import OnboardingNav from './OnboardingNav';
import CreateOrganization from './CreateOrganization';

const OnboardingView: React.FC = () => {
  return (
    <>
      <OnboardingNav />
      <CreateOrganization />
    </>
  );
};

export default OnboardingView;
