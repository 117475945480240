import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useField, useFormikContext } from 'formik';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import Text from '../../base/Text';
import { INTERVAL_TIME_UNITS, getMsIntervalByUnit } from '../../../../utils/rules';
import { useTeamPermission } from '../../../contexts/TeamPermissionContext';
import { SelectButton } from '../../base/Button';
import DropdownMenu from '../../DropdownMenu';

interface Props extends StyledUtilsProps {
  label?: string;
  name: string;
  help?: string;
  type?: string;
  placeholder?: string;
  auto_complete?: string;
  required?: boolean;
  min_interval?: keyof typeof INTERVAL_TIME_UNITS;
}

const StyledField = styled(Div)(
  ({ theme }) => css`
    display: flex;
    max-width: 100%;
    input {
      padding: ${theme.spacing(1.25)} ${theme.spacing(2)};
      border-radius: ${theme.radius.normal} 0 0 ${theme.radius.normal};
      width: 44px;
      line-height: ${theme.pxToRem(20)};

      &:focus {
        z-index: 1;
      }
    }

    button {
      :after {
        border-radius: 0 ${theme.radius.normal} ${theme.radius.normal} 0;
        border-left: none;
      }
      border-radius: 0 ${theme.radius.normal} ${theme.radius.normal} 0;
    }
  `,
);

const IntervalInput: React.FC<Props> = ({
  label,
  name,
  placeholder,
  auto_complete,
  required,
  help,
  min_interval,
}) => {
  const { authorized } = useTeamPermission();
  const [, { value, error, touched }, { setValue, setTouched }] = useField(name);
  const { submitCount } = useFormikContext();
  const [time_unit, setTimeUnit] = useState('hours');
  useEffect(() => {
    if (value) {
      const [, time_unit] = getMsIntervalByUnit(value);
      setTimeUnit(time_unit);
    }
  }, [value]);
  return (
    <Div>
      {label && (
        <Text as="label" subtitle size="s" htmlFor={name}>
          {label}
        </Text>
      )}
      <StyledField>
        <input
          type="number"
          placeholder={placeholder}
          autoComplete={auto_complete}
          required={required}
          disabled={!authorized}
          value={value ? value / INTERVAL_TIME_UNITS[time_unit] : ''}
          max={9999}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setTouched(true);
            if (value > 0) {
              setValue(parseInt(e.target.value) * INTERVAL_TIME_UNITS[time_unit]);
            } else {
              setValue('');
            }
          }}
        />
        <DropdownMenu
          parent_width
          renderToggle={(open, toggle) => (
            <SelectButton onClick={() => toggle(!open)}>{time_unit}</SelectButton>
          )}
          options={Object.keys(INTERVAL_TIME_UNITS)
            .filter(
              (unit) =>
                INTERVAL_TIME_UNITS[unit] >= (min_interval ? INTERVAL_TIME_UNITS[min_interval] : 0),
            )
            .map((unit) => ({
              label: unit,
              onClick: () => {
                if (value) {
                  setValue((value / INTERVAL_TIME_UNITS[time_unit]) * INTERVAL_TIME_UNITS[unit]);
                }
                setTimeUnit(unit);
              },
            }))}></DropdownMenu>
      </StyledField>
      {help && (
        <Text m={{ t: 1, b: 0 }} size="s" as="p" muted>
          {help}
        </Text>
      )}
      {(touched || submitCount > 0) && error && (
        <Text m={{ top: 1, bottom: 0 }} as="p" danger>
          {error}
        </Text>
      )}
    </Div>
  );
};

export default IntervalInput;
