import { useField } from 'formik';
import Text from '../../base/Text';
import { Fragment, useMemo, useState } from 'react';
import { ClickableArea, SelectButton } from '../../base/Button';
import Dropdown from '../../Dropdown';
import { StyledCardSection } from '../../base/Card';
import Search from '../../Search';
import { Div } from '../../helpers/StyledUtils';
import useSWR from 'swr';
import Loading from '../../base/Loading';
import Icon, { IconName } from '../../base/Icon';
import Badge from '../../base/Badge';
import Tabs from '../../base/Tabs';
import Divider from '../../base/Divider';

type Item = {
  id: string;
  label: string;

  icon: IconName;
  category?: string;
};

interface Props {
  name: string;
  search_placeholder: string;
  categories?: { label: string; key: string }[];
  getItemsKey: (search_term: string, category?: string) => string;
  getItems: (search_term: string, category?: string) => Promise<Item[]>;
}

const ItemListPickerInput: React.FC<Props> = ({
  name,
  categories,
  getItems,
  getItemsKey,
  search_placeholder,
}) => {
  const [{ value }, , { setValue }] = useField(name);
  const [fetch_items, setFetchItems] = useState<boolean>(false);
  const [search_term, setSearchTerm] = useState<string>('');
  const [category, setCategory] = useState<string>('all');

  const { data } = useSWR<Item[]>(
    (fetch_items || value) && `${getItemsKey(search_term, category)}::list-picker`,
    () => getItems(search_term, category),
  );

  const current_item = useMemo(
    () => value && data?.find((item) => item.id === value),
    [value, data],
  );

  return (
    <>
      <Dropdown
        placement="bottom-start"
        parent_width
        onToggle={() => !fetch_items && setFetchItems(true)}
        renderToggle={(opened, toggle) => (
          <SelectButton
            block
            minimal
            icon={current_item ? 'verification' : undefined}
            label={current_item?.label || 'Select one...'}
            muted={!current_item}
            onClick={() => toggle(true)}>
            {current_item?.label || 'Select one...'}
          </SelectButton>
        )}>
        {(toggle) => (
          <>
            <StyledCardSection p={{ x: 3, t: 3 }}>
              <Search
                onChange={setSearchTerm}
                value={search_term}
                placeholder={search_placeholder}
                focus
              />
              {categories && categories.length > 0 && (
                <Div p={{ t: 2 }}>
                  <Tabs
                    compact
                    border={false}
                    active_tab={category || 'all'}
                    onTabSelected={(key) => setCategory(key)}
                    tabs={[{ key: 'all', label: 'All' }, ...categories]}
                  />
                </Div>
              )}
            </StyledCardSection>
            <StyledCardSection max_h={{ px: 240 }} scroll>
              {!data && (
                <Div p={{ x: 3, y: 2 }} flex={{ align: 'center', justify: 'center' }}>
                  <Loading />
                </Div>
              )}
              {data && data.length === 0 && (
                <Text p={{ x: 3, y: 2 }} muted>
                  No results found
                </Text>
              )}
              {data &&
                data.map((item, i) => {
                  return (
                    <Fragment key={item.id}>
                      {i !== 0 && <Divider />}
                      <ClickableArea
                        p={{ x: 3, y: 2 }}
                        flex={{ justify: 'space-between', align: 'center' }}
                        onClick={() => {
                          setValue(item.id);
                          toggle(false);
                          setSearchTerm('');
                        }}>
                        <Div flex={{ align: 'center' }}>
                          <Icon icon="verification" left muted />
                          {item.label}
                        </Div>
                        {item.category && <Badge muted>{item.category}</Badge>}
                      </ClickableArea>
                    </Fragment>
                  );
                })}
            </StyledCardSection>
          </>
        )}
      </Dropdown>
    </>
  );
};

export default ItemListPickerInput;
