import { isAfter } from 'date-fns';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import useSWR from 'swr';

import APIMethodKeys from '../../../client/APIMethodKeys';
import { showChat } from '../../../utils/liveChat';
import Button from '../../common/base/Button';
import { StyledCard } from '../../common/base/Card';
import Container from '../../common/base/Container';
import Text from '../../common/base/Text';
import { Div } from '../../common/helpers/StyledUtils';
import { GlobalContext } from '../../contexts/GlobalContext';
import { DashboardContext } from './DashboardContext';
import { StyledPaywallWrapper } from './TeamMemberOverLimitModal';

const ForceUpgradeModal = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription } = useContext(DashboardContext);
  const location = useLocation();

  const eligible =
    subscription?.limit_exceeded_at &&
    subscription?.max_requests !== null &&
    !location.pathname.includes('/settings');

  const { data: subscription_details } = useSWR(
    eligible && APIMethodKeys.billing.getSubscriptionDetails(),
    () => HookdeckAPI.billing.getSubscriptionDetails(),
  );

  const show_modal =
    eligible &&
    subscription.limit_exceeded_at &&
    subscription_details &&
    isAfter(
      new Date(subscription.limit_exceeded_at),
      new Date(subscription_details.current_billing_period_start_date),
    );

  useEffect(() => {
    if (show_modal) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'visible';
      };
    }
  }, [show_modal]);

  if (!show_modal) {
    return null;
  }

  return (
    <StyledPaywallWrapper>
      <Container small flex={{ align: 'center', justify: 'center' }} h={{ vh: 100 }}>
        <StyledCard p={8} raised={3}>
          <Text heading size="xl" center m={{ b: 4 }}>
            Plan limit has been reached
          </Text>
          <Text center>
            You've exceeded your free plan limit of events per month.{' '}
            <strong>Upgrade your plan</strong> to continue using Hookdeck.
          </Text>
          <Div flex={{ justify: 'center', gap: 3 }} m={{ t: 6 }}>
            <Button onClick={showChat} icon="chat" outline>
              Contact Us
            </Button>
            <Button to="/settings/organization/plans?highlight=events" icon="upgrade">
              Upgrade Plan
            </Button>
          </Div>
        </StyledCard>
      </Container>
    </StyledPaywallWrapper>
  );
};

export default ForceUpgradeModal;
