import { useField } from 'formik';
import { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import { DiffEditor } from '@monaco-editor/react';

import { useTheme } from 'styled-components';
import { ShortEventData } from '../../../../../../../../../typings/EventData.interface';
import { Request } from '../../../../../../../../../typings/Request.interface';
import { TransformationExecution } from '../../../../../../../../../typings/TransformationExecution.interface';
import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import useSearchQuery from '../../../../../hooks/useSearchQuery';
import DisplayDate from '../../../../../common/DisplayDate';
import Dropdown from '../../../../../common/Dropdown';
import Editor from '../../../../../common/Editor';
import Table from '../../../../../common/Table';
import Badge from '../../../../../common/base/Badge';
import Button from '../../../../../common/base/Button';
import { StyledCard } from '../../../../../common/base/Card';
import Divider from '../../../../../common/base/Divider';
import Tabs from '../../../../../common/base/Tabs';
import Text from '../../../../../common/base/Text';
import { Div } from '../../../../../common/helpers/StyledUtils';
import EditorInput from '../../../../../common/Form/Fields/EditorInput';
import TextInput from '../../../../../common/Form/Fields/TextInput';
import { useToasts } from '../../../../../common/Toast';
import { getCurrentTimezoneAbreviation } from '../../../../../../utils/date';

const IOPanel: React.FC<{
  source_id: string | null;
  transformation_id: string | null;
  input_height_offset?: number;
}> = ({ source_id, transformation_id }) => {
  const { query, updateSearchQuery } = useSearchQuery<{
    transform_tab: 'input' | 'output' | 'diff';
  }>();
  const active_tab = query.transform_tab || 'input';
  const { HookdeckAPI } = useContext(GlobalContext);
  const [selected_id, setSelectedId] = useState<string | null>(null);
  const [{ value: input }, , { setValue: setInput }] = useField('input');
  const [{ value: output }] = useField('output');
  const theme = useTheme();

  useEffect(() => {
    if (output && Object.keys(output).length > 0) {
      updateSearchQuery({ transform_tab: 'output' });
    }
  }, [output]);

  const { data: requests } = useSWR(
    source_id && APIMethodKeys.requests.list({ source_id, limit: 5 }),
    () => HookdeckAPI.requests.list({ source_id, limit: 5 }),
  );

  const { data: executions } = useSWR(
    transformation_id &&
      APIMethodKeys.transformations.listExecutions(transformation_id, {
        limit: 5,
      }),
    () =>
      HookdeckAPI.transformations.listExecutions(transformation_id!, {
        limit: 5,
      }),
  );

  const first_request = (requests && requests.models[0]) || (executions && executions.models[0]);

  const { data: request } = useSWR(
    first_request && APIMethodKeys.requests.get(first_request.id),
    () => HookdeckAPI.requests.get(first_request!.id),
  );

  const first_execution = executions && executions.models[0];

  const { data: execution } = useSWR(
    first_execution &&
      APIMethodKeys.transformations.getExecution(transformation_id, first_execution.id),
    () => HookdeckAPI.transformations.getExecution(transformation_id!, first_execution!.id),
  );

  const data = execution?.original_event_data || request?.data;

  const setData = (id, data) => {
    setSelectedId(id);
    setInput(
      JSON.stringify(
        {
          headers: data.headers,
          body: data.body,
          query: data.query,
          parsed_query: data.parsed_query,
          path: data.path,
        } || {},
        null,
        2,
      ),
    );
  };

  useEffect(() => {
    if (data && !selected_id) {
      setData(execution?.id || request?.id, data);
    }
  }, [data, selected_id]);

  return (
    <Div flex={{ direction: 'column', grow: true }}>
      <Div p={{ x: 4, t: 2 }} flex={{ align: 'center', justify: 'space-between' }}>
        <Tabs
          compact
          tabs={[
            {
              key: 'input',
              label: 'Input',
            },
            {
              key: 'output',
              label: 'Output',
            },
            {
              key: 'diff',
              label: 'Diff',
            },
          ]}
          border={false}
          m={0}
          active_tab={active_tab}
          onTabSelected={(key) => updateSearchQuery({ transform_tab: key })}
        />
        {source_id ? (
          <ChangeDropdownRequest
            requests={requests?.models || []}
            selected_id={selected_id}
            setData={setData}
          />
        ) : (
          <ChangeDropdownExecutions
            executions={executions?.models || []}
            transformation_id={transformation_id!}
            selected_id={selected_id}
            setData={setData}
          />
        )}
      </Div>

      <Divider />
      {active_tab === 'output' &&
        (!output ? (
          <Text p={3} muted>
            Run your transformation to preview output.
          </Text>
        ) : (
          <Editor flex={{ direction: 'column', grow: true }} prevent_theme_change value={output} />
        ))}
      {active_tab === 'input' && (
        <EditorInput
          flex={{ direction: 'column', grow: true }}
          height={undefined}
          key={active_tab}
          name="input"
        />
      )}
      {active_tab === 'diff' &&
        (!output ? (
          <Text p={3} muted>
            Run your transformation to preview the diff.
          </Text>
        ) : (
          <DiffEditor
            original={input}
            modified={output}
            theme={theme.mode === 'dark' ? 'dark' : 'light'}
            onMount={(editor, monaco) => {
              if (theme.mode === 'dark') {
                monaco.editor.defineTheme('dark', {
                  base: 'vs-dark',
                  inherit: true,
                  rules: [],
                  colors: {
                    'editor.background': theme.colors.surface.base.surface,
                  },
                });
                monaco.editor.setTheme('dark');
              }
            }}
            options={{
              enableSplitViewResizing: false,
              renderSideBySide: false,
              lineNumbers: 'off',
              padding: {
                top: 8,
                bottom: 8,
              },
              contextmenu: false,
              readOnly: true,
              domReadOnly: true,
              renderLineHighlightOnlyWhenFocus: true,
              hideCursorInOverviewRuler: true,
              overviewRulerBorder: false,
              guides: {
                indentation: false,
                highlightActiveIndentation: false,
              },
              minimap: {
                enabled: false,
              },
              scrollbar: {
                vertical: 'hidden',
                horizontal: 'hidden',
              },
              overviewRulerLanes: 0,
              matchBrackets: 'never',
              scrollBeyondLastColumn: 0,
              scrollBeyondLastLine: true,
            }}
            originalLanguage="json"
            modifiedLanguage="json"
            height="100vh"
          />
        ))}
    </Div>
  );
};

const ChangeDropdownRequest: React.FC<{
  requests: Request[];
  selected_id: string | null;
  setData: (id: string, data: ShortEventData) => void;
}> = ({ requests, selected_id, setData }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const [{ value: request_id }] = useField('request_id');
  const handleSelected = (id: string) =>
    HookdeckAPI.requests
      .get(id)
      .then((request) => {
        setData(id, request.data!);
        addToast('success', `Request data applied for ID ${id}`);
      })
      .catch(() => {
        addToast('error', `Could not find data for ID ${id}`);
      });
  return (
    <Dropdown label="Change Input" icon="change" small m={{ b: 2 }} minimal>
      <Div p={4} style={{ minWidth: '546px' }}>
        <Text subtitle size="s" m={{ t: 0, b: 1 }}>
          Latest Requests
        </Text>
        <StyledCard overflow_hidden>
          <Table
            widths={[{ min: 148, max: 148 }, { min: 100 }, { min: 180, max: 180 }]}
            onRowSelected={handleSelected}
            headers={[`Request Date (${getCurrentTimezoneAbreviation()})`, 'Status', 'ID']}
            rows={
              requests.map((request) => ({
                id: request.id,
                highlighted: selected_id === request.id,
                selected: selected_id === request.id,
                fields: [
                  <DisplayDate key={request.ingested_at} date={request.ingested_at} />,
                  request.rejection_cause ? (
                    <Badge small danger subtle icon="error">
                      Rejected
                    </Badge>
                  ) : (
                    <Badge small success subtle icon="check">
                      Accepted
                    </Badge>
                  ),
                  <Text key={`${request.id}-id`} monospace muted size="s">
                    {request.id}
                  </Text>,
                ],
              })) || []
            }
          />
        </StyledCard>
        <Text subtitle size="s" m={{ t: 8, b: 1 }}>
          Specific request ID
        </Text>
        <Div flex={{ align: 'center', gap: 2 }} m={{ y: 2 }}>
          <TextInput w={100} m={{ b: 0 }} name="request_id" placeholder="Request ID" />
          <Button
            outline
            disabled={request_id === selected_id}
            onClick={() => handleSelected(request_id)}
            icon="check">
            Apply
          </Button>
        </Div>
      </Div>
    </Dropdown>
  );
};

const ChangeDropdownExecutions: React.FC<{
  executions: TransformationExecution[];
  selected_id: string | null;
  transformation_id: string;
  setData: (id: string, data: ShortEventData) => void;
}> = ({ executions, transformation_id, selected_id, setData }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const [{ value: execution_id }] = useField('execution_id');
  const handleSelected = (id: string) =>
    HookdeckAPI.transformations.getExecution(transformation_id, id).then((execution) => {
      setData(id, execution.original_event_data!);
    });
  return (
    <Dropdown label="Change Input" small minimal m={{ b: 2 }}>
      <Div p={3} style={{ minWidth: '480px' }}>
        <Text subtitle size="s" muted m={{ t: 0, b: 1 }}>
          Latest Executions
        </Text>
        <StyledCard>
          <Table
            widths={[1 / 4, 2 / 4, 1 / 4]}
            onRowSelected={handleSelected}
            headers={['Log Level', 'ID', 'Execution Date']}
            rows={
              executions.map((execution) => ({
                id: execution.id,
                highlighted: selected_id === execution.id,
                selected: selected_id === execution.id,
                fields: [
                  <Badge
                    small
                    key={execution.id}
                    danger={execution.log_level === 'fatal' || execution.log_level === 'error'}
                    warning={execution.log_level === 'warn'}
                    primary={execution.log_level === 'info' || execution.log_level === 'debug'}
                    success={!execution.log_level}
                    subtle>
                    {execution.log_level?.toUpperCase() || 'OK'}
                  </Badge>,
                  execution.id,
                  <DisplayDate key={execution.created_at} date={execution.created_at} />,
                ],
              })) || []
            }
          />
        </StyledCard>
        <Text subtitle size="s" m={{ t: 8, b: 1 }}>
          Specific Execution ID
        </Text>
        <Div flex={{ align: 'center' }} m={{ y: 2 }}>
          <TextInput w={100} m={{ b: 0 }} name="execution_id" placeholder="Execution ID" />
          <Button
            minimal
            primary
            m={{ l: 2, r: 0 }}
            onClick={() => handleSelected(execution_id)}
            icon="success"
          />
        </Div>
      </Div>
    </Dropdown>
  );
};

export default IOPanel;
