import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import Button from '../../../../../common/base/Button';
import Loading from '../../../../../common/base/Loading';
import Text from '../../../../../common/base/Text';
import { Div } from '../../../../../common/helpers/StyledUtils';
import Modal from '../../../../../common/Modal';
import { GlobalContext } from '../../../../../contexts/GlobalContext';

const InvoicesModal: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const history = useHistory();

  const { data: invoices } = useSWR(APIMethodKeys.billing.getInvoices(), () =>
    HookdeckAPI.billing.getInvoices(),
  );

  const onClose = () =>
    history.push({
      pathname: '/settings/organization/billing',
      state: { scroll: false },
    });

  return (
    <Modal title="Invoices" onClose={onClose}>
      {!invoices && <Loading />}
      {(invoices === null ||
        (invoices && invoices.filter((invoice) => invoice.amount_due !== 0).length === 0)) && (
        <p>You don't have any invoices yet.</p>
      )}
      {invoices &&
        invoices
          .filter((invoice) => invoice.amount_due !== 0)
          .sort((a, b) =>
            new Date(a.end_time).getTime() > new Date(b.end_time).getTime() ? -1 : 1,
          )
          .map((invoice) => (
            <Div
              key={invoice.end_time}
              flex={{ justify: 'space-between', align: 'center' }}
              m={{ y: 2 }}>
              <Div flex>
                <Text heading as="p" m={{ b: 0 }}>
                  {new Date(invoice.issue_date).toLocaleDateString('en-US', {
                    weekday: undefined,
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </Text>
                <Text as="p" capitalize muted m={{ l: 4, b: 0 }}>
                  ${(invoice.amount_due / 100).toFixed(2)} – {invoice.is_paid ? 'Paid' : 'Open'}
                </Text>
              </Div>
              {invoice.pdf_url && (
                <Button as="a" href={invoice.pdf_url} target="_blank" icon="download" outline />
              )}
            </Div>
          ))}
    </Modal>
  );
};

export default InvoicesModal;
