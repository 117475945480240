import { useContext, useState } from 'react';

import { EventAttempt } from '../../../../../../../typings/EventAttempt.interface';
import { getErrorCodeLabels } from '../../../../utils/error-codes';
import Badge from '../../../common/base/Badge';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Link from '../../../common/base/Link';
import Skeleton from '../../../common/base/Skeleton';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';
import { Status } from '../../../common/Status';
import { DashboardContext } from '../DashboardContext';
import { numberWithCommas } from '../../../../utils';
import DisplayDate from '../../../common/DisplayDate';
import { ATTEMPT_TRIGGER_LABELS } from '../../../../utils/attempt';

type Props = {
  attempt?: EventAttempt;
};

const EventAttemptMetadata: React.FC<Props> = ({ attempt }) => {
  const { subscription } = useContext(DashboardContext);
  const [expanded, setExpanded] = useState(false);
  if (!attempt) {
    return (
      <StyledCard>
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Skeleton h={{ px: 20 }} w={{ px: 40 }} loading m={{ b: 1 }} />
          <Skeleton h={{ px: 20 }} w={{ px: 72 }} loading />
        </StyledCardSection>
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Skeleton h={{ px: 20 }} w={{ px: 128 }} loading />
        </StyledCardSection>
      </StyledCard>
    );
  }

  const expanded_content = (
    <>
      {expanded && (
        <>
          {attempt.requested_url && (
            <StyledCardSection p={{ x: 4, y: 3 }}>
              <Div flex={{ justify: 'space-between' }}>
                <Text size="s" subtitle m={{ b: 1 }}>
                  Requested URL
                </Text>
                <Text as="span" text_wrap={false} size="s" subtitle muted>
                  {(attempt.http_method || 'POST').toUpperCase()}
                </Text>
              </Div>
              <Text size="s" overflow_wrap="anywhere">
                {attempt.requested_url}
              </Text>
            </StyledCardSection>
          )}
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Text size="s" subtitle m={{ b: 1 }}>
              Delivered At
            </Text>
            <Div flex={{ justify: 'space-between' }}>
              <DisplayDate date={attempt.delivered_at} />
              {attempt.delivery_latency && (
                <Text monospace as="span" text_wrap={false} size="s" muted>
                  {numberWithCommas(attempt.delivery_latency)}ms
                </Text>
              )}
            </Div>
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Text size="s" subtitle m={{ b: 1 }}>
              Responded At
            </Text>
            <Div flex={{ justify: 'space-between' }}>
              <DisplayDate date={attempt.responded_at} />
              {attempt.response_latency && (
                <Text monospace as="span" text_wrap={false} size="s" muted>
                  {numberWithCommas(attempt.response_latency)}ms
                </Text>
              )}
            </Div>
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Text size="s" subtitle m={{ b: 1 }}>
              Attempt Trigger
            </Text>
            <Div flex={{ justify: 'space-between' }}>
              <Badge small muted>
                {ATTEMPT_TRIGGER_LABELS[attempt.trigger]}
              </Badge>
              {attempt.bulk_retry_id && (
                <Text monospace as="span" text_wrap={false} size="s" muted>
                  {attempt.bulk_retry_id}
                </Text>
              )}
            </Div>
          </StyledCardSection>
        </>
      )}
      <StyledCardSection p={{ x: 4, y: 3 }}>
        <Link
          as="button"
          small
          icon={expanded ? 'collapse_all' : 'expand_all'}
          flex={{ align: 'center' }}
          onClick={() => setExpanded(!expanded)}>
          {!expanded ? `View Metadata` : 'Hide Metadata'}
        </Link>
      </StyledCardSection>
    </>
  );

  if (attempt.error_code) {
    const label = getErrorCodeLabels(subscription)[attempt.error_code];
    return (
      <StyledCard>
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Text size="s" subtitle m={{ b: 1 }}>
            Status
          </Text>
          <Status small {...attempt} />
        </StyledCardSection>
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Text size="s" subtitle m={{ b: 1 }}>
            Error
          </Text>
          <Badge small danger subtle m={{ b: 2 }}>
            {attempt.error_code}
          </Badge>
          <Text size="s">{label}</Text>
        </StyledCardSection>
        {expanded_content}
      </StyledCard>
    );
  }

  return (
    <>
      <StyledCard>
        <StyledCardSection p={{ x: 4, y: 3 }}>
          <Text size="s" subtitle m={{ b: 1 }}>
            Status
          </Text>
          <Status {...attempt} />
        </StyledCardSection>
        {expanded_content}
      </StyledCard>
    </>
  );
};

export default EventAttemptMetadata;
