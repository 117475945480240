import { FormEvent } from 'react';
import TextInput from '../../Form/Fields/TextInput';
import { Div } from '../../helpers/StyledUtils';

const NumberRangeFilter: React.FC<{
  name: string;
  delete_button?: JSX.Element;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}> = ({ name, delete_button, onSubmit }) => {
  return (
    <Div flex={{ direction: 'row' }}>
      <Div flex m={{ r: 1 }}>
        <Div>
          <Div flex={{ align: 'center' }}>
            <TextInput
              m={0}
              label={'Min'}
              variant={'card'}
              name={`${name}.min`}
              type={'number'}
              min={0}
              placeholder="-"
              max={10000}
              onBlur={onSubmit}
            />
            <Div m={{ x: 4 }}>–</Div>
          </Div>
        </Div>
        <Div>
          <TextInput
            m={0}
            label={'Max'}
            variant={'card'}
            name={`${name}.max`}
            type={'number'}
            placeholder="-"
            min={0}
            max={10000}
            onBlur={onSubmit}
          />
        </Div>
      </Div>
      <Div>{delete_button}</Div>
    </Div>
  );
};

export default NumberRangeFilter;
