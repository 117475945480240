import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { stringifyQuery } from '../../../../../utils';
import Button from '../../../../common/base/Button';
import Container from '../../../../common/base/Container';
import Divider from '../../../../common/base/Divider';
import Tabs from '../../../../common/base/Tabs';
import MultiSelectInput from '../../../../common/Form/Fields/MultiSelectInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import MetricCardGroup from '../../../../common/metrics/MetricCard/MetricCardGroup';
import MetricWrapper from '../../../../common/metrics/MetricWrapper';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import { PageNav, SecondaryPageNav, StyledViewContent, StyledViewWrapper } from '../../StyledView';
import ExportDropdown from '../ExportDropdown';
import { metric_categories } from '../metric-categories';
import MetricDatePicker, { useDateRange } from '../MetricDatePicker';
import { StyledCard } from '../../../../common/base/Card';
import MetricChart from '../../../../common/metrics/MetricChart';
import MetricTable from '../../../../common/metrics/MetricTable';
import { metric_definitions } from '../../../../common/metrics/metric-definitions';
import { MetricChartType } from '../../../../common/metrics/useMetric';
import Text from '../../../../common/base/Text';

export const dimension_by_resource = {
  source: 'source_id',
  webhook: 'webhook_id',
  destination: 'destination_id',
};

const MetricCategoryView: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const location = useLocation();

  const metric_category = metric_categories[location.pathname.split('/')[2].split('#')[0]];

  const { query, updateSearchQuery } = useSearchQuery<{
    [key: string]: string[];
  }>();

  const dimension_values = query[metric_category.dimention.key] || [];

  const [active_section, setActiveSection] = useState('overview');

  const all_metrics = [
    metric_category.metrics.overview,
    ...(metric_category.metrics.current_data ? [metric_category.metrics.current_data] : []),
    ...metric_category.metrics.charts,
  ];
  const tabs = all_metrics.map((metric) => ({
    label: metric.tab_label,
    key: metric.tab_label.toLowerCase().replace(/ /g, '-'),
    icon: metric.icon,
  }));

  const goToSection = (section: string) => {
    setActiveSection(section);
  };

  const [date_range, onDateRangeChanged] = useDateRange();

  const onDimentionChanged = (values: string[]) => {
    updateSearchQuery({ [metric_category.dimention.key]: values.length > 0 ? values : undefined });
  };

  const getLabel = (model) => {
    if (metric_category.dimention.api_root === 'webhooks') {
      return model.full_name;
    }
    return model.name;
  };

  const formatOptions = (data) =>
    data && data.models.map((model) => ({ label: getLabel(model), value: model.id }));

  const formatFilters = (search_term?: string, values?: string[]) => {
    if (metric_category.dimention.api_root === 'webhooks') {
      return { limit: 250, full_name: search_term || undefined, id: values };
    }
    return { limit: 250, name: search_term ? { contains: search_term } : undefined, id: values };
  };

  return (
    <StyledViewWrapper>
      <StyledViewContent>
        <PageNav
          breadcrumb={[
            {
              icon: 'metrics',
              title: 'Metrics',
              path: `/metrics?${stringifyQuery({ date: query.date })}`,
            },
            {
              icon: 'metrics',
              title: metric_category.label,
            },
          ]}>
          <Formik
            initialValues={{ [metric_category.dimention.key]: dimension_values }}
            onSubmit={(values) => onDimentionChanged(values[metric_category.dimention.key])}>
            {({ handleSubmit }) => (
              <Form>
                <MultiSelectInput
                  renderToggle={(opened, toggle, value) => (
                    <Button
                      outline
                      icon="filter"
                      onClick={toggle}
                      dropdown
                      badge={value?.length > 0 ? { label: value.length } : undefined}>
                      {metric_category.dimention.label}
                    </Button>
                  )}
                  placement="bottom-end"
                  onClose={handleSubmit}
                  onRemove={handleSubmit}
                  initial_options={[]}
                  formatOptions={formatOptions}
                  formatFilters={formatFilters}
                  fetcher={HookdeckAPI[metric_category.dimention.api_root].list}
                  fetcherKey={APIMethodKeys[metric_category.dimention.api_root].list}
                  option_title={`Filter by ${metric_category.dimention.label}`}
                  name={metric_category.dimention.key}
                  m={0}
                />
              </Form>
            )}
          </Formik>
          <MetricDatePicker date_range={date_range} onDateRangeChanged={onDateRangeChanged} />
          <ExportDropdown />
        </PageNav>
        <SecondaryPageNav>
          <Div p={{ t: 3 }}>
            <Tabs
              anchor
              scroll_offset={-132}
              border={false}
              active_tab={active_section}
              tabs={tabs as any}
              setActiveTab={setActiveSection}
              onTabSelected={goToSection}
            />
          </Div>
        </SecondaryPageNav>
        <Container large w={100} p={{ x: 2 }} m={{ y: 10 }}>
          <MetricWrapper
            id={metric_category.metrics.overview.tab_label.toLowerCase().replace(/ /g, '-')}
            title={metric_category.metrics.overview.title}>
            <MetricCardGroup
              configs={{
                date_range,
                options: {
                  filters: {
                    [metric_category.dimention.key]: dimension_values,
                  } as any,
                },
              }}
              metrics={metric_category.metrics.overview.metrics?.map((m) => m.name) || []}
              columns={3}
            />
          </MetricWrapper>
          <Divider m={{ y: 14 }} />
          {metric_category.metrics.current_data && (
            <>
              <Div
                id={metric_category.metrics.current_data.tab_label
                  .toLowerCase()
                  .replace(/ /g, '-')}>
                <Text heading size={'l'} as={'h3'} m={0}>
                  {metric_category.metrics.current_data.title}
                </Text>
                <Text m={{ b: 4, t: 1 }} muted>
                  {metric_category.metrics.current_data.description}
                </Text>
                <Div flex={{ direction: 'column', gap: 6 }}>
                  {metric_category.metrics.current_data.metrics?.map((metric) => (
                    <MetricTable
                      key={`${metric.name}-${metric.type}-${
                        metric_category.metrics.current_data!.title
                      }`}
                      metric={metric.name}
                      configs={{
                        date_range,
                        options: {
                          dimentions: [
                            dimension_by_resource[metric_definitions[metric.name].resource],
                          ],
                          filters: {
                            [metric_category.dimention.key]: dimension_values,
                          },
                        } as any,
                      }}
                      footer_text={metric.footer_text}
                      footer_link={metric.footer_link}
                    />
                  ))}
                </Div>
              </Div>
              <Divider m={{ y: 14 }} />
            </>
          )}
          {metric_category.metrics.charts.map((chart) => (
            <Div
              m={{ b: 18 }}
              key={JSON.stringify(chart.variants)}
              id={chart.tab_label.toLowerCase().replace(/ /g, '-')}>
              <MetricWrapper
                key={chart.title}
                title={chart.title}
                variants={chart.variants?.map((variant) => ({
                  key: variant.key,
                  label: variant.label,
                  metric: variant.metric,
                }))}
                variantMetric={(variant) => {
                  const { metric } = variant || chart.variants?.[0] || {};
                  return (
                    <StyledCard>
                      <MetricChart
                        type={metric.type as MetricChartType}
                        metric={metric.name}
                        configs={{
                          date_range,
                          options: {
                            dimentions: [
                              dimension_by_resource[metric_definitions[metric.name].resource],
                            ],
                            filters: {
                              [metric_category.dimention.key]: dimension_values,
                            },
                          } as any,
                        }}
                      />
                    </StyledCard>
                  );
                }}
              />
            </Div>
          ))}
        </Container>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default MetricCategoryView;
