import React from 'react';
import Badge from '../../common/base/Badge';
import Button from '../../common/base/Button';
import Container from '../../common/base/Container';
import Icon from '../../common/base/Icon';
import Text from '../../common/base/Text';
import { Div } from '../../common/helpers/StyledUtils';
import { StyledViewWrapper, StyledViewContent } from './StyledView';

const NotFound: React.FC<{
  id: string;
  title: string;
  description: string;
  link?: { to: string; text: string };
  extra_action?: React.ReactNode;
}> = ({ id, title, description, link, extra_action }) => (
  <StyledViewWrapper>
    <StyledViewContent>
      <Container small center h={100} flex={{ align: 'center', justify: 'center' }}>
        <Div>
          <Icon muted large icon="plagiarism" />
          <Text heading m={{ t: 4 }} center size="l">
            {title}
          </Text>
          <Text muted as="p" m={{ t: 1 }}>
            {description}
          </Text>
          {id && (
            <Badge m={{ t: 3 }} muted>
              {id}
            </Badge>
          )}
          {link && (
            <Div flex={{ gap: 2, justify: 'center' }} m={{ t: 14 }}>
              <Button primary minimal to={link.to}>
                {link.text} {'->'}
              </Button>
              {extra_action}
            </Div>
          )}
        </Div>
      </Container>
    </StyledViewContent>
  </StyledViewWrapper>
);

export default NotFound;
