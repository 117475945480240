import { addDays } from 'date-fns';
import { useCallback, useContext, useMemo } from 'react';
import { TransformationExecution } from '../../../../../typings/TransformationExecution.interface';
import APIMethodKeys from '../../client/APIMethodKeys';
import { TransformationExecutionsFiltersProps } from '../../typings/TransformationExecution.interface';
import { GlobalContext } from '../contexts/GlobalContext';
import useRefreshingList, { RefreshingListResult } from './useRefreshingList';

export const formatFiltersForAPI = (filters: TransformationExecutionsFiltersProps = {}) => {
  const formatted_filters: { [k: string]: any } = {};
  if (filters.date?.min || filters.date?.max) {
    formatted_filters['created_at'] = {
      gte: filters.date.min,
      lte: filters.date.max && addDays(new Date(filters.date.max), 1).toISOString(),
    };
  }
  formatted_filters.next = filters.next;
  formatted_filters.prev = filters.prev;
  formatted_filters.webhook_id = filters.webhook_id?.length === 0 ? undefined : filters.webhook_id;
  formatted_filters.log_level = filters.log_level?.length === 0 ? undefined : filters.log_level;
  formatted_filters.issue_id = filters.issue_id;
  return formatted_filters;
};

const order_by = 'created_at';

export interface TransformationExecutionListResult
  extends Omit<RefreshingListResult<Request>, 'models'> {
  executions: TransformationExecution[];
}

const useTransformationExecutionsList = (
  transformation_id: string,
  filters: TransformationExecutionsFiltersProps,
): TransformationExecutionListResult => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const formatted_filters = useMemo(() => formatFiltersForAPI({ ...filters }), [filters]);

  const getKey = useCallback(
    (query) => APIMethodKeys.transformations.listExecutions(transformation_id, query),
    [transformation_id, formatted_filters],
  );
  const list = useCallback(
    (query) => HookdeckAPI.transformations.listExecutions(transformation_id, query),
    [transformation_id],
  );
  const { models: executions, ...props } = useRefreshingList<TransformationExecution>(
    getKey,
    list,
    formatted_filters,
    order_by,
    'DESC',
    { limit: filters.limit || 50 },
  );

  return {
    executions,
    ...props,
  };
};

export default useTransformationExecutionsList;
