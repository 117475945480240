import { FormEvent, useContext } from 'react';
import MultiSelectInput from '../../Form/Fields/MultiSelectInput';
import { Div } from '../../helpers/StyledUtils';
import { GlobalContext } from '../../../contexts/GlobalContext';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { DashboardContext } from '../../../scenes/DashboardScene/DashboardContext';
import { UserContext } from '../../../contexts/UserContext';

const CliUserFilter: React.FC<{
  delete_button?: JSX.Element;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}> = ({ delete_button, onSubmit }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const { team } = useContext(DashboardContext);
  return (
    <Div flex={{ align: 'center' }}>
      <MultiSelectInput
        onClose={onSubmit}
        onRemove={onSubmit}
        initial_options={[{ value: user!.id, label: 'Myself' }]}
        formatFilters={(f) => f}
        fetcher={() => HookdeckAPI.teams.listMembers()}
        fetcherKey={() => APIMethodKeys.teams.listMembers(team!.id)}
        formatOptions={(v) =>
          v.map((u) => ({
            value: u.user_id,
            label: u.user_id === user!.id ? 'Myself' : u.user_name,
          }))
        }
        option_title={'CLI Users'}
        name={'cli_user_id'}
        m={0}
      />
      {delete_button && delete_button}
    </Div>
  );
};

export default CliUserFilter;
