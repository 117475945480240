import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import Avatar from '../../../common/base/Avatar';
import Container from '../../../common/base/Container';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';
import { TeamPermission } from '../../../contexts/TeamPermissionContext';
import { UserContext } from '../../../contexts/UserContext';
import { DashboardContext } from '../DashboardContext';
import SettingsNav from '../Nav/SettingsNav';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import AccountProfile from './Account/AccountProfile';
import TeamApiKeys from './Team/TeamApiKeys';
import OrganizationBilling from './Organization/OrganizationBilling';
import TeamMembers from './Organization/OrganizationMembers';
import OrganizationPlans from './Organization/OrganizationPlans';
import TeamQuotas from './Team/TeamQuotas';
import TeamSettings from './Team/TeamSettings';
import TeamAccessControl from './Team/TeamAccessControl';
import TeamIntegrations from './Team/TeamIntegrations';
import OrganizationSettings from './Organization/OrganizationSettings';
import { Redirect } from 'react-router';
import Icon from '../../../common/base/Icon';
import Tooltip from '../../../common/base/Tooltip';
import ViewRoute from '../../../common/ViewRoute';

const StyledSettingsView = styled.div`
  display: grid;
  grid-template-columns: 208px 1fr;
  position: relative;

  > div:first-child {
    height: fit-content;
    position: sticky;
    top: 57px;
  }
`;

const Settings: React.FC = () => {
  const { team, organization } = useContext(DashboardContext);
  const { user } = useContext(UserContext);

  const sections = {
    project: [
      {
        key: 'settings',
        path: '/settings/project/general',
        label: 'Project General Settings',
        icon: 'settings',
        Component: TeamSettings,
      },
      {
        key: 'secrets',
        path: '/settings/project/secrets',
        label: 'Secrets',
        icon: 'key',
        Component: TeamApiKeys,
      },
      {
        key: 'secrets',
        path: '/settings/project/access',
        label: 'Access Control',
        icon: 'group',
        Component: TeamAccessControl,
      },
      {
        key: 'quotas',
        path: '/settings/project/integrations',
        label: 'Integrations',
        icon: 'tune',
        Component: TeamIntegrations,
      },
      {
        key: 'quotas',
        path: '/settings/project/quotas',
        label: 'Quotas',
        icon: 'rate_limit',
        Component: TeamQuotas,
      },
    ],
    organization: [
      {
        key: 'general',
        path: '/settings/organization/general',
        label: 'Organization General Settings',
        icon: 'settings',
        Component: OrganizationSettings,
      },
      {
        key: 'members',
        path: '/settings/organization/members',
        label: 'Members',
        icon: 'team',
        Component: TeamMembers,
      },
      {
        key: 'billing',
        path: '/settings/organization/billing',
        label: 'Billing',
        icon: 'billing',
        Component: OrganizationBilling,
      },
      ...(!organization!.workos_directory_id && !organization!.workos_connection_id
        ? [
            {
              key: 'plans',
              path: '/settings/organization/plans',
              label: 'Plans',
              icon: 'billing',
              Component: OrganizationPlans,
            },
          ]
        : []),
    ],
  };

  return (
    <StyledViewWrapper>
      <StyledViewContent light>
        <PageNav breadcrumb={[{ icon: 'settings', title: 'Settings' }]} />
        <StyledSettingsView>
          <SettingsNav />
          <Switch>
            {!organization!.workos_directory_id && !organization!.workos_connection_id && (
              <Route path="/settings/organization/plans" component={OrganizationPlans} />
            )}
            <ViewRoute
              title="Profile"
              path="/settings/account/profile"
              render={() => (
                <Div>
                  <Container medium>
                    <Div flex={{ align: 'center' }} m={{ b: 12, t: 13 }}>
                      <Avatar name={user!.name} neutral large m={{ right: 4 }} />
                      <Text heading size="xl" as="h1" m={0}>
                        {user!.name}
                      </Text>
                    </Div>
                    <Div m={{ b: 6 }}>
                      <AccountProfile />
                    </Div>
                  </Container>
                </Div>
              )}
            />
            <Route
              path="/settings/project"
              render={() => (
                <TeamPermission role="member">
                  <Div>
                    <Container medium>
                      <Div flex={{ align: 'center' }} m={{ b: 12, t: 13 }}>
                        <Avatar icon="folder" large neutral m={{ right: 4 }} />
                        <Text heading size="xl">
                          {team!.name}
                        </Text>
                        {team!.private && (
                          <Tooltip tooltip="Private project" placement="bottom-start">
                            <Icon icon="lock" right={2} muted />
                          </Tooltip>
                        )}
                      </Div>
                      <Div m={{ b: 6 }}>
                        <Switch>
                          {sections.project.map((section) => (
                            <ViewRoute title={section.label} path={section.path} key={section.key}>
                              <section.Component />
                            </ViewRoute>
                          ))}
                          <Redirect to="/settings/project/general" />
                        </Switch>
                      </Div>
                    </Container>
                  </Div>
                </TeamPermission>
              )}
            />
            <Route
              path="/settings/organization"
              render={() => (
                <TeamPermission role="member">
                  <Div>
                    <Container medium>
                      <Div flex={{ align: 'center' }} m={{ b: 12, t: 13 }}>
                        <Avatar name={organization!.name} large m={{ right: 4 }} />
                        <Text heading size="xl">
                          {organization!.name}
                        </Text>
                      </Div>
                      <Div m={{ b: 6 }}>
                        <Switch>
                          {sections.organization.map((section) => (
                            <ViewRoute title={section.label} path={section.path} key={section.key}>
                              <section.Component />
                            </ViewRoute>
                          ))}
                          <Redirect to="/settings/organization/general" />
                        </Switch>
                      </Div>
                    </Container>
                  </Div>
                </TeamPermission>
              )}
            />
            <Redirect to="/settings/project/general" />
          </Switch>
        </StyledSettingsView>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default Settings;
