import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { numberWithCommas } from '../../../../../utils';
import { useCopyToClipboard } from '../../../../../utils/copy';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import { StyledCard } from '../../../../common/base/Card';
import Container from '../../../../common/base/Container';
import Divider from '../../../../common/base/Divider';
import Loading from '../../../../common/base/Loading';
import Skeleton from '../../../../common/base/Skeleton';
import Text from '../../../../common/base/Text';
import { useDialog } from '../../../../common/Dialog';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useMetric } from '../../../../common/metrics/useMetric';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { withTeamPermission } from '../../../../contexts/TeamPermissionContext';
import { useDateRange } from '../../Metrics/MetricDatePicker';
import NotFound from '../../NotFound';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../../StyledView';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import { getDestinationIcon } from '../DestinationCard';
import destination_configuration_form_props from '../Forms/destination_configuration';
import resource_details_form_props from '../Forms/resource_details';
import ResourceMetricSection from '../ResourceMetricsSection';
import SaveButton from '../SaveButton';

const DestinationView = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { addToast } = useToasts();
  const showDialog = useDialog();
  const history = useHistory();
  const {
    data: destination,
    mutate,
    error,
  } = useSWR(APIMethodKeys.destinations.get(id), () => HookdeckAPI.destinations.get(id));

  const copyToClipboard = useCopyToClipboard();

  const [date_range] = useDateRange();

  const { data: attempts_count } = useMetric<number>('card', 'attempts_count', 0, {
    options: {
      filters: { destination_id: [id] },
    },
    date_range,
  });

  const { data: connections_count } = useSWR(
    APIMethodKeys.webhooks.count({ destination_id: id }),
    () => HookdeckAPI.webhooks.count({ destination_id: id }),
  );

  const onUpdate = async (values) =>
    HookdeckAPI.destinations
      .update(id, values)
      .then((destination) => {
        mutate(destination);
        addToast('success', 'Destination saved');
        return destination;
      })
      .catch((e) => {
        addToast(
          'error',
          `An error occurred while saving the destination${
            e.response?.data[0] ? `: ${e.response.data[0]}` : ''
          }`,
        );
        throw e;
      });

  const onEnable = async () => {
    HookdeckAPI.destinations
      .enable(id)
      .then((destination) => {
        mutate(destination);
        addToast('success', 'Destination enabled');
      })
      .catch(() => {
        addToast('error', 'Failed to enable destination');
      });
  };

  const onDisable = async () => {
    showDialog(
      () => {
        HookdeckAPI.destinations
          .disable(id)
          .then((destination) => {
            mutate(destination);
            addToast('success', 'Destination disabled');
          })
          .catch(() => {
            addToast('error', 'Failed to disable destination');
          });
      },
      () => null,
      {
        title: 'Disable Destination',
        submit_label: 'Disable',
        cancel_label: 'Cancel',
        message:
          'By disabling this destination, all connections associated with this destination will also be disabled. Are you sure?',
      },
    );
  };

  const onDelete = async () => {
    showDialog(
      () => {
        HookdeckAPI.destinations
          .delete(id)
          .then(() => {
            addToast('success', 'Destination deleted');
            history.push('/connections');
          })
          .catch(() => {
            addToast('error', 'Failed to delete destination');
          });
      },
      () => null,
      {
        danger: true,
        title: 'Delete Destination',
        submit_label: 'Delete',
        cancel_label: 'Keep Destination',
        message: ConfirmDeleteDialog({
          text: 'By deleting this destination, no events will be delivered anymore. All connections associated with this destination will also be deleted. Are you sure?',
        }),
      },
    );
  };

  if (error && error.response?.status === 404) {
    return (
      <NotFound
        title="Destination not found"
        description="This destination does not exist or has been deleted."
        id={id}
      />
    );
  }

  if (error && error.response?.status === 410) {
    return (
      <StyledViewWrapper>
        <StyledViewContent>
          <Div flex={{ justify: 'center' }} p={8}>
            <Container small center>
              <Text subtitle center size="l">
                Destination deleted
              </Text>
              <Text as="p" m={{ t: 4, b: 2 }}>
                This destination was permanently deleted.
              </Text>
              <Button primary minimal m={{ t: 4 }} as={RouterLink} to={`/connections`}>
                Connections
              </Button>
            </Container>
          </Div>
        </StyledViewContent>
      </StyledViewWrapper>
    );
  }

  return (
    <StyledViewWrapper>
      <StyledViewContent light>
        <PageNav
          breadcrumb={[
            { icon: 'connections', title: 'Connections', path: '/connections' },
            {
              icon: destination ? getDestinationIcon(destination) : undefined,
              title: destination?.name || null,
              monospace: true,
            },
          ]}
        />
        <Container large>
          {!destination ? (
            <Div m={{ t: 8 }}>
              <Loading />
            </Div>
          ) : (
            <Div m={{ b: 14 }}>
              <Text as="h2" heading m={{ t: 14, b: 2 }} flex={{ align: 'center' }}>
                Destination Overview
                {destination.disabled_at && (
                  <Badge muted icon="disable" small m={{ l: 2 }}>
                    Disabled
                  </Badge>
                )}
              </Text>
              <Divider m={{ b: 4 }} />
              <Div flex={{ gap: 4 }}>
                <StyledCard flex={{ grow: true, justify: 'space-between', align: 'center' }} p={3}>
                  <Div>
                    <Text subtitle muted size="s">
                      Destination ID
                    </Text>
                    <Text monospace ellipsis>
                      {destination.id}
                    </Text>
                  </Div>
                  <Button outline onClick={() => copyToClipboard(destination.id)} icon="copy" />
                </StyledCard>
              </Div>
              <Div flex={{ gap: 4 }} m={{ t: 4 }}>
                <StyledCard flex={{ justify: 'space-between', align: 'center' }} p={3}>
                  <Div>
                    <Text subtitle muted size="s">
                      Connections
                    </Text>
                    {connections_count !== undefined ? (
                      <Text monospace ellipsis>
                        {connections_count.count}
                      </Text>
                    ) : (
                      <Skeleton w={{ px: 100 }} h={{ px: 20 }} loading />
                    )}
                  </Div>
                  <Button outline to={`/connections?destination_id=${id}`} icon="link" />
                </StyledCard>
                <StyledCard flex={{ justify: 'space-between', align: 'center' }} p={3}>
                  <Div>
                    <Text subtitle muted size="s">
                      Attempts · Last 24h
                    </Text>
                    {attempts_count !== undefined ? (
                      <Text monospace ellipsis>
                        {numberWithCommas(attempts_count)}
                      </Text>
                    ) : (
                      <Skeleton w={{ px: 100 }} h={{ px: 20 }} loading />
                    )}
                  </Div>
                  <Button outline to={`/events?destination_id[0]=${id}`} icon="link" />
                </StyledCard>
              </Div>
              <ResourceMetricSection id={id} resource="destination" />
              <Formik
                key={destination.id}
                initialValues={resource_details_form_props.getInitialValues(destination)}
                validate={(values) =>
                  resource_details_form_props.validate(values, (name) =>
                    name === destination.name
                      ? Promise.resolve(false)
                      : HookdeckAPI.destinations.nameIsUsed(name),
                  )
                }
                onSubmit={(values, { resetForm }) =>
                  onUpdate(resource_details_form_props.postprocessValues(values)).then(
                    (destination) => {
                      resetForm({
                        values: resource_details_form_props.getInitialValues(destination),
                      });
                    },
                  )
                }>
                <Form>
                  <Text as="h2" heading m={{ t: 14, b: 2 }}>
                    Destination Details
                  </Text>
                  <Divider m={{ b: 4 }} />
                  <resource_details_form_props.Fields
                    prefix=""
                    placeholder="my-api"
                    show_description
                  />
                  <SaveButton m={{ t: 4 }} />
                </Form>
              </Formik>
              <Formik
                key={destination.id}
                initialValues={destination_configuration_form_props.getInitialValues(destination)}
                validate={async (values) =>
                  cleanseFormErrorObject(
                    await destination_configuration_form_props.validate(values),
                  )
                }
                onSubmit={(values, { resetForm }) =>
                  onUpdate(destination_configuration_form_props.postprocessValues(values)).then(
                    (destination) => {
                      resetForm({
                        values: destination_configuration_form_props.getInitialValues(destination),
                      });
                    },
                  )
                }>
                <Form>
                  <Text as="h2" heading m={{ t: 14, b: 2 }}>
                    Destination Configuration
                  </Text>
                  <Divider m={{ b: 4 }} />
                  <destination_configuration_form_props.Fields prefix="" show_all base_only />
                  <Divider m={{ t: 6, b: 2 }} />
                  <destination_configuration_form_props.Fields prefix="" show_all advanced_only />
                  <SaveButton />
                </Form>
              </Formik>
              <Text as="h2" heading m={{ t: 14, b: 0 }}>
                {destination.disabled_at ? 'Enable' : 'Disable'} Destination
              </Text>
              <Text as="p" muted m={{ b: 2 }}>
                {destination.disabled_at
                  ? 'Enabling your destination will resume delivery of new events to this destination'
                  : 'Disabling your destination will stop all event delivery to this destination'}
              </Text>
              <Divider m={{ b: 4 }} />
              <Button.Permission
                outline
                onClick={destination.disabled_at ? onEnable : onDisable}
                icon={destination.disabled_at ? 'add_circle' : 'disable'}>
                {destination.disabled_at ? 'Enable' : 'Disable'}
              </Button.Permission>
              <Text as="h2" heading m={{ t: 14, b: 0 }}>
                Delete Destination
              </Text>
              <Text as="p" muted m={{ b: 2 }}>
                Deleting this destination will permanently delete it and all associated connections.
              </Text>
              <Divider m={{ b: 4 }} />
              <Button.Permission danger onClick={onDelete} icon="delete">
                Delete
              </Button.Permission>
            </Div>
          )}
        </Container>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default withTeamPermission(DestinationView, 'member');
