import styled, { css } from 'styled-components';

import { Div } from '../../common/helpers/StyledUtils';
import { PropsWithChildren, useContext, useState } from 'react';
import Text from '../../common/base/Text';
import Icon, { IconName } from '../../common/base/Icon';
import Button, { ClickableArea } from '../../common/base/Button';
import { DashboardContext } from './DashboardContext';
import { copyTextToClipboard } from '../../../utils/copy';
import { useToasts } from '../../common/Toast';
import { StyledCard } from '../../common/base/Card';
import DashboardNav, { StyledDashboardNav } from './Nav';
import Divider from '../../common/base/Divider';
import Dropdown from '../../common/Dropdown';
import TeamSelection from './Nav/TeamSelection/TeamSelection';
import { useIsDegradedBanner } from '../../hooks/useIsDegradedBanner';

export const StyledViewWrapper = styled.div`
  display: flex;
  min-height: 100%;
`;

export const StyledViewNav = styled.div<{
  background_color: 'background' | 'surface';
  secondary?: boolean;
}>(({ theme, background_color, secondary }) => {
  const degraded_banner_active = useIsDegradedBanner();

  return css`
    position: sticky;
    top: ${degraded_banner_active ? '32px' : 0};
    border-bottom: ${theme.border};
    padding: 0 ${theme.spacing(4)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    background-color: ${theme.colors.surface.base[background_color]};
    z-index: ${secondary ? theme.zindex.sticky - 1 : theme.zindex.sticky};
    height: 56px;
  `;
});

const StyledNavHoverZone = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 8px;
  bottom: 0;
`;

const StyledNavContainer = styled.div(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: ${theme.pxToRem(56 + 4 * 4)} 0 ${theme.spacing(4)} 0;
    width: ${theme.pxToRem(224)};
    z-index: ${({ theme }) => theme.zindex.modal};

    ${StyledDashboardNav} {
      @keyframes slideInFromLeft {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(0);
        }
      }

      border-radius: 0 ${theme.radius.large} ${theme.radius.large} 0;
      animation: 125ms ease-out 0s 1 slideInFromLeft;
      overflow: hidden;
      height: 100%;
      max-height: unset;
      min-height: unset;
      box-shadow: ${theme.elevation[3]};
      border: ${theme.border};
      border-left: none;
    }

    &:before {
      @keyframes fade {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      animation: 250ms ease-out 0s 1 fade;
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${({ theme }) => theme.colors.surface.base.backdrop};
      backdrop-filter: blur(1px);
      pointer-events: none;
    }
  `,
);

interface BreadcrumbItem {
  icon?: IconName;
  title: string | null;
  monospace?: true;
}

const updateLinkWithPrevLocation = (
  to: string,
  prev_location: { pathname: string; search: string },
) => {
  if (to === prev_location.pathname) {
    to += prev_location.search || '';
  }
  return to;
};

export const PageNav: React.FC<
  PropsWithChildren<{
    breadcrumb:
      | [BreadcrumbItem]
      | [BreadcrumbItem & { path: string }, BreadcrumbItem]
      | [BreadcrumbItem & { path: string }, BreadcrumbItem & { path: string }, BreadcrumbItem];
  }>
> = ({ breadcrumb, children }) => {
  const { prev_location, nav_collapsed, setNavCollapsed, team } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const [show_nav, setShowNav] = useState(false);

  return (
    <StyledViewNav background_color="background">
      <Div flex={{ align: 'center' }} w={50}>
        {nav_collapsed && (
          <Div onMouseLeave={() => setShowNav(false)} flex={{ align: 'center' }}>
            <Button
              on="background"
              icon="right_panel_close"
              minimal
              onMouseEnter={() => setShowNav(true)}
              onClick={() => setNavCollapsed(false)}
              m={{ l: -1 }}
              style={{ zIndex: show_nav ? 1061 : undefined }}
            />
            {!show_nav && <StyledNavHoverZone onMouseEnter={() => setShowNav(true)} />}
            <Divider vertical h={{ px: 20 }} m={{ l: 2, r: 4 }} />
            {show_nav && (
              <StyledNavContainer>
                <DashboardNav />
              </StyledNavContainer>
            )}
          </Div>
        )}
        <Div flex={{ align: 'center' }}>
          <Icon muted left={1} icon="folder" />
          <Dropdown
            renderToggle={(open, toggle) => (
              <ClickableArea
                on="background"
                rounded
                highlight={open}
                p={{ x: 1, y: 0 }}
                onClick={() => toggle(!open)}>
                <Text subtitle ellipsis muted>
                  {team!.name}
                </Text>
              </ClickableArea>
            )}>
            <TeamSelection team_only />
          </Dropdown>

          <Text muted m={{ l: 1, r: 2 }}>
            /
          </Text>
        </Div>
        {breadcrumb.map((item: BreadcrumbItem & { path?: string }, index) => (
          <Div
            flex={{ align: 'center', grow: index === breadcrumb.length - 1 ? true : false }}
            style={{ overflow: index === breadcrumb.length - 1 ? 'hidden' : 'visible' }}
            key={index}>
            {index !== 0 && (
              <Text muted m={{ l: 1, r: 1 }}>
                /
              </Text>
            )}
            {item.title === null ? (
              <Icon icon="loading" muted right={1} />
            ) : (
              <>
                {(index === 0 || breadcrumb[index - 1].icon !== item.icon) && (
                  <Icon muted icon={item.icon} m={{ x: 1, l: index === 0 ? 0 : 1 }} />
                )}
                {index !== breadcrumb.length - 1 && item.path ? (
                  <ClickableArea
                    on="background"
                    rounded
                    p={{ x: 1, y: 0 }}
                    to={updateLinkWithPrevLocation(item.path, prev_location)}>
                    <Text muted={true} subtitle={!item.monospace} monospace={item.monospace}>
                      {item.title}
                    </Text>
                  </ClickableArea>
                ) : (
                  <>
                    <Text
                      subtitle={!item.monospace}
                      monospace={item.monospace}
                      ellipsis
                      m={{ l: 1 }}>
                      {item.title}
                    </Text>
                    {item.monospace && (
                      <ClickableArea
                        onClick={() =>
                          copyTextToClipboard(item.title as string).then(() =>
                            addToast('success', 'Copied to clipboard'),
                          )
                        }
                        style={{ width: 'auto' }}
                        p={1}
                        m={{ l: 1 }}
                        rounded>
                        <Icon icon="copy" small />
                      </ClickableArea>
                    )}
                  </>
                )}
              </>
            )}
          </Div>
        ))}
      </Div>
      <Div flex={{ align: 'center', gap: 2 }}>{children}</Div>
    </StyledViewNav>
  );
};

export const SecondaryPageNav: React.FC<
  PropsWithChildren<{
    background_color?: 'background' | 'surface';
  }>
> = ({ background_color, children }) => {
  return (
    <StyledViewNav
      secondary
      style={{ top: '57px' }}
      background_color={background_color || 'background'}>
      {children}
    </StyledViewNav>
  );
};

export const StyledViewContent = styled.div<{ light?: boolean }>(
  ({ light, theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 auto;
    min-width: 0;
    min-height: 100%;
    ${light &&
    css`
      background-color: ${theme.colors.surface.base.surface};
    `}
  `,
);

export const StyledViewSidebarSection = styled(Div)(
  ({ theme }) => css`
    padding: ${theme.spacing(4)};
    :not(:last-child) {
      border-bottom: ${theme.border};
    }
  `,
);

export const StyledViewContainerCard = styled(StyledCard)<{ border_top?: boolean }>(
  ({ theme, border_top }) => css`
    background-color: ${theme.colors.surface.base.surface};
    border: none;
    border-radius: 0;
    border-bottom: ${theme.border};
    ${border_top &&
    css`
      border-top: ${theme.border};
    `}
  `,
);

export const StyledViewSidebar = styled.div<{ width?: 'small' | 'large' }>(({ theme, width }) => {
  const computed_width = width === 'large' ? '40vw' : theme.pxToRem(420);
  return css`
    position: sticky;
    display: flex;
    flex-direction: column;
    top: 0;
    flex-basis: ${computed_width};
    width: ${computed_width};
    min-width: ${computed_width};
    background-color: ${theme.colors.surface.base.background};
    border-left: ${theme.border};
    height: 100vh;
    z-index: ${theme.zindex.sticky};

    ${StyledViewNav} {
      background-color: ${theme.colors.surface.base.background};
    }
  `;
});

export const StyledViewSidebarNav = styled(StyledViewNav)``;

export const StyledViewSidebarFooter = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: ${({ theme }) => theme.border};
  min-height: ${({ theme }) => theme.pxToRem(44)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing(2)};
`;

export const StyledViewScrollable = styled.div`
  flex-grow: 1;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;
