import { useContext, useState } from 'react';
import useSWR from 'swr';

import { OrderByDirection } from '../../../../../../typings/API.interface';
import { IgnoredEventCause } from '../../../../../../typings/IgnoredEvent.interface';
import APIMethodKeys from '../../../client/APIMethodKeys';
import { EventListFiltersProps, OrderBy } from '../../../typings/EventList.interface';
import {
  RequestListFiltersProps,
  RequestListOrderBy,
} from '../../../typings/RequestList.interface';
import { GlobalContext } from '../../contexts/GlobalContext';
import { StyledCardSection } from '../base/Card';
import Tabs from '../base/Tabs';
import Dropdown from '../Dropdown';
import ErrorBoundary from '../helpers/ErrorBoundary';
import BulkRetryHistory from './BulkRetryHistory';
import SubmitBulkRetry from './SubmitBulkRetry';

export type BulkRetryModels = 'events' | 'requests' | 'ignored_events';

export const bulk_retry_models_label: Record<BulkRetryModels, string> = {
  events: 'Events',
  requests: 'Requests',
  ignored_events: 'Ignored Events',
};

interface IgnoredEventsFiltersProps {
  transformation_id: string;
  cause: IgnoredEventCause[];
}

export type BulkRetryFilters =
  | EventListFiltersProps
  | RequestListFiltersProps
  | IgnoredEventsFiltersProps;

const DropdownContent: React.FC<{
  model: BulkRetryModels;
  filters: BulkRetryFilters;
  order_by: OrderBy | RequestListOrderBy;
  dir: OrderByDirection;
  onSubmit?: () => void;
}> = ({ model, filters, onSubmit }) => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const [active_tab, setActiveTab] = useState<string>('retry');

  const { data: ongoing_count, mutate: mutateOngoingCount } = useSWR(
    APIMethodKeys.bulk[model].count({ in_progress: true }),
    () => HookdeckAPI.bulk[model].count({ in_progress: true }),
  );
  const { data: history_count, mutate: mutateHistoryCount } = useSWR(
    APIMethodKeys.bulk[model].count(),
    () => HookdeckAPI.bulk[model].count({ in_progress: false }),
  );

  const revalidateCounts = () => {
    mutateOngoingCount();
    mutateHistoryCount();
  };

  return (
    <>
      <StyledCardSection>
        <Tabs
          onTabSelected={setActiveTab}
          active_tab={active_tab}
          border={false}
          p={{ x: 4, t: 3 }}
          tabs={[
            { label: 'Conditions', key: 'retry', icon: 'checklist' },
            {
              label: 'Ongoing',
              key: 'ongoing',
              icon: 'rotate_left',
              badge_label: ongoing_count?.count,
              badge_theme: ongoing_count && ongoing_count.count > 0 ? 'primary' : 'muted',
            },
            {
              label: 'History',
              key: 'history',
              icon: 'history',
              badge_label: history_count?.count,
              badge_theme: 'muted',
            },
          ]}
        />
      </StyledCardSection>
      {active_tab === 'retry' && (
        <SubmitBulkRetry
          model={model}
          onSubmit={onSubmit}
          revalidateCounts={revalidateCounts}
          query={filters}
        />
      )}
      {active_tab === 'ongoing' && (
        <BulkRetryHistory
          model={model}
          revalidateCounts={revalidateCounts}
          filters={{ in_progress: true }}
        />
      )}
      {active_tab === 'history' && (
        <BulkRetryHistory model={model} revalidateCounts={revalidateCounts} filters={{}} />
      )}
    </>
  );
};

const BulkRetryDropdown: React.FC<{
  model: BulkRetryModels;
  outline?: boolean;
  filters: BulkRetryFilters;
  order_by: OrderBy | RequestListOrderBy;
  dir: OrderByDirection;
  onSubmit?: () => void;
}> = (props) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { data: ongoing_count } = useSWR(
    APIMethodKeys.bulk[props.model].count({ in_progress: true }),
    () => HookdeckAPI.bulk[props.model].count({ in_progress: true }),
  );
  return (
    <Dropdown
      icon="retry"
      minimal={!props.outline}
      outline={props.outline}
      small={!props.outline}
      parent_width={{ min: 560, max: 560 }}
      label="Bulk Retry"
      badge={
        !props.outline && ongoing_count && ongoing_count.count > 0
          ? {
              label: String(ongoing_count.count),
            }
          : undefined
      }>
      <ErrorBoundary>
        <DropdownContent {...props} />
      </ErrorBoundary>
    </Dropdown>
  );
};

export default BulkRetryDropdown;
