import { useContext } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import LINKS from '../../../../../configs/links';
import { useCopyToClipboard } from '../../../../../utils/copy';
import { showChat } from '../../../../../utils/liveChat';
import { rejection_cause_labels } from '../../../../../utils/rejection-causes';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Text from '../../../../common/base/Text';
import CopyableField from '../../../../common/CopyableField';
import DisplayDate from '../../../../common/DisplayDate';
import StyledEllipsisOverflow from '../../../../common/helpers/EllipsisOverflow';
import { Div } from '../../../../common/helpers/StyledUtils';
import Modal from '../../../../common/Modal';
import { Status } from '../../../../common/Status';
import Table from '../../../../common/Table';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UserContext } from '../../../../contexts/UserContext';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import Alert from '../../../../common/base/Alert';

const StyledNumber = styled.div(
  ({ theme }) => css`
    border-radius: 50%;
    height: ${theme.pxToRem(24)};
    width: ${theme.pxToRem(24)};
    line-height: ${theme.pxToRem(20)};
    font-weight: ${theme.font_weigths.medium};
    font-size: ${theme.pxToRem(12)};
    margin-right: ${theme.spacing(4)};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.surface.container.neutral};
  `,
);

const PostCreateModal = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const { query, updateSearchQuery } = useSearchQuery<{
    created?: string;
    troubleshoot?: boolean;
  }>();
  const history = useHistory();
  const copyToClipboard = useCopyToClipboard();

  const { data: connection } = useSWR(
    query.created && APIMethodKeys.webhooks.get(query.created),
    () => HookdeckAPI.webhooks.get(query.created!),
  );

  const request_filters = connection && {
    source_id: connection?.source.id,
    limit: 5,
    status: 'rejected',
    ingested_at: { gte: connection?.created_at },
  };

  const { data: requests } = useSWR(
    request_filters && APIMethodKeys.requests.list(request_filters),
    () => HookdeckAPI.requests.list(request_filters),
    {
      refreshInterval: 2000,
      revalidateOnFocus: true,
    },
  );

  const { data: events } = useSWR(
    connection &&
      APIMethodKeys.events.list({ webhook_id: [connection?.id], limit: 5, cli_id: { all: true } }),
    () =>
      HookdeckAPI.events.list({ webhook_id: [connection?.id], limit: 5, cli_id: { all: true } }),
    {
      refreshInterval: 2000,
      revalidateOnFocus: true,
    },
  );

  const { data: active_cli_sessions } = useSWR(
    connection &&
      !connection?.destination.url &&
      user &&
      APIMethodKeys.cli_clients.list({ active: true }),
    () => HookdeckAPI.cli_clients.getSessions({ active: true }),
  );

  const rows = [
    ...(events
      ? events.models.map((event) => ({
          id: event.id,
          fields: [
            <span key={event.id}>
              <Status {...event} show_response />
            </span>,
            <DisplayDate key={event.id} date={event.created_at} />,
            {
              clickable_area: true,
              element: (
                <Div w={100} flex={{ justify: 'flex-end' }}>
                  <Icon muted icon="link" />
                </Div>
              ),
            },
          ],
        }))
      : []),
    ...(requests
      ? requests.models.map((request) => ({
          id: request.id,
          fields: [
            <DisplayDate key={request.id} date={request.ingested_at} />,
            <span key={request.id} style={{ maxWidth: '100%' }}>
              <Badge danger>
                <Icon icon="error" left />
                <StyledEllipsisOverflow>
                  {rejection_cause_labels[request.rejection_cause]}
                </StyledEllipsisOverflow>
              </Badge>
            </span>,
            {
              clickable_area: true,
              element: (
                <Div w={100} flex={{ justify: 'flex-end' }}>
                  <Icon muted icon="link" />
                </Div>
              ),
            },
          ],
        }))
      : []),
  ];

  if (!connection) {
    return null;
  }

  return (
    <>
      {!query.troubleshoot ? (
        <Modal onClose={() => updateSearchQuery({ created: undefined })}>
          <Div flex={{ justify: 'center', align: 'center', direction: 'column' }}>
            <Text heading size="l" as="h3" flex={{ align: 'center' }} center m={{ b: 2 }}>
              <Icon icon="success_circle" success left large />
              Connection Created
            </Text>
            <Text as="p" muted center m={{ b: 5 }}>
              Use this URL to receive your requests from "{connection.source.name}". Valid requests
              to this URL will be sent to your destination "{connection.destination.name}", and
              Hookdeck will reply immediately with an HTTP 200.
            </Text>
          </Div>
          <CopyableField monospace value={connection?.source.url} />
          {connection &&
            !connection.destination.url &&
            active_cli_sessions &&
            active_cli_sessions.length === 0 && (
              <>
                <Alert info inline m={{ y: 4 }}>
                  Connect your CLI to receive events on your localhost.
                </Alert>
                <StyledCard overflow_hidden>
                  <StyledCardSection p={{ x: 4, y: 1 }} muted>
                    <Text size="s" muted subtitle>
                      CLI Instructions
                    </Text>
                  </StyledCardSection>
                  <StyledCardSection p={4}>
                    <Div flex={{ align: 'center' }}>
                      <StyledNumber>1</StyledNumber>
                      <Text>
                        To receive events on your local server,{' '}
                        <Link href={LINKS.product_docs.cli_install}>install our CLI {'->'}</Link>
                      </Text>
                    </Div>
                    <Div flex={{ align: 'center' }} m={{ y: 4 }}>
                      <StyledNumber>2</StyledNumber>
                      <Text m={{ r: 1 }}>Run</Text>
                      <Div as="code" m={{ r: 1 }}>
                        hookdeck login
                      </Div>
                      <Button
                        minimal
                        onClick={() => copyToClipboard('hookdeck login')}
                        small
                        icon="copy"
                      />
                    </Div>
                    <Div flex={{ align: 'center' }}>
                      <StyledNumber>3</StyledNumber>
                      <Text m={{ r: 1 }}>Run</Text>
                      <Div as="code" m={{ r: 1 }}>
                        hookdeck listen [PORT] {connection.source.name}
                      </Div>
                      <Button
                        minimal
                        onClick={() =>
                          copyToClipboard(`hookdeck listen [PORT] ${connection.source.name}`)
                        }
                        small
                        icon="copy"
                      />
                    </Div>
                  </StyledCardSection>
                </StyledCard>
              </>
            )}
          {connection &&
            (connection.destination.url ||
              (active_cli_sessions && active_cli_sessions.length > 0)) && (
              <StyledCard overflow_hidden m={{ t: 4 }}>
                {rows.length > 0 ? (
                  <Table
                    widths={[1 / 2, 1 / 2, { min: 16, max: 16 }]}
                    headers={['Date', 'Status', '']}
                    rows={rows}
                    onRowSelected={(id) => {
                      if (id?.startsWith('evt_')) {
                        history.push(`/events/${id}`);
                      } else {
                        history.push(`/requests/${id}`);
                      }
                    }}
                  />
                ) : (
                  <StyledCardSection
                    muted
                    flex={{ align: 'center', justify: 'center', direction: 'column' }}
                    p={{ x: 11, y: 22 }}>
                    <Icon icon="loading" m={{ b: 4 }} />
                    <Text subtitle>Waiting on your first request</Text>
                    <div>
                      <Button
                        minimal
                        icon="help"
                        onClick={() => updateSearchQuery({ troubleshoot: true })}
                        m={{ t: 6 }}
                        small>
                        I don't see my requests
                      </Button>
                    </div>
                  </StyledCardSection>
                )}
              </StyledCard>
            )}
          <Div flex={{ justify: 'space-between' }} m={{ t: 5 }}>
            <Button outline onClick={() => updateSearchQuery({ created: undefined })}>
              Close
            </Button>
            <Button primary to={`/events?webhook_id=${connection.id}`} icon="events">
              View All Events
            </Button>
          </Div>
        </Modal>
      ) : (
        <Modal
          submit_label="Help"
          onSubmit={showChat}
          title="Request Troubleshooting"
          onClose={() => updateSearchQuery({}, { remove_keys: ['troubleshoot'] })}>
          <Text m={{ b: 4 }}>Your requests may not be showing for a variety of reasons:</Text>
          <StyledCard p={4}>
            <Text heading m={{ b: 2 }}>
              Request method is HTTP <code>GET</code>
            </Text>
            <Text muted>
              Hookdeck does not accept HTTP GET requests unless explicitly enabled on your Source
            </Text>
          </StyledCard>
          <StyledCard p={4} m={{ t: 4 }}>
            <Text heading m={{ b: 2 }}>
              The Content-Type is not supported or unparsable
            </Text>
            <Text muted>
              Hookdeck accepts requests with one of the following "Content-Type" HTTP header values:
            </Text>
            <pre>
              text/plain <br />
              text/xml <br />
              application/json <br />
              application/x-www-form-urlencoded <br />
              application/xml <br />
              application/*+json <br />
              multipart/form-data
              <br />
            </pre>
            <Text muted>
              In some instances, the Content-Type is supported but the data is unparsable. Hookdeck
              will reject the request.
            </Text>
          </StyledCard>
          <StyledCard p={4} m={{ t: 4 }}>
            <Text heading m={{ b: 2 }}>
              The sender implements a challenge to verify the webhook URL
            </Text>
            <Text muted>
              Some webhook providers implement custom handshakes to verify the URL (also know as
              "Challenges"). Hookdeck will implement support for these handshakes on an ad-hoc basis
              when they are requested.{' '}
              <a href={LINKS.product_docs.inbuilt_source_support} target="_blank" rel="noreferrer">
                View the current list of supported platforms
              </a>
              .
              <br />
              <Button outline m={{ t: 3 }} onClick={showChat}>
                Request a new one
              </Button>
            </Text>
          </StyledCard>
          <StyledCard p={4} m={{ t: 4 }}>
            <Text heading m={{ b: 2 }}>
              The sender requires whitelisting our IP
            </Text>
            <Text muted>
              In some rare cases the providers might require you to whitelist Hookdeck IP's to send
              requests to our servers. You can whitelist Cloudflare IPs which are found{' '}
              <a href="http://cloudflare.com/ips/" target="_blank" rel="noreferrer">
                here
              </a>
              .
            </Text>
          </StyledCard>
        </Modal>
      )}
    </>
  );
};

export default PostCreateModal;
