import { add, format, getDay } from 'date-fns';
import { useField } from 'formik';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { usePopper } from 'react-popper';
import styled, { css, keyframes } from 'styled-components';

import { EventListFiltersProps } from '../../../../../typings/EventList.interface';
import { RequestListFiltersProps } from '../../../../../typings/RequestList.interface';
import { Resolution } from '../../../../../utils/chart';
import formatCubeQuery, { CubeModel } from '../../../../../utils/formatCubeQuery';
import { rejection_cause_labels } from '../../../../../utils/rejection-causes';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Divider from '../../../../common/base/Divider';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useCubeQueryLocalRawData } from '../../../../hooks/useCubeQueryLocalRawData';

const placeholder_data = Array.from(Array(60).keys()).map(() => Math.random() * 100);

const StyledChartContainer = styled.div(
  ({ theme }) => css`
    height: 68px;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    width: 100%;
    margin-bottom: ${theme.spacing(0)};
  `,
);

const StyledChartEntry = styled.div<{
  low_opacity?: boolean;
  hovered?: boolean;
  within_range?: boolean;
}>(
  ({ low_opacity, hovered, within_range, theme }) => css`
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    z-index: 1;
    padding: 0 2px;
    cursor: ew-resize;
    ${within_range &&
    css`
      background-color: ${theme.colors.surface.base.surface};
    `}
    ${low_opacity &&
    !hovered &&
    css`
      ${StyledChartValue} {
        opacity: ${low_opacity ? 0.4 : 1};
        transition: opacity 0.15s ease-in;
      }
    `}
    ${hovered &&
    css`
      z-index: 10;
    `}
  `,
);

const fade = keyframes`
  from {
    opacity: 0.25;
  }
`;

const StyledChartValue = styled.div<{
  color?: string;
  $loading?: boolean;
  max_value: number;
  value: number;
}>(
  ({ theme, color, max_value, value, $loading }) => css`
    height: ${((value / max_value) * 100).toFixed(1)}%;
    min-height: 4px;
    background-color: ${value === 0 || !color
      ? theme.colors.surface.container.neutral
      : theme.colors.surface.base[color]};
    border-radius: 2px;
    width: 100%;
    margin: 1px 0px;
    align-self: end;
    ${$loading &&
    css`
      opacity: 0.6 !important;
      background-color: ${theme.colors.surface.container.neutral};
      animation: ${fade} 1s infinite alternate;
    `}
  `,
);

const StyledChartTooltipWrapper = styled.div`
  width: fit-content;
  z-index: 2000;
`;

const StyledChartTooltip = styled(StyledCard)<{ timerange_selected: boolean }>(
  ({ theme, timerange_selected }) => css`
    box-shadow: ${theme.elevation[2]};
    margin-top: ${timerange_selected ? '54px' : '8px'};
  `,
);

const granularity_formating = {
  second: 'MMM d, h:mm:ss a',
  minute: 'MMM d, h:mm a',
  hour: 'MMM d, h:mm a',
  day: 'MMM d, h:mm a',
};

const getTooltipTimeLabel = (start: Date, end: Date, resolution: Resolution) => {
  const format_string = granularity_formating[resolution.granularity];
  const start_label = format(start, format_string);
  const end_label = format(
    end,
    getDay(start) === getDay(end) ? format_string.replace('MMM d,', '') : format_string,
  );

  return `${start_label} - ${end_label}`;
};

const model_dimention_configs = {
  Requests: {
    rejectionCause: [
      {
        value: null as string | null,
        color: 'success',
        label: 'Accepted',
      },
    ],
  },
  Events: {
    status: [
      {
        value: 'SUCCESSFUL',
        color: 'success',
        label: 'Succeeded',
      },
      {
        value: 'FAILED',
        color: 'danger',
        label: 'Failed',
      },

      {
        value: 'QUEUED',
        color: 'primary',
        label: 'Queued',
      },
      {
        value: 'HOLD',
        color: 'warning',
        label: 'Paused',
      },
      {
        value: 'SCHEDULED',
        color: 'warning',
        label: 'Scheduled',
      },
    ],
  },
  EventRequestsTransformations: {
    log_level: [
      {
        value: null,
        color: 'success',
        label: 'OK',
      },
      {
        value: 'info',
        color: 'success',
        label: 'Info',
      },
      {
        value: 'debug',
        color: 'success',
        label: 'Debug',
      },
      {
        value: 'warn',
        color: 'warning',
        label: 'Warn',
      },
      {
        value: 'error',
        color: 'danger',
        label: 'Error',
      },
      {
        value: 'fatal',
        color: 'danger',
        label: 'Fatal',
      },
    ],
  },
  IgnoredEvents: {
    cause: [
      {
        value: 'TRANSFORMATION_FAILED',
        color: 'danger',
        label: 'Failed',
      },
    ],
  },
};

for (const [key, label] of Object.entries(rejection_cause_labels)) {
  model_dimention_configs.Requests.rejectionCause.push({
    value: key,
    color: 'danger',
    label,
  });
}

const HistogramChart: React.FC<{
  model: CubeModel;
  dimention: string;
  filters: EventListFiltersProps | RequestListFiltersProps;
  start_date: Date;
  end_date: Date;
  resolution: Resolution;
  refresh_key?: string;
}> = ({ model, dimention, filters, start_date, end_date, resolution, refresh_key }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [{ value: timebar_start }] = useField('timebar_start');
  const [{ value: timebar_end }] = useField('timebar_end');
  const element = document.querySelector('#main');
  const first_render_refresh_key = useRef(refresh_key);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: element as any,
          mainAxis: true,
          altAxis: false,
          padding: 8,
        },
      },
    ],
  });

  const stats_chart_query = useMemo(() => {
    if (filters.request && Object.values(filters.request).some((v) => v !== undefined)) {
      return null;
    }
    return formatCubeQuery(model, {
      filters: {
        ...filters,
        date: {
          min: start_date.toISOString(),
          max: end_date.toISOString(),
        },
      },
      granularity: resolution.granularity,
      dimentions: [dimention],
    });
  }, [filters, start_date.toISOString(), end_date.toISOString(), resolution]);

  const [hovered_date, setHoveredDate] = useState<Date | null>(null);

  const { raw_data, refetch, is_loading } = useCubeQueryLocalRawData(stats_chart_query, {
    resetResultSetOnChange: false,
  });

  useEffect(() => {
    if (refresh_key && refetch && !is_loading && first_render_refresh_key.current !== refresh_key) {
      refetch();
    }
  }, [refresh_key]);

  const { values, max_value } = useMemo<{
    values: Array<{ start: Date; end: Date; values: Record<string, number> }>;
    max_value: number;
  }>(() => {
    let max_value = 0;

    const series: any = raw_data?.map((row: Record<string, any>) => {
      const model = Object.keys(row)[0].split('.')[0];
      return {
        date: row[`${model}.createdAt`],
        dimention: row[`${model}.${dimention}`],
        count: parseInt(row[`${model}.count`]),
      };
    });

    return {
      values:
        series &&
        Array.from(Array(resolution.steps).keys()).reduce((values: any[], index) => {
          const step_start = add(start_date, {
            seconds: index * resolution.unit * resolution.factor_to_seconds,
          });
          const step_end = add(step_start, {
            seconds: resolution.unit * resolution.factor_to_seconds,
          });
          const totals = series.reduce(
            (totals, row) => {
              if (
                new Date(row.date).getTime() < step_end.getTime() &&
                new Date(row.date).getTime() >= step_start.getTime()
              ) {
                const value = row.count;
                const dimention = row.dimention;
                return {
                  ...totals,
                  total: totals.total + value,
                  [dimention]: (totals[dimention] || 0) + value,
                };
              }
              return totals;
            },
            {
              total: 0,
            },
          );
          if (totals.total > max_value) {
            max_value = totals.total;
          }
          values.push({ start: step_start, end: step_end, values: totals });
          return values;
        }, []),
      max_value,
    };
  }, [raw_data, start_date, resolution]);

  const timerange_selected = timebar_start !== 0 || timebar_end !== resolution.steps - 1;

  const displayed_hovered_date =
    hovered_date && values?.find((v) => hovered_date.getTime() === v.start.getTime());

  return (
    <StyledChartContainer>
      {!stats_chart_query && (
        <StyledCard muted flex={{ align: 'center', justify: 'center' }}>
          <Text muted>Histogram is unavailable when searching or filtering on request values.</Text>
        </StyledCard>
      )}
      {!values &&
        stats_chart_query &&
        placeholder_data.map((v, i) => (
          <StyledChartEntry key={i}>
            <StyledChartValue $loading max_value={100} value={v} />
          </StyledChartEntry>
        ))}
      {stats_chart_query &&
        values?.map(({ start, values }, i) => {
          const hovered = hovered_date && hovered_date.getTime() === start.getTime();
          return (
            <StyledChartEntry
              low_opacity={(hovered_date && !hovered) || timebar_start > i || timebar_end < i}
              hovered={!!hovered}
              key={start.getTime()}
              onMouseEnter={() => setHoveredDate(start)}
              onMouseLeave={() => setHoveredDate(null)}>
              {hovered && <div ref={setReferenceElement} />}
              {values.total === 0 && <StyledChartValue max_value={max_value} value={0} />}
              {model_dimention_configs[model][dimention].map(
                (config) =>
                  values[config.value] > 0 && (
                    <StyledChartValue
                      key={config.value}
                      color={config.color}
                      max_value={max_value}
                      value={values[config.value]}
                    />
                  ),
              )}
            </StyledChartEntry>
          );
        })}
      {displayed_hovered_date && (
        <StyledChartTooltipWrapper
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
          <StyledChartTooltip timerange_selected={timerange_selected} overflow_hidden>
            <StyledCardSection p={{ x: 3, y: 2 }}>
              <Text text_wrap={false} subtitle as="span">
                {getTooltipTimeLabel(
                  displayed_hovered_date.start,
                  displayed_hovered_date.end,
                  resolution,
                )}
              </Text>
            </StyledCardSection>
            <StyledCardSection p={{ x: 3, y: 2 }}>
              {model_dimention_configs[model][dimention]
                .filter((config) => !!displayed_hovered_date.values[config.value])
                .map((config) => (
                  <Div key={config.value} m={{ b: 1 }} flex={{ justify: 'space-between' }}>
                    <Text subtitle muted>
                      {config.label}
                    </Text>
                    <Text subtitle muted m={{ l: 3 }}>
                      <NumberFormat
                        renderText={(v) => v}
                        displayType="text"
                        value={displayed_hovered_date.values[config.value] || 0}
                        thousandSeparator={','}
                      />
                    </Text>
                  </Div>
                ))}
              {displayed_hovered_date.values.total ? (
                <>
                  <Divider m={{ y: 2 }} />
                  <Div flex={{ justify: 'space-between' }}>
                    <Text subtitle>Total</Text>
                    <Text subtitle>
                      <NumberFormat
                        renderText={(v) => v}
                        displayType="text"
                        value={displayed_hovered_date.values.total || 0}
                        thousandSeparator={','}
                      />
                    </Text>
                  </Div>
                </>
              ) : (
                <Text>No {model}</Text>
              )}
            </StyledCardSection>
          </StyledChartTooltip>
        </StyledChartTooltipWrapper>
      )}
    </StyledChartContainer>
  );
};

export default memo(HistogramChart);
