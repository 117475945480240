import { Fragment, useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../client/APIMethodKeys';
import { RelativeDate } from '../../../utils/date';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UserContext } from '../../contexts/UserContext';
import { DashboardContext } from '../../scenes/DashboardScene/DashboardContext';
import Button, { ClickableArea } from '../base/Button';
import Divider from '../base/Divider';
import Icon from '../base/Icon';
import Text from '../base/Text';
import Dropdown from '../Dropdown';
import { Div } from '../helpers/StyledUtils';
import { FilterComponent, FilterFormValues } from './FilterComponents';
import { StyledCardSection } from '../base/Card';

export interface IRecentFilters {
  date?: {
    relative?: RelativeDate;
    min?: string;
    max?: string;
  };
  webhook_id?: string[];
  request?: {
    headers?: string;
    body?: string;
    parsed_query?: string;
    path?: string;
  };
  source_id?: string[];
  destination_id?: string[];
  response?: {
    response_status?: {
      min?: number;
      max?: number;
    };
    error_code?: string[];
  };
  status?: string[];
  cli_user_id?: string[];
}

const StyledRecentFiltersDropdown = styled(Div)(
  ({ theme }) => css`
    width: ${theme.pxToRem(416)};
    max-height: ${theme.pxToRem(264)};
    overflow-y: auto;
  `,
);

interface RecentFiltersProps {
  recent_filters: IRecentFilters;
  filters_by_key: {
    [key: string]: FilterComponent;
  };
  onSelect: (values: FilterFormValues) => void;
}

const RecentFilters = ({ recent_filters, filters_by_key, onSelect }: RecentFiltersProps) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const { team } = useContext(DashboardContext);

  const { unique_webhook_ids, unique_destination_ids, unique_source_ids } = useMemo(() => {
    const new_filters: { webhook_id: string[]; destination_id: string[]; source_id: string[] } = {
      webhook_id: [],
      destination_id: [],
      source_id: [],
    };

    Object.values(recent_filters).forEach((f) => {
      f.webhook_id?.forEach((id) => {
        new_filters.webhook_id = new_filters.webhook_id ? [...new_filters.webhook_id, id] : [id];
      });
      f.destination_id?.forEach((id) => {
        new_filters.destination_id = new_filters.destination_id
          ? [...new_filters.destination_id, id]
          : [id];
      });
      f.source_id?.forEach((id) => {
        new_filters.source_id = new_filters.source_id ? [...new_filters.source_id, id] : [id];
      });
    });

    let unique_webhook_ids: string[] = [];
    let unique_destination_ids: string[] = [];
    let unique_source_ids: string[] = [];

    unique_webhook_ids =
      new_filters.webhook_id && Array.from(new Set(new_filters.webhook_id.map((id) => id))).sort();

    unique_destination_ids =
      new_filters.destination_id &&
      Array.from(new Set(new_filters.destination_id.map((id) => id))).sort();

    unique_source_ids =
      new_filters.source_id && Array.from(new Set(new_filters.source_id.map((id) => id))).sort();

    return { unique_webhook_ids, unique_destination_ids, unique_source_ids };
  }, [recent_filters]);

  const { data: webhooks } = useSWR(
    unique_webhook_ids.length > 0 ? APIMethodKeys.webhooks.list({ id: unique_webhook_ids }) : null,
    () =>
      HookdeckAPI.webhooks.list(
        unique_webhook_ids.length > 0 ? { id: unique_webhook_ids } : { limit: 1 },
      ),
  );

  const { data: destinations } = useSWR(
    unique_destination_ids.length > 0
      ? APIMethodKeys.destinations.list({ id: unique_destination_ids })
      : null,
    () =>
      HookdeckAPI.destinations.list(
        unique_destination_ids.length > 0 ? { id: unique_destination_ids } : { limit: 1 },
      ),
  );

  const { data: sources } = useSWR(
    unique_source_ids.length > 0 ? APIMethodKeys.sources.list({ id: unique_source_ids }) : null,
    () =>
      HookdeckAPI.sources.list(
        unique_source_ids.length > 0 ? { id: unique_source_ids } : { limit: 1 },
      ),
  );

  const { data: team_members } = useSWR(APIMethodKeys.teams.listMembers(team!.id), () =>
    HookdeckAPI.teams.listMembers(),
  );

  const webhooks_by_id = webhooks
    ? webhooks.models.reduce((object, webhook) => ({ ...object, [webhook.id]: webhook }), {})
    : { no_value: 0 };

  const destinations_by_id = destinations
    ? destinations.models.reduce(
        (object, destination) => ({ ...object, [destination.id]: destination }),
        {},
      )
    : { no_value: 0 };

  const sources_by_id = sources
    ? sources.models.reduce((object, source) => ({ ...object, [source.id]: source }), {})
    : { no_value: 0 };

  const team_members_by_id = team_members
    ? team_members.reduce(
        (object, team_member) => ({ ...object, [team_member.user_id]: team_member }),
        {},
      )
    : { no_value: 0 };

  const data_by_id = {
    webhook_id: webhooks_by_id,
    destination_id: destinations_by_id,
    source_id: sources_by_id,
    team_members: team_members_by_id,
  };

  const recent_filters_array = Object.values(recent_filters)
    .map((recent_filter) => {
      return Object.entries(recent_filter)
        .filter(([key]) => filters_by_key[key])
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
    })
    .filter((recent_filter) => Object.keys(recent_filter).length > 0);
  return (
    <Dropdown
      placement="bottom-end"
      renderToggle={(open, toggle) => (
        <Button minimal small icon="filter" onClick={() => toggle(!open)}>
          Recent Filters
        </Button>
      )}>
      {(toggle) => (
        <>
          <StyledCardSection muted p={{ x: 3, y: 1 }}>
            <Text size="xs" subtitle>
              Recent Filters
            </Text>
          </StyledCardSection>
          <StyledRecentFiltersDropdown>
            {recent_filters_array.length > 0 ? (
              recent_filters_array.map((f, i) => (
                <Fragment key={JSON.stringify(f)}>
                  {i !== 0 && <Divider />}
                  <ClickableArea
                    w={100}
                    flex={{ direction: 'column', gap: 2 }}
                    p={{ x: 3, y: 2 }}
                    onClick={() => {
                      onSelect(f);
                      toggle(false);
                    }}>
                    {Object.entries(f)
                      .slice(0, 4)
                      .map(([key, f]) => (
                        <Div flex={{ align: 'center' }} m={{ r: 3 }} key={key} w={100}>
                          <Icon left icon={filters_by_key[key].icon} />
                          <Text subtitle ellipsis>
                            {filters_by_key[key].valuesToString(
                              f as any,
                              data_by_id,
                              user || undefined,
                            )}
                          </Text>
                        </Div>
                      ))}
                  </ClickableArea>
                </Fragment>
              ))
            ) : (
              <Div p={3}>
                <Text text_wrap={false}>No recent filters</Text>
              </Div>
            )}
          </StyledRecentFiltersDropdown>
        </>
      )}
    </Dropdown>
  );
};

export default RecentFilters;
