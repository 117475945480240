import styled, { css } from 'styled-components';
import Button from './base/Button';
import { IconName } from './base/Icon';
import Text from './base/Text';
import { Div } from './helpers/StyledUtils';

interface EmptyStateProps {
  title: string;
  description: string;
  asset: string;
  cta: {
    icon: IconName;
    label: string;
    onClick?: () => void;
    to?: string;
  };
}

const StyledEmptyStateCard = styled.div(
  ({ theme }) => css`
    width: 100%;
    max-width: 918px;
    height: 504px;
    display: flex;
    align-self: center;
    border-radius: ${theme.radius.large};
    border: 1px dashed ${theme.colors.outline.neutral};
    position: relative;
    background-color: ${theme.colors.surface.base.background};

    z-index: 0;

    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: ${theme.radius.large_inset};
      background-size: 38.2px 36px;
      background-image: linear-gradient(
          to right,
          ${theme.colors.outline.neutral} 1px,
          transparent 1px
        ),
        linear-gradient(to bottom, ${theme.colors.outline.neutral} 1px, transparent 1px);
    }

    &:after {
      border-radius: ${theme.radius.large_inset};
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: radial-gradient(
        60% 70% at 80% 50%,
        ${theme.mode === 'dark' ? 'rgba(17, 22, 29, 0.70)' : 'rgba(250, 251, 253, 0.60)'} 0%,
        ${theme.colors.surface.base.background} 100%
      );
    }
  `,
);

const EmptyState = ({ title, description, cta, asset }: EmptyStateProps) => {
  return (
    <Div flex={{ align: 'center', justify: 'center', grow: true }}>
      <StyledEmptyStateCard>
        <Div w={50} flex={{ align: 'center' }}>
          <Div p={{ x: 15 }}>
            <Text heading size="xl" m={{ b: 2 }}>
              {title}
            </Text>
            <Text muted>{description}</Text>
            {cta && (
              <Button m={{ t: 8 }} primary icon={cta.icon} to={cta.to} onClick={cta.onClick}>
                {cta.label}
              </Button>
            )}
          </Div>
        </Div>
        <Div w={50} flex={{ align: 'center', justify: 'center' }} p={8}>
          <img src={asset} />
        </Div>
      </StyledEmptyStateCard>
    </Div>
  );
};

export default EmptyState;
