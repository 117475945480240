import { useContext, useMemo } from 'react';
import useSWR from 'swr';

import APIMethodKeys from '../../../client/APIMethodKeys';
import { capitalizeFirstLetter } from '../../../utils';
import isEmpty from '../../../utils/isEmpty';
import { GlobalContext } from '../../contexts/GlobalContext';
import BarChart from '../Chart/BarChart';
import { ChartDataSet } from '../Chart/Chart';
import { generateRandomChartColor } from '../Chart/LegendColor';
import LineChart from '../Chart/LineChart';
import { Div } from '../helpers/StyledUtils';
import { default_data_formatter, metric_definitions, MetricName } from './metric-definitions';
import MetricError from './MetricError';
import MetricNoData from './MetricNoData';
import { ChartData, MetricChartType, MetricConfigs, useMetric } from './useMetric';
import { useTheme } from 'styled-components';

const MetricChart: React.FC<{
  height?: number;
  type: MetricChartType;
  metric: MetricName;
  configs: MetricConfigs;
  refresh_key?: number;
}> = ({ type, metric, refresh_key, configs, height }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const theme = useTheme();
  const { data, error, rate_as } = useMetric<ChartData>(type, metric, refresh_key || 0, configs);

  const is_array = Array.isArray(data?.data);

  const {
    type: metric_type,
    resource,
    tooltip_label,
    formatDataForDisplay,
  } = metric_definitions[metric];

  const unique_ids = useMemo(
    () => (!is_array && data ? Object.entries(data).map(([key]) => key) : []),
    [data],
  );

  const { data: resources } = useSWR(
    unique_ids.length > 0 ? APIMethodKeys[`${resource}s`].list({ id: unique_ids }) : null,
    () =>
      HookdeckAPI[`${resource}s`].list(
        unique_ids.length > 0 ? { id: unique_ids, archived: true } : { limit: 1 },
      ) as any,
  );

  const resources_by_id = useMemo(
    () =>
      resources?.models
        ? resources.models.reduce(
            (object, resource) => ({ ...object, [resource.id]: resource }),
            {},
          )
        : {},
    [resources],
  );

  let has_data = false;
  let formated_data: ChartDataSet[] = [];
  if (is_array) {
    has_data = data?.data?.some((d) => d.y !== 0) || false;
    data &&
      formated_data.push({
        ...data,
        key: metric,
        label: tooltip_label,
        getDataLabel:
          formatDataForDisplay ||
          ((v) => default_data_formatter[metric_type](v, rate_as || undefined)),
        theme_color: 'primary',
        metric_type,
      });
  } else {
    formated_data =
      data &&
      resources_by_id &&
      !isEmpty(data) &&
      !isEmpty(resources_by_id) &&
      Object.entries(data).map(([key, data_entry]) => ({
        ...data_entry,
        key,
        label: resources_by_id[key]
          ? resource === 'webhook'
            ? resources_by_id[key]?.full_name
            : resources_by_id[key]?.name
          : 'Deleted',
        getDataLabel:
          formatDataForDisplay ||
          ((v) => default_data_formatter[metric_type](v, rate_as || undefined)),
        hex_color: resources_by_id[key]
          ? generateRandomChartColor(key)
          : theme.colors.on.neutral.disabled,
        metric_type,
      }));
    has_data = formated_data && !isEmpty(formated_data);
  }

  const props = {
    legend_placeholder: configs.options?.dimentions && capitalizeFirstLetter(resource),
    datasets: !formated_data ? null : formated_data,
    loading: data === undefined,
    height: height || 478,
  };

  if (error) {
    return (
      <Div
        flex={{ direction: 'column', align: 'center', justify: 'center' }}
        h={{ px: height || 478 }}>
        <MetricError />
      </Div>
    );
  }

  if (!has_data && data !== undefined) {
    return (
      <Div
        flex={{ direction: 'column', align: 'center', justify: 'center' }}
        h={{ px: height || 478 }}>
        <MetricNoData />
      </Div>
    );
  }

  return (
    <>
      {type === 'chart:line' && <LineChart {...props} />}
      {type === 'chart:bar' && <BarChart {...props} />}
    </>
  );
};

export default MetricChart;
