import { useContext, useEffect } from 'react';
import useSWR from 'swr';

import { Grid, GridUnit } from '@hookdeck/theme';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import LINKS from '../../../../configs/links';
import Alert from '../../../common/base/Alert';
import Badge from '../../../common/base/Badge';
import { StyledCard } from '../../../common/base/Card';
import Container from '../../../common/base/Container';
import Icon from '../../../common/base/Icon';
import Link from '../../../common/base/Link';
import Text from '../../../common/base/Text';
import { Div } from '../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useEventList from '../../../hooks/useEventList';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useRequestList from '../../../hooks/useRequestList';
import FullscreenLayout from '../../../layouts/FullscreenLayout';
import { StyledProgress } from '../Connections/Create/CreateView';
import GuidedConnectionNav from './GuidedConnectionNav';
import AddDestination from './Steps/AddDestination';
import AddRules from './Steps/AddRules';
import AddSource from './Steps/AddSource';
import PreviewEvents from './Steps/PreviewEvents';
import PreviewRequests from './Steps/PreviewRequests';
import InstallCLI from './Steps/InstallCLI';
import { DashboardContext } from '../DashboardContext';

const steps = [
  {
    key: 'source',
    nav_label: 'Define Request Source',
    label: 'Define your request source',
    description: 'Name and configure the publisher of your HTTP requests.',
    icon: 'source' as const,
    Component: AddSource,
    reference: {
      title: 'Sources',
      text: 'Request sources represent the origin or publisher of your events. In Hookdeck, any service that sends HTTP requests can be defined as a request source. They can be webhooks, SDKs, devices, customer facing endpoints or other services.',
      link: LINKS.product_docs.sources,
    },
  },
  {
    key: 'requests',
    label: 'Send requests to Hookdeck',
    description: 'Trigger HTTP requests from the service defined above into Hookdeck.',
    icon: 'requests' as const,
    reveal: (context) => !!context.source,
    Component: PreviewRequests,
    full_width: true,
  },
  {
    key: 'destination',
    nav_label: 'Define Event Destination',
    label: 'Define your event destination',
    description:
      'Name and configure the destination you’d like Hookdeck to route the above HTTP requests to.',
    icon: 'destination' as const,
    reveal: (context) => !!(context.requests_list && context.requests_list.count > 0),
    Component: AddDestination,
    reference: {
      title: 'Destinations',
      text: 'In Hookdeck, HTTP requests can be routed to any HTTP endpoint, a local test environment (localhost) via the Hookdeck CLI, or a mock API endpoint. When HTTP requests reach Hookdeck, the platform queues an outgoing event to the corresponding destination.',
      link: LINKS.product_docs.destinations,
    },
  },
  {
    key: 'rules',
    nav_label: 'Define Connection Rules',
    label: 'Define your connection rules',
    description:
      'Optionally configure rules that dictate the behavior of events being routed to your destination.',
    icon: 'rule' as const,
    optional: true,
    Component: AddRules,
    reveal: (context) => !!context.destination,
    reference: {
      title: 'Rules',
      text: 'Hookdeck offers different types of rules that can dictate the behavior of events being routed to a destination. Each rule can be customized to achieve a range of desired event outcomes.',
      link: LINKS.product_docs.rules,
    },
  },
  {
    key: 'cli',
    label: 'Install the Hookdeck CLI',
    description: 'Follow the setup instructions below to receive events on your local server.',
    icon: 'rule' as const,
    Component: InstallCLI,
    reveal: (context) => !!context.webhook,
    conditional: (context) => !!context.destination?.cli_path,
    reference: {
      title: 'CLI',
      text: 'The Hookdeck CLI lets you forward events received on any Hookdeck connection to a local web server. Develop, test, and troubleshoot your integration with Hookdeck, all without interfering with your production setup.',
      link: LINKS.product_docs.cli,
    },
  },
  {
    key: 'events',
    nav_label: 'Review Events',
    label: 'View events',
    reveal: (context) => !!context.webhook,
    description: 'Inspect the response of the events that have been routed to your destination.',
    icon: 'events' as const,
    full_width: true,
    Component: PreviewEvents,
  },
];

let retrying = false;

const GuidedCreateConnection: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { team, mutateHasConnection } = useContext(DashboardContext);

  const [completed_step, setCompletedStep] = useLocalStorage(
    `${team!.id}:progress:guided_connection_step`,
    steps[0].key,
  );

  const { data: source, mutate: mutateSources } = useSWR(
    'guided_connection_create_source',
    () =>
      HookdeckAPI.sources
        .list({ limit: 1, disabled_at: null })
        .then((results) =>
          results.models.length > 0
            ? results.models[0].verification
              ? HookdeckAPI.sources.get(results.models[0].id, { include: 'verification.configs' })
              : results.models[0]
            : Promise.resolve(null),
        ),
    {
      refreshInterval: (result) => (!result ? 5000 : 0),
    },
  );

  const { data: destinations, mutate: mutateDestinations } = useSWR(
    source && APIMethodKeys.destinations.list({ limit: 1 }),
    () => HookdeckAPI.destinations.list({ limit: 1 }),
    {
      refreshInterval: (result) => (!result ? 5000 : 0),
    },
  );

  const destination = destinations?.models[0];

  const { data: webhooks, mutate: mutateWebhooks } = useSWR(
    APIMethodKeys.webhooks.list(
      source && destination
        ? { limit: 1, source_id: source.id, destination_id: destination.id }
        : undefined,
    ),
    () =>
      HookdeckAPI.webhooks.list({
        limit: 1,
        source_id: source!.id,
        destination_id: destination!.id,
      }),
    {
      refreshInterval: (result) => (!result ? 5000 : 0),
    },
  );

  const webhook = webhooks?.models[0];

  const requests_list = useRequestList(
    source ? { source_id: [source!.id] } : {},
    'ingested_at',
    'desc',
    {
      limit: 10,
      revalidation_interval: source && !destination ? 1000 : 5000,
      include_data: true,
      fetch: !!source,
    },
  );

  const events_list = useEventList(
    webhook ? { webhook_id: [webhook!.id], cli_id: { all: true } } : {},
    'created_at',
    'desc',
    {
      limit: 10,
      revalidation_interval: 2000,
      include_data: true,
      fetch: !!webhook,
    },
  );

  const context = {
    source: source || null,
    destination: destination || null,
    webhook: webhook || null,
    requests_list: requests_list || null,
    events_list: events_list || null,
  };

  const eligible_steps = steps.filter((step) => !step.conditional || step.conditional(context));

  let progressed_to_index = eligible_steps.findIndex((step) => {
    return !step.reveal ? false : step.reveal(context) === false;
  });

  if (progressed_to_index === -1) {
    progressed_to_index = eligible_steps.length - 1;
  } else {
    progressed_to_index -= 1;
  }

  const completed_to_index = eligible_steps.findIndex((step) => step.key === completed_step);

  const progress = Math.min(progressed_to_index, completed_to_index + 1);

  const current_nav_step_key = eligible_steps.reduce((key, step, i) => {
    if (i <= progress && step.nav_label) {
      return step.key;
    }
    return key;
  }, eligible_steps[0].key);

  useEffect(() => {
    if (progress > 0) {
      const element = document.getElementById(steps[progress].key);
      if (!element) return;
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [progress]);

  useEffect(() => {
    HookdeckAPI.track.event('Completed Guided Connection Step', { step: steps[progress].key });
  }, [completed_step]);

  useEffect(() => {
    if (webhook && requests_list && requests_list.count > 0 && current_nav_step_key === 'events') {
      const requests_to_retry = requests_list.requests.filter(
        (request) => request.rejection_cause === 'NO_CONNECTION',
      );

      if (requests_to_retry.length > 0 && retrying === false) {
        retrying = true;
        Promise.all(requests_to_retry.map((request) => HookdeckAPI.requests.retry(request.id)))
          .then(() => {
            requests_list.refresh();
            events_list.refresh();
            retrying = false;
          })
          .catch(() => {
            retrying = false;
          });
      }
    }
  }, [webhook, requests_list, current_nav_step_key]);

  return (
    <>
      <GuidedConnectionNav
        steps={
          eligible_steps.filter((step) => !!step.nav_label) as { key: string; nav_label: string }[]
        }
        current_step={current_nav_step_key}
      />
      <FullscreenLayout light>
        <Container w={{ px: 1024 }} m={{ y: 14 }} p={0}>
          <Div flex>
            <Div style={{ flexBasis: '692px' }}>
              <Alert
                m={{ y: 14 }}
                bare
                action={{
                  label: 'Skip to dashboard',
                  icon: 'link',
                  to: '/connections',
                }}
                inline>
                You're using our onboarding flow to make your first connection.
                <br />
                If you prefer, you can view our dashboard first.
              </Alert>
            </Div>
          </Div>
          {eligible_steps.map((step, index) => {
            if (progress + 1 < index) {
              return (
                <span
                  key={step.key}
                  id={step.key}
                  style={{ position: 'absolute', top: '-100px' }}
                />
              );
            }
            return (
              <Div
                key={step.key}
                flex
                style={
                  progress < index
                    ? { opacity: 0.2, pointerEvents: 'none', position: 'relative' }
                    : { position: 'relative' }
                }>
                <span id={step.key} style={{ position: 'absolute', top: '-100px' }} />
                <StyledProgress
                  is_last={eligible_steps.length - 1 === index}
                  required={progress < index ? false : true}>
                  <Icon icon={step.icon} />
                </StyledProgress>
                <Div p={{ l: 6, b: 20 }} flex={{ grow: true }} w={{ px: 998 }}>
                  <Grid>
                    <GridUnit size={step.full_width ? 1 : 2 / 3}>
                      <Div flex={{ justify: 'space-between', align: 'center' }}>
                        <Div>
                          <Text heading size="l" as="h3" m={{ b: 0 }} flex={{ align: 'center' }}>
                            {step.label}
                            {step.optional && (
                              <Badge m={{ l: 3 }} muted>
                                Optional
                              </Badge>
                            )}
                          </Text>
                          <Text as="p" muted m={{ b: 6 }}>
                            {step.description}
                          </Text>
                        </Div>
                      </Div>
                      {step.Component && (
                        <step.Component
                          context={context as any}
                          is_current_step={progress === index}
                          nextStep={() => {
                            setCompletedStep(step.key);
                          }}
                          mutateContext={(input) => {
                            if (input.source) {
                              mutateSources(input.source);
                            }
                            if (input.destination) {
                              mutateDestinations({
                                ...destinations!,
                                models: [input.destination],
                              });
                            }
                            if (input.webhook) {
                              mutateHasConnection();
                              mutateWebhooks({
                                ...webhooks!,
                                models: [input.webhook],
                              });
                            }
                            if (input.request) {
                              requests_list.refresh(input.request);
                            }
                            if (input.event) {
                              events_list.refresh(input.event);
                            }
                          }}
                        />
                      )}
                    </GridUnit>
                    {step.reference && (
                      <GridUnit size={1 / 3}>
                        <Div flex={{ justify: 'flex-end' }}>
                          <StyledCard muted p={4} m={{ t: 4, l: 14 }} w={{ px: 248 }}>
                            <Text subtitle m={{ b: 4 }} flex={{ align: 'center' }}>
                              <Icon icon="document" left />
                              {step.reference.title}
                            </Text>
                            <Text as="p" muted m={{ b: 4 }}>
                              {step.reference.text}
                            </Text>
                            <Link href={step.reference.link} icon="arrow_forward" target="_blank">
                              Docs
                            </Link>
                          </StyledCard>
                        </Div>
                      </GridUnit>
                    )}
                  </Grid>
                </Div>
              </Div>
            );
          })}
        </Container>
      </FullscreenLayout>
    </>
  );
};

export default GuidedCreateConnection;
