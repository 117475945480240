import { add, format } from 'date-fns';
import styled, { css } from 'styled-components';
import { Div } from '../../../../common/helpers/StyledUtils';
import { Resolution } from '../../../../../utils/chart';

const granularity_formating = {
  second: 'h:mm:ss a',
  minute: 'h:mm a',
  hour: 'MMM d, h:mm a',
  day: 'MMM d',
};

const StyledTimebarContainer = styled.div(
  () => css`
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    width: 100%;
  `,
);

const StyledTimebarMarker = styled.div(
  ({ theme }) => css`
    width: 1px;
    height: 6px;
    background-color: ${theme.colors.surface.container.neutral};
  `,
);

const StyledTimebarLabel = styled.div(
  ({ theme }) => css`
    display: inline-block;
    width: max-content;
    font-size: ${theme.pxToRem(12)};
    font-weight: 500;
    color: ${theme.colors.on.neutral.secondary_neutral};
    margin-top: ${theme.spacing(2.1)};
    transform: translateX(-50%);
    text-align: center;
  `,
);

const HistogramTimebar: React.FC<{
  start_date: Date;
  resolution: Resolution;
}> = ({ start_date, resolution }) => {
  return (
    <Div flex>
      <StyledTimebarContainer>
        {Array.from(Array(resolution.steps).keys()).map((i) => {
          const step_date = add(start_date, {
            seconds: i * resolution.unit * resolution.factor_to_seconds,
          });
          const label =
            i !== 0 &&
            i % 2 === 0 &&
            format(step_date, granularity_formating[resolution.granularity]);

          return (
            <div key={step_date.getTime()}>
              {label ? (
                <>
                  <StyledTimebarMarker />
                  <StyledTimebarLabel>{label}</StyledTimebarLabel>
                </>
              ) : (
                i !== 0 && <StyledTimebarMarker />
              )}
            </div>
          );
        })}
      </StyledTimebarContainer>
    </Div>
  );
};

export default HistogramTimebar;
