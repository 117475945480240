import { useContext, useState } from 'react';
import { ResourcesContext } from '../../ResourcesContext';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { useToasts } from '../../../../../common/Toast';
import { useDialog } from '../../../../../common/Dialog';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import { Webhook } from '../../../../../../../../../typings/Webhook.interface';

const useOnPauseToggle = () => {
  const { mutateResourceType } = useContext(ResourcesContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const { HookdeckAPI } = useContext(GlobalContext);
  const [pause_is_running, setPauseIsRunning] = useState(false);
  const [confirm_pause, setConfirmPause] = useLocalStorage('prompt_pause_webhook', true);
  const [confirm_unpause, setConfirmUnpause] = useLocalStorage('prompt_unpause_webhook', true);

  const onTogglePause = (webhook: Webhook, pause: boolean) => {
    if (pause_is_running) {
      return;
    }
    const apply = () => {
      const promise = pause
        ? HookdeckAPI.webhooks.pause(webhook.id)
        : HookdeckAPI.webhooks.unpause(webhook.id);
      setPauseIsRunning(true);
      return promise
        .then((webhook) => {
          addToast(
            'success',
            `${webhook.paused_at ? 'Paused' : 'Unpaused'} webhook ${webhook.full_name}`,
          );
          setPauseIsRunning(false);
          mutateResourceType!('webhooks', webhook);
        })
        .catch(() => {
          setPauseIsRunning(false);
          addToast(
            'error',
            `Failed to ${pause ? 'pause' : 'unpause'} webhook ${webhook.full_name}`,
          );
        });
    };
    if (pause && confirm_pause) {
      showDialog(
        () => {
          apply();
          setConfirmPause(false);
        },
        () => null,
        {
          message:
            'Incoming events will be held, and redelivered when the connection is unpaused. Are you sure you want to pause the connection?',
        },
      );
    } else if (!pause && confirm_unpause) {
      showDialog(
        () => {
          apply();
          setConfirmUnpause(false);
        },
        () => null,
        {
          message:
            'All held events associated with this connection will be sent, and any maximum delivery rate on the destination will be honored. Are you sure you want to unpause the connection?',
        },
      );
    } else {
      apply();
    }
  };

  return onTogglePause;
};

export default useOnPauseToggle;
