import { addSeconds, endOfDay, isSameDay, startOfDay, sub } from 'date-fns';
import { useContext, useMemo, useRef } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { APIList } from '../../../../../../../../typings/API.interface';
import { Destination } from '../../../../../../../../typings/Destination.interface';
import { Issue, IssueStatus, IssueType } from '../../../../../../../../typings/Issue.interface';
import { Transformation } from '../../../../../../../../typings/Transformation.interface';
import { Webhook } from '../../../../../../../../typings/Webhook.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { capitalizeFirstLetter } from '../../../../../utils';
import formatCubeQuery from '../../../../../utils/formatCubeQuery';
import { issue_actions, ISSUE_STATUS_CONFIGS } from '../../../../../utils/issues';
import Button from '../../../../common/base/Button';
import Icon from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Loading from '../../../../common/base/Loading';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { useCubeQueryLocalRawData } from '../../../../hooks/useCubeQueryLocalRawData';
import { DashboardContext } from '../../DashboardContext';
import IssueGroup from './IssuesGroup';

export interface IssuesRenderData {
  transformations_by_id: Record<string, Transformation> | undefined;
  webhooks_by_id: Record<string, Webhook> | undefined;
  destinations_by_id: Record<string, Destination> | undefined;
  timeseries_by_issue_id?: Record<string, [Date, number][]>;
}

const IssuesList: React.FC<{
  types?: IssueType[];
  sort_by?: 'last_seen_at' | 'first_seen_at';
  issue_trigger_id?: string;
  status?: IssueStatus;
  onAction: (status: keyof typeof issue_actions) => void;
}> = ({ types, sort_by, status, issue_trigger_id, onAction }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { mutate } = useSWRConfig();
  const { team } = useContext(DashboardContext);
  const { addToast } = useToasts();

  const issues_pagination = {
    order_by: sort_by,
    dir: 'desc',
    limit: 50,
    status,
    issue_trigger_id: issue_trigger_id || undefined,
  };

  const issue_filters = !types ? issues_pagination : { type: types, ...issues_pagination };

  const {
    data: issues_pages,
    mutate: mutateIssuesPages,
    size,
    setSize,
    isValidating,
  } = useSWRInfinite(
    (_, previous_data) =>
      APIMethodKeys.issues.list({ ...issue_filters, next: previous_data?.pagination.next }),
    (url) => {
      return HookdeckAPI.request<APIList<Issue>>('GET', url);
    },
    {
      onSuccess: () => mutate([APIMethodKeys.issues.count({ status: 'OPENED' }), team!.id]),
    },
  );

  const issues: Issue[] = issues_pages?.reduce((arr, page) => [...arr, ...page.models], []) ?? [];

  const prevUniqueWebhookIdsRef = useRef<string[]>([]);

  const unique_webhook_ids = useMemo(() => {
    if (issues) {
      const new_array = Array.from(
        new Set(
          issues.reduce(
            (arr, issue) =>
              issue.type === 'delivery' ? [...arr, ...issue.aggregation_keys.webhook_id] : arr,
            [],
          ),
        ),
      );

      // Check if the previous value contains all the value of the new array, if so, no need to refetch
      const old_set = new Set(prevUniqueWebhookIdsRef.current);
      const is_subset = new_array.every((value) => old_set.has(value));
      if (is_subset) {
        return prevUniqueWebhookIdsRef.current;
      }

      prevUniqueWebhookIdsRef.current = new_array;
      return new_array;
    } else {
      return [];
    }
  }, [issues]);

  const prevUniqueDestinationIdsRef = useRef<string[]>([]);

  const unique_destination_ids = useMemo(() => {
    if (issues) {
      const new_array = Array.from(
        new Set(
          issues.reduce(
            (arr, issue) =>
              issue.type === 'backpressure'
                ? [...arr, ...issue.aggregation_keys.destination_id]
                : arr,
            [],
          ),
        ),
      );

      // Check if the previous value contains all the value of the new array, if so, no need to refetch
      const old_set = new Set(prevUniqueDestinationIdsRef.current);
      const is_subset = new_array.every((value) => old_set.has(value));
      if (is_subset) {
        return prevUniqueDestinationIdsRef.current;
      }

      prevUniqueDestinationIdsRef.current = new_array;
      return new_array;
    } else {
      return [];
    }
  }, [issues]);

  const prevUniqueTransformationIdsRef = useRef<string[]>([]);

  const unique_transformation_ids = useMemo(() => {
    if (issues) {
      const new_array = Array.from(
        new Set(
          issues.reduce(
            (arr, issue) =>
              issue.type === 'transformation'
                ? [...arr, ...issue.aggregation_keys.transformation_id]
                : arr,
            [],
          ),
        ),
      );

      // Check if the previous value contains all the value of the new array, if so, no need to refetch
      const old_set = new Set(prevUniqueTransformationIdsRef.current);
      const is_subset = new_array.every((value) => old_set.has(value));
      if (is_subset) {
        return prevUniqueTransformationIdsRef.current;
      }

      prevUniqueTransformationIdsRef.current = new_array;
      return new_array;
    } else {
      return [];
    }
  }, [issues]);

  const prevUniqueIssuesForCubeRef = useRef<Issue[]>([]);

  const unique_issues_for_cube = useMemo(() => {
    if (issues) {
      const new_array = issues.map((issue) => issue.id);
      const old_array = new Set(prevUniqueIssuesForCubeRef.current.map((issue) => issue.id));
      const is_subset = new_array.every((value) => old_array.has(value));
      if (is_subset) {
        return prevUniqueIssuesForCubeRef.current;
      }

      prevUniqueIssuesForCubeRef.current = issues;
      return new_array;
    } else {
      return issues;
    }
  }, [issues]);

  const cube_query_delivery = useMemo(() => {
    return (
      unique_issues_for_cube &&
      unique_issues_for_cube.some((iss) => iss.type === 'delivery') &&
      formatCubeQuery('EventsWithIssues', {
        filters: {
          issue_id: unique_issues_for_cube.map((issue) => issue.id),
          date: {
            min: startOfDay(sub(new Date(), { days: 14 })).toISOString(),
            max: endOfDay(new Date()).toISOString(),
          },
        },
        granularity: 'hour',
        dimentions: ['issue'],
      })
    );
  }, [unique_issues_for_cube]);

  const { raw_data: raw_data_delivery } = useCubeQueryLocalRawData(cube_query_delivery || {}, {
    resetResultSetOnChange: false,
  });

  const cube_query_transformation = useMemo(
    () =>
      unique_issues_for_cube &&
      unique_issues_for_cube.some((iss) => iss.type === 'transformation') &&
      formatCubeQuery('EventRequestsTransformations', {
        filters: {
          issue_id: unique_issues_for_cube.map((issue) => issue.id),
          date: {
            min: startOfDay(sub(new Date(), { days: 7 })).toISOString(),
            max: addSeconds(endOfDay(new Date()), 1).toISOString(),
          },
        },
        granularity: 'hour',
        dimentions: ['issue'],
      }),
    [unique_issues_for_cube],
  );

  const { raw_data: raw_data_transformation } = useCubeQueryLocalRawData(
    cube_query_transformation || {},
    {
      resetResultSetOnChange: false,
    },
  );

  const timeseries_by_issue_id = useMemo(() => {
    const now = new Date();
    return (
      (!cube_query_delivery || raw_data_delivery) &&
      (!cube_query_transformation || raw_data_transformation) &&
      issues &&
      issues.reduce((obj, { id }) => {
        obj[id] = Array.from(Array(7).keys())
          .reduce((arr: any, i) => {
            const step_start = startOfDay(
              sub(now, {
                days: i,
              }),
            );

            const data = [
              ...(raw_data_delivery
                ? raw_data_delivery.map((entry: any) => ({
                    created_at: entry['EventsWithIssues.createdAt'],
                    count: entry['EventsWithIssues.count'],
                    issue_id: entry['EventsWithIssues.issueId'],
                  }))
                : []),
              ...(raw_data_transformation
                ? raw_data_transformation.map((entry: any) => ({
                    created_at: entry['EventRequestsTransformations.createdAt'],
                    count: entry['EventRequestsTransformations.count'],
                    issue_id: entry['EventRequestsTransformations.issueId'],
                  }))
                : []),
            ];

            const entries = data.filter(
              (entry) => isSameDay(new Date(entry.created_at), step_start) && entry.issue_id === id,
            );

            // Because of the timezone convertion happening on the client, the data is queried per hour instead of day and rolled up by day here
            const entry = entries.reduce(
              (rollup, data) => {
                rollup.count += parseInt(data.count);
                return rollup;
              },
              { created_at: step_start, count: 0, issue_id: id },
            );

            arr.push([step_start, entry ? entry.count : 0]);

            return arr;
          }, [])
          .reverse();
        return obj;
      }, {})
    );
  }, [raw_data_delivery, raw_data_transformation]);

  const { data: webhooks } = useSWR(
    unique_webhook_ids.length > 0 && APIMethodKeys.webhooks.list({ id: unique_webhook_ids }),
    () => HookdeckAPI.webhooks.list({ id: unique_webhook_ids }),
  );

  const { data: destinations } = useSWR(
    unique_destination_ids.length > 0 &&
      APIMethodKeys.destinations.list({ id: unique_destination_ids }),
    () => HookdeckAPI.destinations.list({ id: unique_destination_ids }),
  );

  const { data: transformations } = useSWR(
    unique_transformation_ids.length > 0 &&
      APIMethodKeys.transformations.list({ id: unique_transformation_ids }),
    () => HookdeckAPI.transformations.list({ id: unique_transformation_ids }),
  );

  const webhooks_by_id = webhooks?.models.reduce(
    (object, webhook) => ({ ...object, [webhook.id]: webhook }),
    {},
  );

  const destinations_by_id = destinations?.models.reduce(
    (object, destination) => ({ ...object, [destination.id]: destination }),
    {},
  );

  const transformations_by_id = transformations?.models.reduce(
    (object, transformation) => ({ ...object, [transformation.id]: transformation }),
    {},
  );

  const render_data: IssuesRenderData = {
    webhooks_by_id,
    transformations_by_id,
    timeseries_by_issue_id: timeseries_by_issue_id || undefined,
    destinations_by_id,
  };

  const applyAction = async (
    current_status: IssueStatus,
    issues_id: string[],
    action: keyof typeof issue_actions,
  ) => {
    Promise.all(
      issues_id.map((id) => {
        if (action === 'acknowledge') {
          return HookdeckAPI.issues.update(id, { status: 'ACKNOWLEDGED' });
        } else if (action === 'resolve') {
          return HookdeckAPI.issues.update(id, { status: 'RESOLVED' });
        } else if (action === 'reopen') {
          return HookdeckAPI.issues.update(id, { status: 'OPENED' });
        } else if (action === 'ignore') {
          return HookdeckAPI.issues.update(id, { status: 'IGNORED' });
        } else if (action === 'dismiss') {
          return HookdeckAPI.issues.dismiss(id);
        }
      }),
    )
      .then((updated_issues) => {
        onAction(action);
        addToast('success', 'Issues status updated');
        mutateIssuesPages(
          issues_pages?.map(
            (page) => ({
              ...page,
              models: page.models.map(
                (issue) =>
                  updated_issues.find(
                    (updated_issue) => updated_issue && updated_issue.id === issue.id,
                  ) || issue,
              ),
            }),
            false,
          ),
        );
      })
      .catch(() => addToast('error', 'Failed to update issues'));
  };

  const loading =
    !issues ||
    (unique_webhook_ids.length > 0 && !webhooks) ||
    (unique_destination_ids.length > 0 && !destinations) ||
    (unique_transformation_ids.length > 0 && !transformations);

  if (loading) {
    return (
      <Div flex={{ justify: 'center' }} p={8}>
        <Loading />
      </Div>
    );
  }

  const statuses = status ? [status] : Object.keys(ISSUE_STATUS_CONFIGS);
  const open_issues = issues_pages && issues_pages[0].count > 0;

  return (
    <Div m={{ b: 14 }} w={100} style={{ flexGrow: 1 }} h={open_issues ? undefined : 100}>
      {open_issues &&
        statuses.map((key: IssueStatus) => {
          const group_issues = issues.filter((issue) => issue.status === key);
          if (group_issues.length === 0) {
            return null;
          }
          return (
            <IssueGroup
              key={key}
              issues={group_issues}
              group_key={key}
              render_data={render_data}
              applyAction={applyAction}
            />
          );
        })}
      {issue_trigger_id && issues_pages?.[0].count === 0 && (
        <Div flex={{ align: 'center', justify: 'center', direction: 'column' }} h={100}>
          <Text as="h2" flex={{ align: 'center', justify: 'center' }} heading size="l">
            <Icon icon="success" left />
            No Issues Yet
          </Text>
          <Text as="p">
            You will be alerted when new issues appear here. Until then, rest easy.
          </Text>
        </Div>
      )}
      {!issue_trigger_id && issues_pages?.[0].count === 0 && status === 'OPENED' && (
        <Div flex={{ align: 'center', justify: 'center', direction: 'column' }} h={100}>
          <Text as="h2" flex={{ align: 'center', justify: 'center' }} heading size="l">
            <Icon icon="success" left />
            No {capitalizeFirstLetter(status.toLowerCase())} Issues
          </Text>
          <Text as="p">
            You will be alerted when new issues appear here. Until then, rest easy.
          </Text>
          <Div m={{ t: 2, b: 5 }}>
            <Link to={'/issue-triggers'} icon="arrow_forward">
              Configure your issue triggers
            </Link>
          </Div>
        </Div>
      )}
      {!issue_trigger_id && issues_pages?.[0].count === 0 && status && status !== 'OPENED' && (
        <Div flex={{ align: 'center', justify: 'center', direction: 'column' }} h={100}>
          <Text as="h2" flex={{ align: 'center', justify: 'center' }} heading size="l">
            <Icon icon="success" left />
            No {capitalizeFirstLetter(status.toLowerCase())} Issues
          </Text>
          <Text as="p">
            Issues that have their status updated to {status.toLowerCase()} will appear here.
          </Text>
        </Div>
      )}
      {issues_pages?.[issues_pages.length - 1].pagination.next && (
        <Div flex={{ justify: 'center' }} m={{ y: 8 }}>
          <Button icon="history" onClick={() => setSize(size + 1)} minimal primary>
            View more issues
          </Button>
        </Div>
      )}
      {isValidating && (
        <Div flex={{ justify: 'center' }} p={2} m={{ b: 8 }}>
          <Loading />
        </Div>
      )}
    </Div>
  );
};

export default IssuesList;
