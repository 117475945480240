import { BulkRetryModels } from '.';
import { EventListFiltersProps } from '../../../typings/EventList.interface';
import { RequestListFiltersProps } from '../../../typings/RequestList.interface';
import { capitalizeFirstLetter } from '../../../utils';
import { StyledCard } from '../base/Card';
import Icon, { IconName } from '../base/Icon';
import Text from '../base/Text';
import {
  event_list_filters,
  request_list_filters,
  search_filter_component,
} from '../Filters/FilterComponents';
import { Div } from '../helpers/StyledUtils';
import useBulkRetryRenderData, { BulkRetryRenderData } from './useBulkRetryRenderData';

const prettyPrintDate = (date) =>
  new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });

const model_filter_icons: Record<BulkRetryModels, Record<string, IconName>> = {
  events: event_list_filters.reduce((obj, filter) => {
    obj[filter.filter_key] = filter.icon;
    return obj;
  }, {}),
  requests: request_list_filters.reduce((obj, filter) => {
    obj[filter.filter_key] = filter.icon;
    return obj;
  }, {}),
  ignored_events: event_list_filters.reduce((obj, filter) => {
    obj[filter.filter_key] = filter.icon;
    return obj;
  }, {}),
};

type FilterBeakdownConfig = {
  label: string;
  icon: IconName;
  render: (
    values: any, //EventListFiltersProps[keyof EventListFiltersProps] technically correct type but too annoying to typecast everywhere
    render_data: BulkRetryRenderData,
  ) => string | JSX.Element;
};

const common: Record<string, FilterBeakdownConfig> = {
  search_term: {
    label: 'Search term',
    icon: search_filter_component.icon,
    render: (v) => v,
  },
  created_at: {
    label: 'Created date',
    icon: model_filter_icons['events'].date,
    render: ({ gte, lte }) => {
      if (gte === lte) {
        return prettyPrintDate(gte);
      }
      if (gte && lte) {
        return `between ${prettyPrintDate(gte)} and ${prettyPrintDate(lte)}`;
      }
      return `${lte ? 'lesser or equal to' : 'greater or equal to'} ${prettyPrintDate(gte || lte)}`;
    },
  },
  body: {
    label: 'Request Body',
    icon: model_filter_icons['events'].request,
    render: () => {
      return `filters matches the active body request filters`;
    },
  },
  headers: {
    label: 'Request Headers',
    icon: model_filter_icons['events'].request,
    render: () => {
      return `filters matches the active headers request filters`;
    },
  },
  path: {
    label: 'Request Path',
    icon: model_filter_icons['events'].request,
    render: () => {
      return `filters matches the active path request filters`;
    },
  },
  query: {
    label: 'Request Query',
    icon: model_filter_icons['events'].request,
    render: () => {
      return `filters matches the active query request filters`;
    },
  },
  source_id: {
    label: 'Source',
    icon: model_filter_icons['events'].source_id,
    render: (values, { sources }) =>
      values
        .map((v) => `${sources?.find((source) => source.id === v)?.name || 'Failed to fetch name'}`)
        .join(', '),
  },
  bulk_retry_id: {
    label: 'Bulk Retry',
    icon: model_filter_icons['events'].bulk_retry_id,
    render: (value) => (Array.isArray(value) ? value.join(', ') : value),
  },
};

const model_render_configs: Record<BulkRetryModels, Record<string, FilterBeakdownConfig>> = {
  events: {
    ...common,
    status: {
      label: 'Event Status',
      icon: model_filter_icons['events'].status,
      render: (key) =>
        Array.isArray(key)
          ? key
              .map((k) => (k === 'QUEUED' ? 'Pending' : capitalizeFirstLetter(k.toLowerCase())))
              .join(', ')
          : capitalizeFirstLetter(key.toLowerCase()),
    },
    response_status: {
      label: 'HTTP Status',
      icon: 'http',
      render: (value) => {
        if (Array.isArray(value)) {
          return value.join(', ');
        }
        if (value.lte === value.gte) {
          return value.lte;
        }
        if (value.lte && value.gte) {
          return `between ${value.gte} and ${value.lte}`;
        }
        return `${value.lte ? 'lesser or equal to' : 'greater or equal to'} ${
          value.lte || value.gte
        }`;
      },
    },
    destination_id: {
      label: 'Destination',
      icon: model_filter_icons['events'].destination_id,
      render: (values, { destinations }) =>
        values
          .map(
            (v) =>
              `${
                destinations?.find((destination) => destination.id === v)?.name ||
                'Failed to fetch name'
              }`,
          )
          .join(', '),
    },
    cli_user_id: {
      label: 'CLI User',
      icon: model_filter_icons['events'].cli_user_id,
      render: (values, { team_members }) =>
        values
          .map(
            (v) =>
              `${
                team_members?.find((member) => member.user_id === v)?.user_email ||
                'Failed to fetch name'
              }`,
          )
          .join(', '),
    },
    issue_id: {
      label: 'Issue',
      icon: model_filter_icons['events'].issue_id,
      render: (value) => (Array.isArray(value) ? value.join(', ') : value),
    },
    error_code: {
      label: 'Error Code',
      icon: 'error',
      render: (key) => (Array.isArray(key) ? key.join(', ') : key),
    },
    webhook_id: {
      label: 'Connection',
      icon: model_filter_icons['events'].webhook_id,
      render: (values, { webhooks }) =>
        values
          .map((v) => {
            const webhook = webhooks?.find((webhook) => webhook.id === v);
            return webhook ? webhook.full_name : 'Failed to fetch name';
          })
          .join(', '),
    },
  },
  requests: {
    status: {
      label: 'Request Status',
      icon: model_filter_icons['requests'].status,
      render: (key) => capitalizeFirstLetter(key.toLowerCase()),
    },
    rejection_cause: {
      label: 'Rejection Cause',
      icon: model_filter_icons['requests'].rejection_cause,
      render: (key) => (Array.isArray(key) ? key.join(', ') : key),
    },
    ...common,
  },
  ignored_events: {
    created_at: common.created_at,
    cause: {
      label: 'Cause',
      icon: model_filter_icons['requests'].status,
      render: (key) => (Array.isArray(key) ? key.join(', ') : key),
    },
    transformation_id: {
      label: 'Transformation',
      icon: model_filter_icons['events'].webhook_id,
      render: (key) => (Array.isArray(key) ? key.join(', ') : key),
    },
    webhook_id: {
      label: 'Connection',
      icon: model_filter_icons['events'].webhook_id,
      render: (values, { webhooks }) =>
        values
          .map((v) => {
            const webhook = webhooks?.find((webhook) => webhook.id === v);
            return webhook ? webhook.full_name : 'Failed to fetch name';
          })
          .join(', '),
    },
  },
};

const BulkRetryBreakdown: React.FC<{
  model: BulkRetryModels;
  query: EventListFiltersProps | RequestListFiltersProps;
}> = ({ query, model }) => {
  const { data, loading } = useBulkRetryRenderData(query);
  const render_configs = model_render_configs[model];

  if (loading) {
    return (
      <div>
        <Icon icon="loading" muted />
      </div>
    );
  }

  const keys = Object.keys(query).filter((key) => {
    if (!render_configs[key]) return false;
    if (
      (Array.isArray(query[key]) && query[key].length === 0) ||
      query[key] === null ||
      query[key] === undefined
    )
      return false;
    if (typeof query[key] === 'object') {
      return Object.values(query[key]).some((v) => !!v);
    }
    return !!query[key];
  });

  if (keys.length === 0) {
    return null;
  }

  return (
    <StyledCard p={3} flex={{ direction: 'column', gap: 3 }}>
      {keys.map((key) => (
        <Div flex key={key}>
          <Div w={30} flex={{ align: 'center' }}>
            <Icon muted icon={render_configs[key].icon} left />
            <Text muted text_wrap={false}>
              {render_configs[key].label}
            </Text>
          </Div>
          <Div w={60}>
            <Text as="p" m={0}>
              {render_configs[key].render(query[key], data)}
            </Text>
          </Div>
        </Div>
      ))}
    </StyledCard>
  );
};

export default BulkRetryBreakdown;
