import { Form, FormikProvider, useFormik } from 'formik';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { EventListFiltersProps } from '../../../typings/EventList.interface';
import { RequestListFiltersProps } from '../../../typings/RequestList.interface';
import useLocalStorage from '../../hooks/useLocalStorage';
import { DashboardContext } from '../../scenes/DashboardScene/DashboardContext';
import { StyledViewContainerCard } from '../../scenes/DashboardScene/StyledView';
import Badge from '../base/Badge';
import Button from '../base/Button';
import { StyledCardSection } from '../base/Card';
import Icon from '../base/Icon';
import LabelButton from '../base/LabelButton';
import Text from '../base/Text';
import { Div } from '../helpers/StyledUtils';
import { FilterComponent, FilterFormValues } from './FilterComponents';
import RecentFilters, { IRecentFilters } from './RecentFilters';

const StyledForm = styled.div`
  display: grid;
  grid-template-columns: fit-content(100%) auto;
  row-gap: 12px;
  margin-bottom: 20px;
  width: 100%;
  margin: 0;
  > ${Text} {
    max-height: 42px;
    min-height: 42px;
    display: flex;
    justify-self: end;
  }
`;

interface FiltersProps {
  route: string;
  filters: EventListFiltersProps | RequestListFiltersProps;
  unstructured_component?: FilterComponent;
  components: FilterComponent[];
  onFilterChanged: (filters: object) => void;
}

const Filters: React.FC<FiltersProps> = ({
  filters,
  components,
  route,
  onFilterChanged,
  unstructured_component,
}) => {
  if (unstructured_component) {
    components = [...components, unstructured_component];
  }
  const { team, view } = useContext(DashboardContext);
  const [active_filters, setActiveFilters] = useState<FilterComponent[]>([]);
  const [recent_filters, setRecentFilters] = useLocalStorage<IRecentFilters>(
    `recent_filters:${route}:${team!.id}`,
    {},
  );
  const [collapsed_filters, setCollpasedFilters] = useLocalStorage<boolean>(
    `collapsed_filters:${route}:${team!.id}`,
    false,
  );
  const [save_error, setSaveError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const new_active_filters: FilterComponent[] = [...active_filters];

    const active_filters_by_key = active_filters.reduce(
      (object, f) => ({ ...object, [f.filter_key]: f }),
      {},
    );

    const new_values = {};
    components.forEach((f) => {
      const formattedValue = f.formatForForm(filters[f.filter_key], filters);
      new_values[f.form_name] = formattedValue;
      const active = f.isActive(formattedValue);
      if (active && !active_filters_by_key[f.filter_key]) {
        new_active_filters.push(f);
        active_filters_by_key[f.filter_key] = f;
        return;
      } else if (!active) {
        delete active_filters_by_key[f.filter_key];
        const index = new_active_filters.findIndex((a) => a.filter_key === f.filter_key);
        if (index !== -1) {
          new_active_filters.splice(index, 1);
        }
      }
    });

    setActiveFilters(new_active_filters);
    setValues(new_values);
  }, [filters]);

  useEffect(() => {
    const new_active_filters = active_filters.filter(
      (f) => !!components.find((filter) => f.filter_key === filter.filter_key),
    );

    if (new_active_filters.length !== active_filters.length) {
      const new_values = { ...values };
      const removed_keys = Object.keys(new_values).filter(
        (key) => !new_active_filters.find((f) => f.filter_key === key),
      );
      removed_keys.forEach((key) => delete new_values[key]);
      setActiveFilters(new_active_filters);
      setValues(new_values);
    }
  }, [components]);

  const formik = useFormik<FilterFormValues>({
    initialValues: components.reduce((obj, f) => {
      obj[f.filter_key] = f.formatForForm(filters[f.filter_key], filters);
      return obj;
    }, {}) as FilterFormValues,
    validate: (values) => {
      return active_filters
        .filter((f) => !!f.validate)
        .reduce((errors, filter) => {
          const filter_errors = filter.validate?.(values[filter.filter_key]);
          if (filter_errors && Object.keys(filter_errors).length > 0) {
            errors[filter.filter_key] = filter_errors;
          }
          return errors;
        }, {});
    },
    onSubmit: (values) => {
      let should_update = false;

      components.forEach((f) => {
        const formated_filter = f.formatForForm(filters[f.filter_key], filters);
        if (!f.isActive(values[f.form_name]) && !f.isActive(formated_filter)) {
          return;
        }
        if (JSON.stringify(values[f.filter_key]) !== JSON.stringify(formated_filter)) {
          should_update = true;
        }
      });

      if (!should_update) {
        return;
      }

      const new_filters = components.reduce((query, { filter_key, form_name, formatForQuery }) => {
        return {
          ...query,
          [filter_key]: formatForQuery(values[form_name], view),
        };
      }, {});

      onFilterChanged(new_filters);

      handleAddRecentFilter();

      if (save_error) {
        setSaveError(undefined);
      }
    },
  });

  const { handleSubmit, setValues, values, setFieldTouched } = formik;

  const filters_by_key: { [key: string]: FilterComponent } = useMemo(() => {
    return components.reduce((object, f) => ({ ...object, [f.filter_key]: f }), {});
  }, [components]);

  const handleAddActiveFilter = (key: string) => {
    setActiveFilters((prev) => [...prev, filters_by_key[key]]);
  };

  const handleRemoveActiveFilter = (key: string) => {
    const new_active_filters = [...active_filters];
    new_active_filters.splice(
      active_filters.findIndex((f) => f.filter_key === key),
      1,
    );
    setActiveFilters(new_active_filters);
    const new_filters_by_key = { ...filters_by_key };
    new_active_filters.forEach((f) => delete new_filters_by_key[f.filter_key]);
    handleSubmit();
  };

  const handleRemoveAllActiveFilters = () => {
    onFilterChanged(
      components.reduce(
        (query, { filter_key }) => ({
          ...query,
          [filter_key]: undefined,
        }),
        {},
      ),
    );
    setActiveFilters([]);
  };

  const handleAddRecentFilter = () => {
    let should_add_filters = false;
    let new_recent_filters = {};
    components.forEach((f) => {
      if (f.isActive(values[f.form_name])) {
        if (f.filter_key === 'date' && values[f.form_name].relative) {
          delete values[f.form_name].min;
          delete values[f.form_name].max;
        }
        should_add_filters = true;
        new_recent_filters = {
          ...new_recent_filters,
          [f.filter_key]: f.formatForMemory
            ? f.formatForMemory(values[f.form_name])
            : values[f.form_name],
        };
      }
    });
    if (should_add_filters) {
      const current_recent_filters = Object.entries(recent_filters);
      const new_recent_filters_string = JSON.stringify(new_recent_filters);
      if (recent_filters[new_recent_filters_string] !== undefined) return;
      if (current_recent_filters.length > 19) {
        current_recent_filters.pop();
        setRecentFilters(
          current_recent_filters.reduce(
            (object, [key, v]) => {
              return { ...object, [key]: v };
            },
            { [new_recent_filters_string]: new_recent_filters },
          ),
        );
        return;
      }
      setRecentFilters((prev) => {
        return {
          [new_recent_filters_string]: new_recent_filters,
          ...prev,
        };
      });
    }
  };

  const handleSelectRecentFilter = (recent_filters: IRecentFilters) => {
    let new_filters = {};
    let new_values = {};
    const new_active_filters: FilterComponent[] = [];
    const new_filter_buttons: FilterComponent[] = [];
    components.forEach((f) => {
      if (!f.isActive(recent_filters[f.filter_key])) {
        new_filter_buttons.push(f);
        return;
      }
      new_filters = {
        ...new_filters,
        [f.filter_key]: f.formatForQuery(recent_filters[f.filter_key]),
      };
      new_values = {
        ...new_values,
        [f.form_name]: recent_filters[f.filter_key],
      };
      new_active_filters.push(f);
    });
    onFilterChanged(new_filters);
    setValues(new_values);
    setActiveFilters(new_active_filters);
  };

  const getDeleteButton = (filter: FilterComponent) => {
    return (
      <Button
        minimal
        icon="delete"
        onClick={() => {
          handleRemoveActiveFilter(filter.filter_key);
          setValues((prev) => ({
            ...prev,
            [filter.form_name]: filter.formatForForm(null, {}),
          }));
          setFieldTouched(filter.form_name);
        }}
        margin={{ l: 2 }}
      />
    );
  };

  const filter_buttons = components
    .filter((fitler) => !active_filters.find((f) => f.filter_key === fitler.filter_key))
    .filter((f) => !f.hidden);

  const active_filter_without_unstructured = active_filters.filter((a) =>
    unstructured_component ? a.filter_key !== unstructured_component?.filter_key : true,
  );

  const applied_filter_count = active_filters.reduce(
    (count, f) => (f.isActive(filters[f.filter_key]) ? count + 1 : count),
    0,
  );

  return (
    <FormikProvider value={formik}>
      <Form>
        <StyledViewContainerCard>
          <StyledCardSection
            flex={{ align: 'center', justify: 'space-between' }}
            p={{ x: 4, y: 2 }}>
            <Text subtitle flex={{ align: 'center' }}>
              Filters
              {applied_filter_count > 0 && (
                <Badge m={{ l: 2 }} muted>
                  {applied_filter_count}
                </Badge>
              )}
            </Text>
            <Div flex={{ align: 'center', gap: 2 }}>
              {active_filters.length > 0 && (
                <Button
                  minimal
                  danger
                  small
                  icon="delete"
                  onClick={() => {
                    setSaveError(undefined);
                    handleRemoveAllActiveFilters();
                  }}
                  disabled={!(active_filters.length > 0)}>
                  Clear All Filters
                </Button>
              )}
              <RecentFilters
                recent_filters={recent_filters}
                filters_by_key={filters_by_key}
                onSelect={handleSelectRecentFilter}
              />
              <Button
                minimal
                small
                dropdown
                icon={collapsed_filters ? 'expand_more' : 'expand_less'}
                onClick={() => setCollpasedFilters((prev) => !prev)}
              />
            </Div>
          </StyledCardSection>
          {!collapsed_filters && (
            <>
              <StyledCardSection p={4}>
                {filter_buttons.length > 0 ? (
                  <Div flex={{ direction: 'row', wrap: true, gap: 2 }}>
                    {filter_buttons.map((f) => (
                      <LabelButton
                        key={f.filter_key}
                        label={f.label}
                        label_icon={f.icon}
                        icon="add"
                        onClick={() => {
                          handleAddActiveFilter(f.filter_key);
                        }}
                      />
                    ))}
                  </Div>
                ) : (
                  <Div flex={{ align: 'center' }}>
                    <Icon left icon="check" muted />
                    <Text muted>All filters added</Text>
                  </Div>
                )}
              </StyledCardSection>
              {active_filter_without_unstructured.length > 0 && (
                <StyledCardSection p={4}>
                  <StyledForm>
                    {active_filters
                      .filter((a) =>
                        unstructured_component
                          ? a.filter_key !== unstructured_component?.filter_key
                          : true,
                      )
                      .map((f, i) => (
                        <Fragment key={`filters-${i}`}>
                          <Text
                            margin={{ right: 5 }}
                            subtitle
                            style={{ alignItems: f.center_label ? 'center' : undefined }}>
                            {f.label}
                          </Text>
                          <Div flex={{ align: 'center' }}>
                            <f.Component
                              key={f.filter_key}
                              label={f.label}
                              name={f.form_name}
                              filters={filters}
                              {...(f.component_props as any)}
                              delete_button={getDeleteButton(f)}
                              onSubmit={handleSubmit}
                            />
                          </Div>
                        </Fragment>
                      ))}
                  </StyledForm>
                </StyledCardSection>
              )}
            </>
          )}
        </StyledViewContainerCard>
        {/*Without this button, clicking enter to submit will not work*/}
        <button hidden type="submit" />
      </Form>
    </FormikProvider>
  );
};

export default Filters;
