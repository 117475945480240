import { Form, Formik } from 'formik';
import { useContext } from 'react';

import { Destination } from '../../../../../../../../typings/Destination.interface';
import field_formats from '../../../../../utils/field-formatters';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import Button, { SelectButton } from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon, { IconName } from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import DropdownMenu from '../../../../common/DropdownMenu';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import TextAreaInput from '../../../../common/Form/Fields/TextAreaInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { DashboardContext } from '../../DashboardContext';
import {
  destination_form_types,
  destination_partial_form_props,
} from '../Forms/destination_configuration';
import resource_details_form_props from '../Forms/resource_details';
import { ResourcesContext } from '../ResourcesContext';
import useOnDisableToggle from './hooks/useOnDisableToggle';
import useOnDelete from './hooks/useOnDeleteToggle';
import Link from '../../../../common/base/Link';

interface Props {
  destination: Destination;
}

const DestinationPreview = ({ destination }: Props) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { mutateResourceType } = useContext(ResourcesContext);
  const { addToast } = useToasts();

  const { team } = useContext(DashboardContext);
  const onDisable = useOnDisableToggle('destinations');
  const onDelete = useOnDelete('destinations');

  return (
    <Formik
      onSubmit={(values, { resetForm }) => {
        return HookdeckAPI.destinations
          .update(destination.id, {
            name: values.name,
            ...destination_partial_form_props.postprocessValues(values),
          })
          .then((updated_destination: Destination) => {
            mutateResourceType!('destinations', updated_destination);
            resetForm({
              values: {
                name: updated_destination.name,
                ...destination_partial_form_props.getInitialValues(updated_destination),
              },
              touched: {},
            });
            addToast('success', 'Destination updated');
          })
          .catch((e) => {
            addToast(
              'error',
              `An error occurred while saving the destination${
                e.response?.data[0] ? `: ${e.response.data[0]}` : ''
              }`,
            );
          });
      }}
      validate={async (values) => {
        const errors = {
          ...(await resource_details_form_props.validate(values, (name) =>
            name === destination.name
              ? Promise.resolve(false)
              : HookdeckAPI.destinations.nameIsUsed(name),
          )),
          ...(await destination_partial_form_props.validate(values)),
        };
        return cleanseFormErrorObject(errors);
      }}
      initialValues={{
        name: destination.name,
        ...destination_partial_form_props.getInitialValues(destination),
      }}>
      {({ dirty, resetForm, isSubmitting, isValid, values, setFieldValue }) => {
        const show_search_tooltip =
          (values.type === 'cli' && values.cli_path?.includes('?')) ||
          (values.type === 'http' && values.url?.includes('?'));

        const { rate_limit, rate_limit_period } = values;
        let show_rate_limit_warning = false;
        let rate_limit_per_second = Number(rate_limit);
        if (rate_limit_period === 'minute') {
          rate_limit_per_second = Number(rate_limit) / 60;
        } else if (rate_limit_period === 'hour') {
          rate_limit_per_second = Number(rate_limit) / 3600;
        }
        if (rate_limit_per_second > team!.max_events_per_second) {
          show_rate_limit_warning = true;
        }

        return (
          <Form>
            <StyledCardSection muted p={{ x: 4, y: 1 }}>
              <Text subtitle size="xs">
                {destination.disabled_at ? 'Disabled' : 'Enabled'} Destination
              </Text>
            </StyledCardSection>
            <StyledCardSection p={4}>
              <TextAreaInput
                monospace
                name="name"
                label="Destination Name"
                required
                auto_resize={true}
                format={field_formats.slugify}
                maxlength={155}
              />
              <Text subtitle size="s" m={{ b: 1, t: 4 }}>
                Destination Type
              </Text>
              <Div m={{ b: 2 }}>
                <DropdownMenu.Permission
                  options={Object.entries(destination_form_types).map(([key, { icon, label }]) => ({
                    label,
                    icon,
                    onClick: () => setFieldValue('type', key),
                  }))}
                  parent_width
                  renderToggle={(open, toggle) => (
                    <SelectButton
                      onClick={() => toggle(!open)}
                      block
                      icon={destination_form_types[values.type].icon as IconName}>
                      {destination_form_types[values.type].label}
                    </SelectButton>
                  )}
                />
              </Div>
              {['http', 'mock'].includes(values.type) && (
                <>
                  {values.type === 'mock' ? (
                    <TextInput disabled required monospace name="mock_url" m={{ b: 2 }} />
                  ) : (
                    <TextAreaInput
                      name="url"
                      monospace
                      auto_resize={true}
                      placeholder={`https://example.com${values.cli_path || ''}`}
                      m={{ b: 2 }}
                    />
                  )}
                  <StyledCard p={{ x: 3, y: 2 }}>
                    <Div flex={{ justify: 'space-between', align: 'center' }}>
                      <Text>Max delivery rate</Text>
                      <SwitchInput show_label name={'enable_rate_limit'} />
                    </Div>
                    {values.enable_rate_limit && (
                      <Div m={{ t: 2, b: 1 }} flex={{ gap: 2 }}>
                        <TextInput
                          w={50}
                          focus
                          name="rate_limit"
                          placeholder="50"
                          type="number"
                          default_value={
                            team?.max_events_per_second
                              ? team?.max_events_per_second.toString()
                              : '5'
                          }
                          min={1}
                          m={0}
                        />
                        <SelectInput
                          w={50}
                          m={0}
                          block
                          name={'rate_limit_period'}
                          options={[
                            {
                              value: 'second',
                              label: 'per second',
                            },
                            {
                              value: 'minute',
                              label: 'per minute',
                            },
                            {
                              value: 'hour',
                              label: 'per hour',
                            },
                            {
                              value: 'concurrent',
                              label: 'concurrent',
                            },
                          ]}
                          required
                        />
                      </Div>
                    )}
                    {show_rate_limit_warning && (
                      <Text muted size="s" m={{ t: 1 }}>
                        This value is higher than your project throughput of{' '}
                        {team?.max_events_per_second} events per second.{' '}
                        <Link icon="arrow_forward" to="/settings/project/quotas?view=throughput">
                          Increase it
                        </Link>
                      </Text>
                    )}
                  </StyledCard>
                </>
              )}
              {values.type === 'cli' && (
                <TextInput
                  m={0}
                  name={'cli_path'}
                  placeholder="/webhooks"
                  help="Path to forward the request to on your localhost."
                  required
                />
              )}
              {show_search_tooltip && (
                <Text flex muted size="s" m={{ t: 2 }}>
                  <Icon icon="info" left={2} p={{ t: 0.25 }} />
                  <span>By setting query params, event query params will be ignored.</span>
                </Text>
              )}
            </StyledCardSection>
            <StyledCardSection p={{ x: 4, y: 3 }} flex={{ justify: 'space-between' }}>
              {dirty ? (
                <>
                  <Button onClick={() => resetForm()} outline>
                    Cancel
                  </Button>
                  <Button submit primary icon="save" disabled={isSubmitting || !isValid}>
                    {isSubmitting ? <Icon icon="loading" /> : 'Save'}
                  </Button>
                </>
              ) : (
                <>
                  <Button to={`/destinations/${destination.id}`} outline icon="link">
                    Open Destination
                  </Button>
                  <DropdownMenu.Permission
                    outline
                    icon="horizontal_more"
                    placement="bottom-end"
                    options={[
                      destination.disabled_at
                        ? {
                            label: 'Enable Destination',
                            icon: 'enable',
                            onClick: () => onDisable(destination, false),
                          }
                        : {
                            label: 'Disable Destination',
                            icon: 'disable',
                            onClick: () => onDisable(destination, true),
                          },
                      {
                        label: 'Delete Destination',
                        icon: 'delete',
                        danger: true,
                        onClick: () => onDelete(destination),
                      },
                    ]}
                  />
                </>
              )}
            </StyledCardSection>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DestinationPreview;
