import { formatDistanceToNow } from 'date-fns';

import { Issue, IssueStatus } from '../../../../../../../../typings/Issue.interface';

import {
  getIssueDescrition,
  getIssueTitleParts,
  issue_actions,
  ISSUE_STATUS_CONFIGS,
} from '../../../../../utils/issues';
import Button, { ButtonGroup } from '../../../../common/base/Button';
import { StyledCard } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import { Div } from '../../../../common/helpers/StyledUtils';
import Tooltip from '../../../../common/base/Tooltip';
import Text from '../../../../common/base/Text';
import IssueChart from './Chart';
import { IssuesRenderData } from '.';
import IssueTitle from '../IssueTitle';

const IssueSection: React.FC<{
  issue: Issue;
  render_data: IssuesRenderData;
  applyAction: (status: IssueStatus, ids: string[], action: keyof typeof issue_actions) => void;
}> = ({ issue, render_data, applyAction }) => {
  const description = getIssueDescrition(issue, render_data);
  const title_parts = getIssueTitleParts(issue, render_data);
  const { default_action, current_action } = ISSUE_STATUS_CONFIGS[issue.status];

  return (
    <StyledCard p={{ l: 4, b: 4, r: 2.5, t: 2.5 }} m={{ b: 4 }}>
      <Div flex w={100}>
        <Div flex={{ direction: 'column', grow: true }} w={100}>
          <Div flex={{ justify: 'space-between', align: 'center' }} m={{ b: 1.5 }} w={100}>
            <IssueTitle issue={issue} parts={title_parts} />
            <Div>
              <Div flex={{ gap: 2 }} m={{ l: 4 }}>
                {issue.type !== 'backpressure' && (
                  <IssueChart
                    values={
                      (render_data.timeseries_by_issue_id &&
                        render_data.timeseries_by_issue_id[issue.id]) ||
                      undefined
                    }
                    resolution="day"
                  />
                )}
                <ButtonGroup
                  m={{ l: 4 }}
                  more_options={Object.keys(issue_actions)
                    .filter((key) => {
                      if (key === current_action || key === default_action) {
                        return false;
                      }
                      if (key === 'dismiss' && issue.dismissed_at) {
                        return null;
                      }
                      return issue.status === 'OPENED' ? key !== 'reopen' : true;
                    })
                    .map((key: keyof typeof issue_actions) => ({
                      label: issue_actions[key].label,
                      role: issue_actions[key].role,
                      icon: issue_actions[key].icon,
                      onClick: () => applyAction(issue.status, [issue.id], key),
                      danger: issue_actions[key].danger,
                    }))}>
                  <Button.Permission
                    role={issue_actions[default_action].role}
                    outline
                    onClick={() => applyAction(issue.status, [issue.id], default_action)}>
                    <Icon icon={issue_actions[default_action].icon} muted left />
                    {issue_actions[default_action].label}
                  </Button.Permission>
                </ButtonGroup>
                <Button to={`/issues/${issue.id}`} outline icon="open" />
              </Div>
            </Div>
          </Div>
          <Text as="p" muted m={{ b: 3 }} w={60}>
            {description}
          </Text>
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <Div flex={{ gap: 3 }}>
              <Tooltip tooltip={`First seen on ${issue.first_seen_at}`} placement="bottom-start">
                <Div flex={{ align: 'center' }}>
                  <Icon muted icon="history" small left={1} />
                  <Text subtitle muted>
                    {formatDistanceToNow(new Date(issue.first_seen_at))} old
                  </Text>
                </Div>
              </Tooltip>
              <Tooltip tooltip={`Last seen on ${issue.first_seen_at}`} placement="bottom-start">
                <Div flex={{ align: 'center' }}>
                  <Icon muted icon="visibility" small left={1} />
                  <Text subtitle muted>
                    last seen{' '}
                    {formatDistanceToNow(new Date(issue.last_seen_at), {
                      addSuffix: true,
                    })}
                  </Text>
                </Div>
              </Tooltip>
            </Div>
          </Div>
        </Div>
      </Div>
    </StyledCard>
  );
};

export default IssueSection;
