import { useFormikContext } from 'formik';
import isEmpty from '../../../utils/isEmpty';
import { Div } from '../helpers/StyledUtils';
import InputError from './Fields/Input/InputError';
import { ReactNode } from 'react';

// Shows form errors, or errors for any field anywhere in the form.
// It can be rendered anywhere in the form, but it's usually rendered at the bottom of the form.
const FormError = ({
  error_limit = 1,
  field,
  children,
}: {
  // The maximum number of errors to display. Defaults to 1.
  error_limit?: number;
  // The field(s) to display errors for. If not provided, all errors will be displayed.
  field?: string | string[];
  children: (field: string) => ReactNode;
}) => {
  const { errors } = useFormikContext<string>();

  if (process.env.NODE_ENV !== 'production' && error_limit <= 0) {
    throw new Error(
      'You set error_limit to 0 or less. This is probably not what you want, since it will never show any errors.',
    );
  }

  const fields = typeof field === 'string' ? [field] : field;

  if (isEmpty(errors)) {
    return null;
  }

  return (
    <Div m={{ t: 2 }}>
      {Object.entries(errors)
        .filter(
          ([field, error]) =>
            (!Array.isArray(field) || (fields && fields.includes(field))) &&
            typeof error === 'string',
        )
        .slice(0, error_limit)
        .map(([field, error]) => (
          <>
            <InputError>{error}</InputError>
            {children?.(field)}
          </>
        ))}
    </Div>
  );
};

export default FormError;
