import { useContext } from 'react';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import LabelButton from '../../../common/base/LabelButton';
import Text from '../../../common/base/Text';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { Status } from '../../../common/Status';
import Skeleton from '../../../common/base/Skeleton';

type Props = {
  entry_id: string;
};

const EventSidebarSection: React.FC<Props> = ({ entry_id }) => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const { data: event } = useSWR(APIMethodKeys.events.get(entry_id), () =>
    HookdeckAPI.events.get(entry_id),
  );

  const { data: webhook, error: webhook_error } = useSWR(
    event && APIMethodKeys.webhooks.get(event.webhook_id),
    () => HookdeckAPI.webhooks.get(event!.webhook_id),
  );

  if (!event || (!webhook && !webhook_error)) {
    return (
      <>
        <StyledCard m={{ b: 4 }}>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Skeleton h={{ px: 20 }} w={{ px: 40 }} loading m={{ b: 1 }} />
            <Skeleton h={{ px: 20 }} w={{ px: 58 }} loading />
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Skeleton h={{ px: 20 }} w={{ px: 40 }} loading m={{ b: 1 }} />
            <Skeleton h={{ px: 20 }} w={{ px: 128 }} loading />
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <Skeleton h={{ px: 20 }} w={{ px: 40 }} loading m={{ b: 1 }} />
            <Skeleton h={{ px: 20 }} w={{ px: 128 }} loading />
          </StyledCardSection>
        </StyledCard>
      </>
    );
  }

  return (
    <StyledCard m={{ b: 4 }}>
      <StyledCardSection p={{ x: 4, y: 3 }}>
        <Text size="s" subtitle m={{ b: 1 }}>
          Status
        </Text>
        <Status show_response={false} {...event} />
      </StyledCardSection>
      <StyledCardSection p={{ x: 4, y: 3 }}>
        <Text size="s" subtitle m={{ b: 1 }}>
          Event
        </Text>
        <LabelButton
          neutral
          monospace
          to={`${event.cli_id ? '/cli' : ''}/events/${event.id}`}
          label={entry_id}
        />
      </StyledCardSection>
      <StyledCardSection p={{ x: 4, y: 3 }}>
        <Text size="s" subtitle m={{ b: 1 }}>
          Connection
        </Text>
        {webhook_error?.response?.status === 410 && <Text muted>Connection Deleted</Text>}
        {!webhook_error && webhook && (
          <LabelButton
            monospace
            neutral
            to={webhook && `/connections/${webhook.id}`}
            label={webhook?.full_name || ''}
          />
        )}
      </StyledCardSection>
    </StyledCard>
  );
};

export default EventSidebarSection;
