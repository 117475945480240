import { Form, Formik } from 'formik';

import { IssueType } from '../../../../../../../typings/Issue.interface';
import { fieldName } from '../../../../utils';
import { issue_type_configs } from '../../../../utils/issues';
import Button, { ButtonGroup } from '../../../common/base/Button';
import Divider from '../../../common/base/Divider';
import Text from '../../../common/base/Text';
import Dropdown from '../../../common/Dropdown';
import CheckboxInput from '../../../common/Form/Fields/CheckboxInput';
import { Div } from '../../../common/helpers/StyledUtils';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import TriggersList from './IssueTriggersList';

const IssueTriggersListView: React.FC = () => {
  const { query, updateSearchQuery } = useSearchQuery<{
    type: IssueType[];
    status: ('enabled' | 'disabled')[];
  }>();

  const active_filter_count = (query.type?.length || 0) + (query.status?.length || 0);
  return (
    <StyledViewWrapper>
      <StyledViewContent>
        <PageNav
          breadcrumb={[
            { icon: 'issues', title: 'Issues', path: '/issues' },
            { icon: 'issues', title: 'Issue Triggers' },
          ]}>
          <ButtonGroup>
            <Dropdown
              parent_width={{ min: 272 }}
              placement="bottom-end"
              icon="filter"
              label="Filter"
              badge={active_filter_count ? { label: String(active_filter_count) } : undefined}
              outline>
              {(toggle) => (
                <Formik
                  initialValues={{
                    type: query.type
                      ? query.type.reduce((obj, key) => ({ ...obj, [key]: true }), {})
                      : {},
                    status: query.status
                      ? query.status.reduce((obj, key) => ({ ...obj, [key]: true }), {})
                      : {},
                  }}
                  onSubmit={(values) => {
                    const type = Object.entries(values.type)
                      .filter(([, value]) => value)
                      .map(([key]) => key);
                    const status = Object.entries(values.status)
                      .filter(([, value]) => value)
                      .map(([key]) => key);
                    updateSearchQuery({
                      type: type.length > 0 ? type : undefined,
                      status: status.length > 0 ? status : undefined,
                    });
                    toggle(false);
                  }}>
                  <Form>
                    <Div p={{ x: 3, y: 3, b: 0.5 }}>
                      <Text heading size="xs" muted>
                        Issue Trigger Type
                      </Text>
                      {Object.entries(issue_type_configs).map(([key, config]) => (
                        <CheckboxInput
                          m={{ y: 2.5 }}
                          key={key}
                          label={config.label}
                          icon={config.icon}
                          name={fieldName(key, 'type')}
                        />
                      ))}
                    </Div>
                    <Divider />
                    <Div p={{ x: 3, y: 3, b: 0.5 }}>
                      <Text heading size="xs" muted>
                        Issue Trigger Status
                      </Text>
                      <CheckboxInput
                        m={{ y: 2.5 }}
                        icon="notification"
                        label="Enabled"
                        name="status.enabled"
                      />
                      <CheckboxInput
                        m={{ y: 2.5 }}
                        icon="notification_off"
                        label="Disabled"
                        name="status.disabled"
                      />
                    </Div>
                    <Divider />
                    <Div p={3} flex={{ justify: 'space-between', gap: 2 }}>
                      <Button submit>Apply</Button>
                    </Div>
                  </Form>
                </Formik>
              )}
            </Dropdown>
            {((query.type && query.type.length > 0) ||
              (query.status && query.status.length > 0)) && (
              <Button
                outline
                onClick={() => {
                  updateSearchQuery({
                    type: undefined,
                    status: undefined,
                  });
                }}
                icon="cancel">
                Clear Filters
              </Button>
            )}
          </ButtonGroup>
        </PageNav>
        <TriggersList types={query.type} status={query.status} />
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default IssueTriggersListView;
