import { useContext } from 'react';
import useSWR from 'swr';
import { Destination } from '../../../../../../typings/Destination.interface';
import { Source } from '../../../../../../typings/Source.interface';
import { TeamMember } from '../../../../../../typings/TeamMember.interface';
import { Webhook } from '../../../../../../typings/Webhook.interface';
import APIMethodKeys from '../../../client/APIMethodKeys';
import { EventListFiltersProps } from '../../../typings/EventList.interface';
import { RequestListFiltersProps } from '../../../typings/RequestList.interface';
import { GlobalContext } from '../../contexts/GlobalContext';
import { DashboardContext } from '../../scenes/DashboardScene/DashboardContext';

export interface BulkRetryRenderData {
  webhooks?: Webhook[];
  sources?: Source[];
  destinations?: Destination[];
  team_members?: TeamMember[];
}

const useBulkRetryRenderData = (
  query: EventListFiltersProps | RequestListFiltersProps,
): {
  loading: boolean;
  data: BulkRetryRenderData;
} => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { team } = useContext(DashboardContext);

  const { data: webhooks } = useSWR(
    'webhook_id' in query &&
      query.webhook_id &&
      query.webhook_id?.length > 0 &&
      APIMethodKeys.webhooks.list({ id: query.webhook_id }),
    () => HookdeckAPI.webhooks.list({ id: (query as EventListFiltersProps).webhook_id }),
  );
  const { data: sources } = useSWR(
    query.source_id &&
      query.source_id.length > 0 &&
      APIMethodKeys.sources.list({ id: query.source_id }),
    () => HookdeckAPI.sources.list({ id: query.source_id }),
  );
  const { data: destinations } = useSWR(
    'destination_id' in query &&
      query.destination_id &&
      query.destination_id.length > 0 &&
      APIMethodKeys.destinations.list({ id: query.destination_id }),
    () => HookdeckAPI.destinations.list({ id: (query as EventListFiltersProps).destination_id }),
  );

  const { data: team_members } = useSWR(
    'cli_user_id' in query &&
      query.cli_user_id &&
      query.cli_user_id.length > 0 &&
      APIMethodKeys.teams.listMembers(team!.id),
    () => HookdeckAPI.teams.listMembers(),
  );

  const loading =
    (query.source_id && query.source_id.length > 0 && !sources) ||
    ('destination_id' in query &&
      query.destination_id &&
      query.destination_id.length > 0 &&
      !destinations) ||
    ('cli_user_id' in query &&
      query.cli_user_id &&
      query.cli_user_id.length > 0 &&
      !team_members) ||
    ('webhook_id' in query && query.webhook_id && query.webhook_id?.length > 0 && !webhooks) ||
    false;

  return {
    loading,
    data: {
      webhooks: webhooks?.models,
      sources: sources?.models,
      destinations: destinations?.models,
      team_members: team_members,
    },
  };
};

export default useBulkRetryRenderData;
