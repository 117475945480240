import { format } from 'date-fns';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../client/APIMethodKeys';
import isEmpty from '../../../utils/isEmpty';
import { GlobalContext } from '../../contexts/GlobalContext';
import { DashboardContext } from '../../scenes/DashboardScene/DashboardContext';
import LegendColor from '../Chart/LegendColor';
import Loader from '../Loader';
import MouseTooltip from '../MouseTooltip';
import WithLoader from '../WithLoader';
import Button from '../base/Button';
import { StyledCard, StyledCardSection } from '../base/Card';
import Link from '../base/Link';
import Skeleton from '../base/Skeleton';
import Text from '../base/Text';
import ScrollArea from '../helpers/ScrollArea';
import { Div, StyledUtilsProps } from '../helpers/StyledUtils';
import MetricError from './MetricError';
import MetricNoData from './MetricNoData';
import { MetricName, default_data_formatter, metric_definitions } from './metric-definitions';
import { MetricConfigs, useMetric } from './useMetric';

export interface TableData {
  resource_id: string;
  value: number;
}

interface Props extends StyledUtilsProps {
  metric: MetricName;
  refresh_key?: number;
  configs: MetricConfigs;
  footer_text?: string;
  footer_link?: {
    label: string;
    to: string;
  };
}

interface RowProps {
  resource_id: string;
  hovered_resource_id: string | null;
  label: string;
  value: string;
  width: string;

  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const StyledRow = styled(Div)<{ muted: boolean }>(
  ({ theme, muted }) => css`
    position: relative;
    box-sizing: border-box;
    border-radius: ${theme.radius.small};
    overflow: hidden;
    opacity: ${muted ? 0.5 : 1};
  `,
);

const StyledRowBackground = styled.div<{ width: string; muted: boolean; hovered: boolean }>(
  ({ theme, width, muted, hovered }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-width: 4px;
    width: ${width};
    background-color: ${hovered
      ? theme.colors.surface.container.hover.neutral
      : theme.colors.surface.container.neutral};
    border-radius: ${theme.radius.small};
    opacity: ${muted ? 0.5 : 1};
  `,
);

const Row = ({
  resource_id,
  hovered_resource_id,
  label,
  value,
  width,
  ...other_props
}: RowProps) => {
  const hovered = hovered_resource_id && hovered_resource_id === resource_id;
  const muted = hovered_resource_id && !hovered;

  return (
    <Div p={{ b: 3 }} {...other_props}>
      <StyledRow w={100} p={2} flex={{ align: 'center', justify: 'space-between' }} muted={!!muted}>
        <StyledRowBackground width={width} muted={!!muted} hovered={!!hovered} />
        <Div style={{ zIndex: 2 }} flex={{ align: 'center', gap: 2 }}>
          <LegendColor id={resource_id} />
          <Text monospace>{label}</Text>
        </Div>
        <Text style={{ zIndex: 2 }}>{value}</Text>
      </StyledRow>
    </Div>
  );
};

const Tooltip = ({
  resource_id,
  resource_name,
  last_updated_at,
  tooltip_data,
}: {
  resource_id: string;
  resource_name: string;
  last_updated_at: number;
  tooltip_data: {
    label: string;
    value: string;
  }[];
}) => {
  return (
    <MouseTooltip>
      <StyledCard raised min_w={{ px: 296 }}>
        <StyledCardSection p={3}>
          <Div flex={{ align: 'center', justify: 'space-between' }}>
            <Div style={{ zIndex: 2 }} flex={{ align: 'center', gap: 2 }}>
              <LegendColor id={resource_id} />
              <Text text_wrap={false} heading>
                {resource_name}
              </Text>
            </Div>
            <Text heading>{format(new Date(last_updated_at), 'eee d, h:mm aa')}</Text>
          </Div>
        </StyledCardSection>
        {tooltip_data &&
          tooltip_data.map(({ label, value }) => (
            <StyledCardSection p={3} key={label}>
              <Div flex={{ align: 'center', justify: 'space-between' }}>
                <Text muted m={{ r: 2 }}>
                  {label}
                </Text>
                <Text muted>{value}</Text>
              </Div>
            </StyledCardSection>
          ))}
      </StyledCard>
    </MouseTooltip>
  );
};

const StyledSkeletonLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.on.neutral.secondary_neutral};
    pointer-events: none;

    svg {
      fill: ${theme.colors.on.neutral.secondary_neutral};
    }
  `,
);

const MetricTableSkeleton = ({
  title,
  description,
  skeleton_count,
  total_count,
  collapsed,
  footer,
  scroll_position,
}: {
  title: string;
  description?: string;
  skeleton_count: number;
  total_count: number;
  collapsed: boolean;
  footer: string;
  scroll_position: number;
}) => {
  return (
    <>
      <StyledCardSection p={4}>
        <Div flex={{ justify: 'space-between', align: 'center' }}>
          <Div>
            <Text heading as={'h3'} m={0}>
              {title}
            </Text>
            {description && <Text muted>{description}</Text>}
          </Div>
          <Button outline skeleton icon={'refresh'} />
        </Div>
      </StyledCardSection>
      <StyledCardSection>
        <ScrollArea p={4} max_h={{ px: 432 }} scroll_position={scroll_position}>
          <Div flex={{ direction: 'column', gap: 2 }}>
            <>
              {Array.from(Array(skeleton_count)).map((_, i) => (
                <Skeleton key={i} h={{ px: 36 }} w={{ raw: `${i < 3 ? 100 - i * 10 : 70}%` }} />
              ))}
            </>
          </Div>
          {total_count > 3 && (
            <Div m={{ t: 3 }}>
              {collapsed ? (
                <StyledSkeletonLink neutral onClick={() => null} style={{ pointerEvents: 'none' }}>
                  See {total_count - 3} more
                </StyledSkeletonLink>
              ) : (
                <StyledSkeletonLink neutral style={{ pointerEvents: 'none' }}>
                  See less
                </StyledSkeletonLink>
              )}
            </Div>
          )}
        </ScrollArea>
      </StyledCardSection>
      {footer && (
        <StyledCardSection p={4}>
          <Text muted>{footer}</Text>
        </StyledCardSection>
      )}
    </>
  );
};

const getWidth = (current: number, max: number) => {
  return `${(current / max) * 100}%`;
};

const MetricTable = ({
  metric,
  refresh_key,
  configs,
  footer_text,
  footer_link,
  ...other_props
}: Props) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { team } = useContext(DashboardContext);
  const [_refresh_key, setRefreshKey] = useState(refresh_key || new Date().getTime());
  const [loading_state, setLoadingState] = useState({
    skeleton_count: 3,
    total_count: 0,
    scroll_position: 0,
  });
  const [show_all, setShowAll] = useState(false);
  const [hovered_resource_id, setHoveredResourceId] = useState<string | null>(null);

  const {
    type,
    title,
    description,
    resource,
    formatTableTooltipData: formatTooltipData,
    formatDataForDisplay,
  } = metric_definitions[metric];

  refresh_key = refresh_key || _refresh_key;

  const { data, error, rate_as } = useMetric<TableData[]>('table', metric, refresh_key, configs);

  const { date_range, options } = configs;

  const unique_ids = useMemo(() => (data ? data.map((d) => d.resource_id) : []), [data]);

  const { data: resources } = useSWR(
    unique_ids.length > 0 ? APIMethodKeys[`${resource}s`].list({ id: unique_ids }) : null,
    () =>
      HookdeckAPI[`${resource}s`].list(
        unique_ids.length > 0 ? { id: unique_ids, archived: true } : { limit: 1 },
      ) as any,
  );

  const resources_by_id = useMemo(
    () =>
      resources?.models
        ? resources.models.reduce(
            (object, resource) => ({ ...object, [resource.id]: resource }),
            {},
          )
        : {},
    [resources],
  );

  const max = data && data[0]?.value;

  const first_rows = useMemo(() => (data ? data.slice(0, 3) : []), [data]);
  const other_rows = useMemo(() => (data ? data.slice(3) : []), [data]);

  const loading = !data || (!resources && unique_ids.length > 0);

  const handleRefresh = () => {
    if (show_all) {
      setLoadingState((state) => ({
        ...state,
        skeleton_count: data?.length || 0,
        total_count: data?.length || 0,
      }));
    } else if (first_rows.length > 0) {
      setLoadingState((state) => ({
        ...state,
        skeleton_count: first_rows.length,
        total_count: data?.length || 0,
      }));
    } else if (!loading && first_rows.length === 0) {
      setLoadingState((state) => ({
        ...state,
        skeleton_count: 3,
        total_count: 0,
      }));
    }
    setRefreshKey(new Date().getTime());
  };

  useEffect(() => {
    setLoadingState((state) => ({
      ...state,
      skeleton_count: 3,
      total_count: 0,
    }));
  }, [options, date_range]);

  return (
    <StyledCard {...other_props}>
      <WithLoader
        loading={loading && !error}
        loading_element={
          <MetricTableSkeleton
            title={title}
            description={description}
            skeleton_count={loading_state.skeleton_count}
            total_count={loading_state.total_count}
            collapsed={!show_all}
            footer={
              footer_text && footer_link
                ? `${footer_text}${footer_link.label}`
                : footer_text
                  ? footer_text
                  : footer_link?.label
                    ? footer_link.label
                    : ''
            }
            scroll_position={loading_state.scroll_position}
          />
        }
        long_loading_element={
          <Loader
            h={{ px: footer_text || footer_link ? 327 : 266 }}
            message={
              'Loading your data is taking a bit longer than usual. It should be ready in a few moments.'
            }
          />
        }>
        <StyledCardSection p={4}>
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <Div>
              <Text heading as={'h3'} m={0}>
                {title}
              </Text>
              {description && <Text muted>{description}</Text>}
            </Div>
            <Button outline icon={'refresh'} onClick={handleRefresh} />
          </Div>
        </StyledCardSection>
        <StyledCardSection>
          {first_rows.length > 0 ? (
            <ScrollArea
              onScroll={(e) =>
                setLoadingState((state) => ({
                  ...state,
                  scroll_position: (e.target as HTMLElement).scrollTop || 0,
                }))
              }
              scroll_position={loading_state.scroll_position}
              p={{ t: 4, l: 4, r: 4, b: isEmpty(other_rows) ? 2 : 4 }}
              max_h={{ px: 432 }}>
              <Div flex={{ direction: 'column' }}>
                {(other_rows.length > 0 && show_all
                  ? [...first_rows, ...other_rows]
                  : [...first_rows]
                ).map((row) => {
                  return (
                    <Fragment key={`${row.resource_id}_${metric}`}>
                      <Row
                        resource_id={row.resource_id}
                        hovered_resource_id={hovered_resource_id}
                        label={resources_by_id[row.resource_id]?.name || row.resource_id}
                        value={
                          formatDataForDisplay
                            ? formatDataForDisplay(row.value)
                            : default_data_formatter[type](row.value, rate_as || undefined)
                        }
                        width={getWidth(row.value, max!)}
                        onMouseEnter={() => setHoveredResourceId(row.resource_id)}
                        onMouseLeave={() => setHoveredResourceId(null)}
                      />
                      {hovered_resource_id === row.resource_id && (
                        <Tooltip
                          key={`${row.resource_id}_${metric}_tooltip`}
                          resource_id={hovered_resource_id}
                          resource_name={resources_by_id[hovered_resource_id]?.name}
                          last_updated_at={refresh_key || 0}
                          tooltip_data={formatTooltipData!(row.value, {
                            resource: resources_by_id[row.resource_id],
                            rate_limit: team!.max_events_per_second,
                          })}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </Div>
              {other_rows.length > 0 && (
                <Div>
                  {show_all ? (
                    <Link as={'button'} onClick={() => setShowAll(!show_all)}>
                      See less
                    </Link>
                  ) : (
                    <Link as={'button'} onClick={() => setShowAll(!show_all)}>
                      See {other_rows.length || loading_state.total_count - 3} more
                    </Link>
                  )}
                </Div>
              )}
            </ScrollArea>
          ) : (
            <Div flex={{ direction: 'column', align: 'center', justify: 'center' }} h={{ px: 164 }}>
              {error ? <MetricError /> : <MetricNoData />}
            </Div>
          )}
        </StyledCardSection>
        {(footer_text || footer_link) && (
          <StyledCardSection p={4}>
            <Text>
              {footer_text && footer_text}
              {footer_link && (
                <Link as="button" primary to={footer_link.to}>
                  {footer_link.label}
                </Link>
              )}
            </Text>
          </StyledCardSection>
        )}
      </WithLoader>
    </StyledCard>
  );
};

export default MetricTable;
