import { useField, useFormikContext } from 'formik';
import { PropsWithChildren, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import field_formats from '../../../../utils/field-formatters';
import Button, { ClickableArea } from '../../base/Button';
import { StyledCard, StyledCardSection } from '../../base/Card';
import Icon from '../../base/Icon';
import Loading from '../../base/Loading';
import Text from '../../base/Text';
import Dropdown from '../../Dropdown';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import { APIList } from '../../../../../../../typings/API.interface';

interface Props<Resource> extends StyledUtilsProps {
  label?: string;
  name: string;
  search_key?: string;
  help?: string;
  placeholder?: string;
  getLabel?: (resource: Resource) => string;
  listResources: (filters: object) => Promise<Resource[] | APIList<Resource>>;
  listResourcesKey: (filters: object) => string;
  ResourcePreview: React.FC<{ resource: Resource }>;
  ResourceFields?: React.FC<{ prefix: string }>;
}

const StyledSearchField = styled.div(
  ({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.spacing(2)};
    border-radius: ${theme.radius.normal};
    position: relative;

    input {
      padding: ${theme.spacing(1.25)} ${theme.spacing(2)};
      flex-grow: 1;
      line-height: ${theme.pxToRem(20)};
      &:focus {
        outline: 1px solid ${theme.colors.outline.focus.primary};
        border-color: ${theme.colors.outline.focus.primary};
      }
    }
    button {
      border: ${theme.border};
      border-left: none;
      border-radius: 0 ${theme.radius.normal} ${theme.radius.normal} 0;
    }
  `,
);

const SelectResourceInput = <Resource extends { id: string; name: string }>({
  label,
  search_key,
  getLabel = (r) => r.name,
  listResources,
  listResourcesKey,
  ResourcePreview,
  ResourceFields,
  name,
  help,
  placeholder,
}: PropsWithChildren<Props<Resource>>) => {
  search_key = search_key || 'name';
  const [search_query, setSearch] = useState('');
  const [show_create, toggleCreate] = useState(false);
  const [show_results, showResults] = useState(false);

  const new_ressource_name = `new_${name}`;
  const [, { value, error, touched }, { setValue, setTouched }] = useField(name);
  const [, { value: new_value }, { setValue: setValueNewResource }] = useField(new_ressource_name);
  const { submitCount, initialValues } = useFormikContext<any>();

  const { data: resources } = useSWR<Resource[] | APIList<Resource>>(
    show_results &&
      listResourcesKey(search_query ? { [search_key]: { contains: search_query } } : {}),
    () =>
      listResources(search_query && search_key ? { [search_key]: { contains: search_query } } : {}),
  );

  useEffect(() => {
    if (!touched && value !== initialValues[name]) {
      setTouched(true);
    }
  }, [value]);

  const getDisplayResourceList = () => {
    return (Array.isArray(resources) ? resources : resources?.models || []).filter((resource) =>
      getLabel(resource).toLowerCase().includes(search_query.toLowerCase()),
    );
  };

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      {!value && (
        <Dropdown
          p={0}
          placement={'bottom-end'}
          parent_width
          renderToggle={(opened, toggle) => (
            <StyledSearchField>
              <input
                type="text"
                value={search_query}
                placeholder={placeholder || 'Select...'}
                onFocus={() => {
                  if (!opened) {
                    toggle(!opened);
                    showResults(true);
                  }
                }}
                onClick={() => {
                  if (!opened) {
                    toggle(!opened);
                    showResults(true);
                  }
                }}
                onChange={(e) => {
                  setTouched(true);
                  setSearch(field_formats.slugify(e.target.value));
                }}
              />
              {ResourceFields && (
                <Button
                  primary
                  minimal
                  onClick={() => {
                    setValue(null);
                    showResults(false);
                    toggle(false);
                    toggleCreate(!show_create);
                  }}>
                  {show_create ? 'Cancel' : 'New'}
                </Button>
              )}
            </StyledSearchField>
          )}>
          {(toggle) => (
            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
              {!resources ? (
                <Div flex={{ justify: 'center' }} p={3}>
                  <Icon icon="loading" muted />
                </Div>
              ) : (
                <>
                  {getDisplayResourceList().map((resource) => (
                    <StyledCardSection key={resource.id}>
                      <ClickableArea
                        flex={{ direction: 'column', align: 'start' }}
                        p={{ x: 3, y: 3 }}
                        onClick={() => {
                          setValue(resource);
                          setSearch('');
                          toggle(false);
                        }}>
                        {getLabel(resource) && <Text m={0}>{getLabel(resource)}</Text>}
                      </ClickableArea>
                    </StyledCardSection>
                  ))}
                  {getDisplayResourceList().length === 0 &&
                    (ResourceFields ? (
                      <StyledCardSection>
                        <ClickableArea
                          flex={{ align: 'center' }}
                          p={{ x: 3, y: 3 }}
                          onClick={() => {
                            setValueNewResource({
                              ...new_value,
                              name: search_query,
                            });
                            setValue(null);
                            toggle(false);
                            toggleCreate(!show_create);
                          }}>
                          <Icon icon="add_circle" muted left />
                          {search_query}
                        </ClickableArea>
                      </StyledCardSection>
                    ) : (
                      <Text p={{ x: 3, y: 3 }} muted>
                        No results..
                      </Text>
                    ))}
                </>
              )}
            </div>
          )}
        </Dropdown>
      )}
      {value && (
        <Div flex={{ align: 'center' }}>
          {value && !value ? (
            <Loading require={[value]} />
          ) : (
            <>
              <ResourcePreview resource={value} />
              <Button
                onClick={() => {
                  setValue(null);
                }}
                icon="close"
                outline
                m={{ l: 2 }}
              />
            </>
          )}
        </Div>
      )}
      {!value && show_create && ResourceFields && (
        <>
          <StyledCard m={{ bottom: 2, top: 1 }}>
            <StyledCardSection p={{ all: 4 }}>
              <ResourceFields prefix={new_ressource_name} />
            </StyledCardSection>
          </StyledCard>
          <Div flex={{ justify: 'flex-end' }}>
            <Button
              minimal
              onClick={() => {
                setSearch('');
                toggleCreate(false);
              }}>
              Cancel
            </Button>
          </Div>
        </>
      )}
      {help && (
        <Text m={{ t: 1, b: 0 }} as="p" muted>
          {help}
        </Text>
      )}
      {error && (touched || submitCount > 0) && (
        <Text m={{ t: 1, b: 0 }} as="p" danger>
          {error}
        </Text>
      )}
    </>
  );
};

export default SelectResourceInput;
