import { useContext } from 'react';
import useSWR from 'swr';

import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import APIMethodKeys from '../../../../../client/APIMethodKeys';

import { GlobalContext } from '../../../../contexts/GlobalContext';
import { useTheme } from 'styled-components';

export const useStripeElementOptions = () => {
  const theme = useTheme();
  return {
    style: {
      base: {
        fontSize: theme.font_size,
        color: theme.colors.on.neutral.primary_neutral,
        '::placeholder': {
          color: theme.colors.on.neutral.secondary_neutral,
        },
      },
      invalid: {
        color: theme.colors.on.neutral.danger,
      },
    },
  };
};

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY as string);

export const WrapWithStripeElement: React.FC<{
  loading: JSX.Element;
  children: (props: { stripe: any; elements: any; client_secret: string }) => React.ReactNode;
}> = ({ loading, children }) => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const { data: intent } = useSWR(
    APIMethodKeys.billing.getToken(),
    () => HookdeckAPI.billing.getToken(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  if (!intent) {
    return loading;
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret: intent.client_secret }}>
      <ElementsConsumer>
        {({ stripe, elements }) =>
          children({ stripe, elements, client_secret: intent.client_secret })
        }
      </ElementsConsumer>
    </Elements>
  );
};

export default WrapWithStripeElement;
