import { useContext } from 'react';

import { Destination } from '../../../../../../../../../typings/Destination.interface';
import { Source } from '../../../../../../../../../typings/Source.interface';
import { Webhook } from '../../../../../../../../../typings/Webhook.interface';
import { capitalizeFirstLetter } from '../../../../../../utils';
import { useDialog } from '../../../../../common/Dialog';
import { useToasts } from '../../../../../common/Toast';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog';
import { ResourcesContext } from '../../ResourcesContext';

const configs = {
  webhooks: 'connection',
  destinations: 'destination',
  sources: 'source',
} as const;

const useOnDelete = (type: keyof typeof configs) => {
  const { mutateResourceType } = useContext(ResourcesContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const { HookdeckAPI } = useContext(GlobalContext);
  const label = configs[type];
  const onDelete = (resource: Webhook | Source | Destination) => {
    const apply = () => {
      return HookdeckAPI[type]
        .delete(resource.id)
        .then(() => {
          addToast(
            'success',
            `Deleted ${label} ${'full_name' in resource ? resource.full_name : resource.name}`,
          );
          mutateResourceType!('webhooks', resource, true);
        })
        .catch(() => {
          addToast(
            'error',
            `Failed to delete webhook ${
              'full_name' in resource ? resource.full_name : resource.name
            }`,
          );
        });
    };
    showDialog(
      () => {
        apply();
      },
      () => null,
      {
        danger: true,
        title: `Delete ${capitalizeFirstLetter(label)}`,
        submit_label: `Delete ${capitalizeFirstLetter(label)}`,
        submit_icon: 'delete',
        cancel_label: `Keep ${capitalizeFirstLetter(label)}`,
        message: ConfirmDeleteDialog({
          text: `By deleting this ${label}, no events will be accepted or delivered anymore. Are you sure?`,
        }),
      },
    );
  };

  return onDelete;
};

export default useOnDelete;
