import { ElementRef, useEffect, useRef, useState } from 'react';

import Tooltip from './base/Tooltip';
import StyledEllipsisOverflow from './helpers/EllipsisOverflow';

export function insertMiddleEllipsis(text: string, max_length: number) {
  if (text.length <= max_length) return text;
  const side_length = Math.floor((max_length - 3) / 2);
  return text.slice(0, side_length) + '...' + text.slice(text.length - side_length);
}

export function useTruncatable({
  text,
  target,
}: {
  text?: string;
  target?: ElementRef<'span' | 'div'> | null;
}) {
  const [ref_width, setRefWidth] = useState<number | null>(null);

  useEffect(() => {
    if (!target) return;
    const resizeObserver = new ResizeObserver((observed) => {
      if (observed[0].contentRect.width < 100) {
        return;
      }
      setRefWidth(observed[0].contentRect.width);
    });
    resizeObserver.observe(target);
    return () => resizeObserver.disconnect(); // clean up
  }, [target]);

  if (!text || !ref_width) return [text ?? '', false] as const;
  const should_truncate = ref_width < 100 ? false : text.length / ((ref_width - 24) / 8) > 1;
  const display_text = should_truncate ? insertMiddleEllipsis(text, ref_width / 8) : text;
  const truncated = text !== display_text;
  return [display_text, truncated] as const;
}

export function Truncatable({ text }: { text: string }) {
  const ref = useRef<ElementRef<'span'>>(null);
  const [display_text, truncated] = useTruncatable({ text, target: ref?.current || null });

  return (
    <StyledEllipsisOverflow ref={ref} as="span" aria-label={text}>
      {truncated ? (
        <Tooltip tooltip={text} placement="bottom-start">
          {display_text}
        </Tooltip>
      ) : (
        text
      )}
    </StyledEllipsisOverflow>
  );
}
