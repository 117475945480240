import styled, { css } from 'styled-components';
import { Div, StyledUtilsProps } from '../helpers/StyledUtils';

interface Props extends StyledUtilsProps {
  value: number;
  cancelled: boolean;
}

const StyledProgressBar = styled(Div)<Props>(
  ({ theme, value, cancelled }) => css`
    height: 8px;
    width: 100%;
    border-radius: ${theme.radius.large};
    background-color: ${theme.colors.surface.container.neutral};
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      width: ${value * 100}%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: ${value === 1
        ? theme.colors.surface.base.success
        : cancelled
          ? theme.colors.surface.base.danger
          : theme.colors.surface.base.primary};
      border-radius: ${theme.radius.large};
      transition: width 0.25s ease-in-out;
    }
  `,
);

const ProgressBar: React.FC<Props> = (props) => <StyledProgressBar {...props} />;

export default ProgressBar;
