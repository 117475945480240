import { Route, RouteProps } from 'react-router';
import useTitle from '../hooks/useTitle';

interface ViewRouteProps extends RouteProps {
  title?: string;
  subtitle?: string;
  usePathAsTitle?: boolean;
  capitalizeSubtitle?: boolean;
}

const ViewRoute = ({
  title,
  usePathAsTitle,
  subtitle,
  capitalizeSubtitle,
  ...routeProps
}: ViewRouteProps) => {
  useTitle({ title, subtitle, usePath: usePathAsTitle, capitalizeSubtitle });
  return <Route {...routeProps} />;
};

export default ViewRoute;
