import { Grid, GridUnit } from '@hookdeck/theme';
import { Field, Form, Formik, useField } from 'formik';
import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import LINKS from '../../../../../configs/links';
import { fieldName } from '../../../../../utils';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';
import Tabs from '../../../../common/base/Tabs';
import Text from '../../../../common/base/Text';
import Dropdown from '../../../../common/Dropdown';
import { Div } from '../../../../common/helpers/StyledUtils';
import { ScreenModal } from '../../../../common/Modal';
import Table from '../../../../common/Table';
import { useToasts } from '../../../../common/Toast';
import EditorInput from '../../../../common/Form/Fields/EditorInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { getCurrentTimezoneAbreviation } from '../../../../../utils/date';
import DisplayDate from '../../../../common/DisplayDate';
import useEventList from '../../../../hooks/useEventList';

const StyledTextInput = styled.textarea(
  ({ theme }) => css`
    margin-bottom: 0 !important;
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 500px;
    padding: ${theme.spacing(3)};
    box-sizing: border-box;
  `,
);

const test_input_default_values = {
  headers: '',
  body: '',
  query: '',
  path: '',
};

const FilterRuleForm: React.FC<{
  name: string;
  source_id?: string;
  webhook_id?: string;
  onClose: () => any;
}> = ({ name, source_id, webhook_id, onClose }) => {
  const [{ value: rule }] = useField(name);
  const [{ value: test_input }, , { setValue: setTestInput }] = useField(
    fieldName('test_input', name),
  );
  const [prompt, setPrompt] = useState('');

  const { addToast } = useToasts();
  const { query, updateSearchQuery } = useSearchQuery<{ filter_tab: string }>();
  const active_tab = query.filter_tab || 'body';
  const [, , { setValue: setFilter }] = useField(fieldName(active_tab, name));
  const { HookdeckAPI } = useContext(GlobalContext);
  const [test_results, setFilterTestResult] = useState<{
    headers: boolean;
    body: boolean;
    query: boolean;
    path: boolean;
  } | null>(null);
  const [selected_request_or_event_id, setRequestOrEventId] = useState<string>('');

  const [{ value: request_id_input }] = useField('request_id');

  const showEvents = !!webhook_id;
  const showRequests = !webhook_id;
  const { data: requests } = useSWR(
    source_id && showRequests && APIMethodKeys.requests.list({ source_id, limit: 5 }),
    () => HookdeckAPI.requests.list({ source_id, limit: 5 }),
  );

  const { events } = useEventList(
    showEvents ? { webhook_id: [webhook_id], cli_id: { all: true } } : {},
    'created_at',
    'desc',
    {
      limit: 5,
      include_data: true,
      fetch: showEvents,
    },
  );

  const requests_or_events = showEvents ? events : requests?.models;
  const item_type = showEvents ? 'Event' : 'Request';

  const request_id = showRequests ? selected_request_or_event_id || requests?.models[0]?.id : null;
  const { data: request } = useSWR(
    request_id && showRequests && APIMethodKeys.requests.get(request_id),
    () => HookdeckAPI.requests.get(request_id!),
  );

  const event_id = showEvents ? selected_request_or_event_id || events[0]?.id : undefined;
  const event_fetched = events.find((event) => event.id === event_id);
  const { data: event_result } = useSWR(
    !!event_id && showEvents && !event_fetched && APIMethodKeys.events.get(event_id!),
    () => HookdeckAPI.events.get(event_id!),
  );
  const event = event_id ? event_fetched || event_result : events[0];

  const request_or_event = showEvents ? event : request;
  const test_data = request_or_event?.data || test_input_default_values;

  useEffect(() => {
    if (test_data) {
      const input = !test_data
        ? {
            headers: '"Data archived"',
            body: '"Data archived"',
            parsed_query: '"Data archived"',
            path: '"Data archived"',
          }
        : Object.keys(test_data).reduce(
            (obj, key) => ({
              ...obj,
              [key]: JSON.stringify(test_data[key], null, '\t'),
            }),
            {} as { [key: string]: string },
          );
      input.query = input.parsed_query;
      if (selected_request_or_event_id) {
        addToast('success', `Set ${item_type.toLowerCase()} data as filter input`);
      }
      setTestInput(input);
    }
    if (!test_data && !test_input) {
      setTestInput(test_input_default_values);
    }
  }, [test_data]);

  useEffect(() => {
    setFilterTestResult(null);
  }, [rule, test_input]);

  const onFilterTest = async ({
    headers,
    body,
    query,
    path,
  }: {
    headers: any;
    body: any;
    query: any;
    path: any;
  }): Promise<void> => {
    let input: { headers: any; body: any; parsed_query: any; path: any } | null = null;
    try {
      input = null || {
        headers: test_input?.headers ? JSON.parse(test_input.headers) : null,
        body: test_input?.body ? JSON.parse(test_input?.body || '') : null,
        parsed_query: test_input?.query ? JSON.parse(test_input?.query || '') : null,
        path: test_input?.path ? JSON.parse(test_input?.path || '') : null,
      };
    } catch {
      addToast('error', "Invalid JSON in input, can't test filter");
      return;
    }

    let filter: null | {
      headers: any;
      body: any;
      query: any;
      path: any;
    } = null;
    try {
      filter = {
        headers: headers ? JSON.parse(headers) : undefined,
        body: body ? JSON.parse(body) : undefined,
        query: query ? JSON.parse(query) : undefined,
        path: path ? JSON.parse(path) : undefined,
      };
    } catch {
      addToast('error', "Invalid JSON in filter syntax, can't test filter");
      return;
    }

    if (input && filter) {
      return await HookdeckAPI.utils
        .testFilters(input, filter)
        .then((results) => setFilterTestResult(results[1]));
    }
  };

  const onPromptSubmit = (values) => {
    if (!values.prompt) return;

    return HookdeckAPI.completion
      .completeFilter(
        values.prompt,
        test_input[active_tab] ? test_input[active_tab].replace(/\n/g, '').replace(/\t/g, '') : '',
      )
      .then(({ filter }) => {
        if (filter) {
          setFilter(JSON.stringify(JSON.parse(filter), null, 2));
          setPrompt(values.prompt);
          addToast('success', 'Filter updated based on AI completion.');
        } else {
          addToast('error', "Filter couldn't be automatically generated. Enter a filter manually.");
        }
      })
      .catch(() => {
        addToast('error', "Filter couldn't be automatically generated. Enter a filter manually.");
      });
  };

  const not_filtered =
    test_results &&
    test_results.body &&
    test_results.headers &&
    test_results.query &&
    test_results.path;

  return (
    <ScreenModal>
      <Div p={3} flex={{ align: 'center', justify: 'space-between' }}>
        <Div flex={{ align: 'center', gap: 4 }}>
          <Button onClick={onClose} outline icon="close" />
          <Text heading flex={{ align: 'center' }}>
            <Icon icon="filter" left />
            Connection Filter
          </Text>
        </Div>
        <Div flex={{ align: 'center', gap: 2 }}>
          {test_results && (
            <>
              <Text subtitle flex={{ align: 'center' }} m={{ r: 4 }}>
                <Icon
                  left
                  icon={not_filtered ? 'success' : 'block'}
                  success={!!not_filtered}
                  danger={!not_filtered}
                />
                {not_filtered ? 'Not Filtered' : 'Filtered'}
              </Text>
            </>
          )}
          <Button onClick={() => onFilterTest(rule)} outline icon="rule">
            Test Filter
          </Button>
          <Button primary icon="success" onClick={onClose}>
            Confirm
          </Button>
        </Div>
      </Div>
      <Divider />
      <Div p={{ x: 3, t: 2 }} flex={{ align: 'center', justify: 'space-between' }}>
        <Tabs
          compact
          tabs={[
            {
              key: 'body',
              label: 'Body',
              icon: test_results ? (test_results.body ? 'success' : 'block') : undefined,
              icon_theme: test_results ? (test_results.body ? 'success' : 'danger') : undefined,
              ...(rule.body
                ? {
                    badge_label: '1',
                    badge_theme: test_results
                      ? test_results.body === false
                        ? 'danger'
                        : 'success'
                      : 'muted',
                  }
                : {}),
            },
            {
              key: 'headers',
              label: 'Headers',
              icon: test_results ? (test_results.headers ? 'success' : 'block') : undefined,
              icon_theme: test_results ? (test_results.headers ? 'success' : 'danger') : undefined,
              ...(rule.headers
                ? {
                    badge_label: '1',
                    badge_theme: test_results
                      ? test_results.headers === false
                        ? 'danger'
                        : 'success'
                      : 'muted',
                  }
                : {}),
            },
            {
              key: 'query',
              label: 'Query',
              icon: test_results ? (test_results.query ? 'success' : 'block') : undefined,
              icon_theme: test_results ? (test_results.query ? 'success' : 'danger') : undefined,
              ...(rule.query
                ? {
                    badge_label: '1',
                    badge_theme: test_results
                      ? test_results.query === false
                        ? 'danger'
                        : 'success'
                      : 'muted',
                  }
                : {}),
            },
            {
              key: 'path',
              label: 'Path',
              icon: test_results ? (test_results.path ? 'success' : 'block') : undefined,
              icon_theme: test_results ? (test_results.path ? 'success' : 'danger') : undefined,
              ...(rule.path
                ? {
                    badge_label: '1',
                    badge_theme: test_results
                      ? test_results.path === false
                        ? 'danger'
                        : 'success'
                      : 'muted',
                  }
                : {}),
            },
          ]}
          border={false}
          active_tab={active_tab}
          onTabSelected={(key) => updateSearchQuery({ filter_tab: key })}
        />
        <Button
          m={{ b: 2 }}
          minimal
          small
          as="a"
          href={LINKS.product_docs.filters}
          target="_blank"
          icon="document">
          Filters syntax
        </Button>
      </Div>
      <Divider />

      <Grid>
        <GridUnit size={1 / 2}>
          <Div flex>
            <Div
              flex={{ grow: true, direction: 'column' }}
              w={100}
              style={{ height: 'calc(100vh - 102px)' }}>
              <Div p={{ x: 4, y: 2 }} flex={{ justify: 'space-between', align: 'center' }}>
                <Text subtitle>
                  {request_or_event
                    ? `${selected_request_or_event_id || 'Latest'} ${item_type} Data`
                    : 'Test Data'}
                </Text>
                <Dropdown minimal small label="Change Data" icon="change">
                  <Div p={4}>
                    <Text subtitle size="s" m={{ t: 0, b: 1 }}>
                      Latest {item_type}
                    </Text>
                    <StyledCard overflow_hidden>
                      <Table
                        widths={[
                          { min: 148, max: 148 },
                          { min: 180, max: 180 },
                        ]}
                        onRowSelected={(id: string) => setRequestOrEventId(id)}
                        headers={[`${item_type} Date (${getCurrentTimezoneAbreviation()})`, 'ID']}
                        rows={
                          (requests_or_events || [])
                            .sort((a, b) =>
                              new Date(a.created_at).getTime() > new Date(b.created_at).getTime()
                                ? -1
                                : 1,
                            )
                            .map((request_or_event) => ({
                              id: request_or_event.id,
                              selected: selected_request_or_event_id === request_or_event.id,
                              fields: [
                                <DisplayDate
                                  key={`${request_or_event.id}-date`}
                                  date={request_or_event.created_at}
                                />,
                                <Text key={`${request_or_event.id}-id`} monospace muted size="s">
                                  {request_or_event.id}
                                </Text>,
                              ],
                            })) || []
                        }
                      />
                    </StyledCard>
                    <Text subtitle size="s" m={{ t: 8, b: 1 }}>
                      Specific {item_type} ID
                    </Text>
                    <Div flex={{ align: 'center', gap: 2 }}>
                      <TextInput
                        w={100}
                        m={{ b: 0 }}
                        monospace
                        name={fieldName('request_id', name)}
                        placeholder={`Enter a ${item_type} ID`}
                      />
                      <Button
                        outline
                        disabled={request_id_input === selected_request_or_event_id}
                        onClick={() => setRequestOrEventId(rule.request_id)}
                        icon="check">
                        Apply
                      </Button>
                    </Div>
                  </Div>
                </Dropdown>
              </Div>
              <Divider />
              <EditorInput
                key={active_tab}
                flex={{ direction: 'column', grow: true }}
                name={fieldName(`test_input.${active_tab}`, name)}
              />
            </Div>
            <Divider vertical />
          </Div>
        </GridUnit>
        <GridUnit size={1 / 2}>
          <Div flex={{ grow: true, direction: 'column' }} style={{ height: 'calc(100vh - 102px)' }}>
            <Div p={{ x: 4, y: 2 }} flex={{ justify: 'space-between', align: 'center' }}>
              <Text subtitle>Filter Schema</Text>
              <Formik initialValues={{ prompt: '' }} onSubmit={onPromptSubmit}>
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <Dropdown
                      placement="bottom-end"
                      renderToggle={(open, toggle) => (
                        <Button
                          small
                          onClick={() => toggle(!open)}
                          primary
                          minimal
                          icon="auto_awesome">
                          AI Filter
                        </Button>
                      )}>
                      <StyledCardSection p={4}>
                        <Field name="prompt">
                          {({ field }) => (
                            <StyledTextInput
                              {...field}
                              rows={4}
                              placeholder="Describe your desired filter..."
                            />
                          )}
                        </Field>
                      </StyledCardSection>
                      <StyledCardSection
                        p={{ x: 4, y: 3 }}
                        flex={{ justify: 'space-between', align: 'center', gap: 4 }}>
                        <Text muted flex={{ align: 'center' }}>
                          <Icon icon="info" muted left />
                          Data will be shared with OpenAI.
                        </Text>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          onClick={submitForm}
                          small
                          icon={isSubmitting ? 'loading' : undefined}>
                          Generate
                        </Button>
                      </StyledCardSection>
                    </Dropdown>
                  </Form>
                )}
              </Formik>
            </Div>
            <Divider />
            <EditorInput
              key={`${active_tab}-${prompt}`}
              flex={{ direction: 'column', grow: true }}
              name={fieldName(active_tab, name)}
            />
          </Div>
        </GridUnit>
      </Grid>
    </ScreenModal>
  );
};

export default FilterRuleForm;
