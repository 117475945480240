import { FormEvent } from 'react';
import SelectInput from '../../Form/Fields/SelectInput';
import { Div } from '../../helpers/StyledUtils';

const SelectFilter: React.FC<{
  delete_button?: JSX.Element;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
  name: string;
  options: { label: string; value: string }[];
}> = ({ delete_button, onSubmit, options, name }) => {
  return (
    <Div flex={{ align: 'center' }} m={0}>
      <SelectInput onChange={onSubmit} options={options} name={name} m={0} />
      {delete_button && delete_button}
    </Div>
  );
};

export default SelectFilter;
