import { Placement } from '@popperjs/core';
import { Field as FormikField, useField, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import useDebounce from '../../../hooks/useDebounce';
import Button from '../../base/Button';
import Divider from '../../base/Divider';
import Icon from '../../base/Icon';
import Loading from '../../base/Loading';
import Text from '../../base/Text';
import Dropdown from '../../Dropdown';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import Checkbox from './Checkbox';
import { Variant } from './Input/Input';
import LabelButton from '../../base/LabelButton';
import Search from '../../Search';
import { withStaticProperties } from '../../../../utils/withStaticProperties';
import { TeamRole } from '../../../../../../../typings/TeamMember.interface';
import { useAuthorized } from '../../../contexts/TeamPermissionContext';

const SEARCH_DELAY_MS = 500;

export interface MultiSelectSelectOption {
  value: string;
  label: string;
}

interface Props extends StyledUtilsProps {
  label?: string;
  variant?: Variant;
  neutral?: boolean;
  option_title: string;
  name: string;
  initial_options: MultiSelectSelectOption[];
  renderToggle?: (opened, toggle, values) => JSX.Element;
  placement?: Placement;
  help?: string;
  required?: boolean;
  disabled?: boolean;
  formatFilters?: (search_term?: string, values?: string[]) => any;
  fetcher?: (filters: object) => Promise<any>;
  fetcherKey?: (filters: object) => string;
  formatOptions?: (data: any) => MultiSelectSelectOption[];
  onClose?: () => void;
  onRemove?: () => void;
}

const StyledInput = styled.div``;
const StyledField = styled(Div)<{
  variant?: Variant;
  custom_toggle?: boolean;
  disabled?: boolean;
}>(
  ({ theme, variant, custom_toggle, disabled }) => css`
    width: 100%;
    ${!custom_toggle &&
    css`
      min-width: 300px;
    `};
    &:last-of-type {
      margin-bottom: 0;
    }

    ${StyledInput} {
      padding: ${theme.spacing(0.75)} ${theme.spacing(0.75)} !important;
      background-color: ${theme.colors.surface.base.surface};
      width: fit-content;
      border-radius: ${theme.radius.normal};
      cursor: text;
      box-sizing: border-box;
      border: 1px solid ${theme.colors.outline.neutral};
      width: 100%;
      display: flex;

      &:focus,
      &:hover {
        outline: 1px solid ${theme.colors.outline.focus.primary};
        border-color: ${theme.colors.outline.focus.primary};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
      }

      ${disabled &&
      css`
        background-color: ${theme.colors.surface.base.disabled};
      `}

      > ${Text} {
        margin-left: auto;
      }
    }

    ${variant === 'card' &&
    css`
      > label {
        margin: 0;
        border: 1px solid ${theme.colors.outline.neutral};
        border-bottom: none;
        border-radius: ${theme.radius.normal} ${theme.radius.normal} 0 0;
        padding: ${theme.pxToRem(4)} ${theme.pxToRem(8)};
        background-color: ${theme.colors.surface.base.variant_surface_2};
        font-size: ${theme.font_size[12]};
      }

      ${StyledInput} {
        border-radius: 0 0 ${theme.radius.normal} ${theme.radius.normal};
      }
    `}
  `,
);

const StyledTitle = styled(Div)`
  background-color: ${({ theme }) => theme.colors.surface.base.variant_surface_2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.outline.neutral};
`;
const StyledOption = styled(Checkbox)<React.HTMLAttributes<HTMLElement> & { monospace: boolean }>(
  ({ theme, monospace }) => css`
    font-weight: 500;
    margin: 0;
    cursor: pointer;
    ${monospace &&
    css`
      font-family:
        JetBrains Mono,
        monospace;
    `}

    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.outline.neutral};
    }

    &:hover {
      background-color: ${theme.colors.surface.base.hover.neutral};
    }

    label {
      padding: 12px;
      margin: 0;
    }
  `,
);

const StyledOptions = styled(Div)<React.HTMLAttributes<HTMLElement>>`
  overflow-y: auto;
  max-height: 300px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.outline.neutral};
  border-top: 1px solid ${({ theme }) => theme.colors.outline.neutral};
`;

const StyledLoadingOption = styled(Div)`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.surface.base.surface};
`;

const validate = (value: string[], required: boolean) => {
  if (value?.length < 1 && required) return 'Required';
};

const search_initial_state = {
  search_term: '',
  search_term_by_term: {},
};

const options_initial_state: {
  options: MultiSelectSelectOption[];
  filtered_options: MultiSelectSelectOption[];
  all_options_selected: boolean;
} = {
  options: [],
  filtered_options: [],
  all_options_selected: false,
};

const MultiSelectInput: React.FC<Props> = (props) => {
  const {
    label,
    variant,
    option_title,
    name,
    help,
    initial_options,
    required = false,
    neutral,
    disabled,
    formatFilters,
    fetcher,
    fetcherKey,
    formatOptions,
    onClose,
    onRemove,
    renderToggle,
    placement,
    ...other_props
  } = props;

  const [field, { value, error, touched }, { setValue, setTouched }] = useField<string[]>({
    name,
    validate: (value) => validate(value, required),
  });

  const { submitCount } = useFormikContext();

  const [{ search_term, search_term_by_term }, setSearchState] = useState(search_initial_state);

  const [{ options, filtered_options, all_options_selected }, setOptionsState] =
    useState(options_initial_state);

  const debounced_search_term = useDebounce(search_term, SEARCH_DELAY_MS);

  const [should_fetch, setShouldFetch] = useState(false);

  const { isValidating } = useSWR(
    should_fetch &&
      fetcher &&
      fetcherKey &&
      formatFilters &&
      fetcherKey(debounced_search_term && formatFilters(debounced_search_term)),
    () => fetcher!(debounced_search_term && formatFilters!(debounced_search_term)),
    {
      onSuccess: (data) => {
        handleAddOptions(formatOptions!(data));
        setShouldFetch(false);
      },
    },
  );

  const options_by_value: { [key: string]: MultiSelectSelectOption } = useMemo(() => {
    return options
      ? options.reduce((object, o) => ({ ...object, [o.value]: { ...o } }), {})
      : { no_option: 0 };
  }, [options]);

  useSWR(
    value &&
      fetcherKey &&
      formatFilters &&
      Object.keys(options_by_value).length === 0 &&
      fetcherKey(formatFilters(undefined, value)),
    () => fetcher!(formatFilters!(undefined, value)),
    {
      onSuccess: (data) => {
        handleAddOptions(formatOptions!(data));
      },
    },
  );

  const values_index_by_value: { [key: string]: number } = value
    ? value.reduce((object, v, i) => ({ ...object, [v]: i }), {})
    : { no_value: 0 };

  const { handleSearch, handleAsyncSearch } = {
    handleSearch: (v) => {
      setSearchState((prev) => ({ ...prev, search_term: v }));
    },
    handleAsyncSearch: () => {
      setShouldFetch(true);
      setSearchState((prev) => ({
        ...prev,
        search_term_by_term: { ...prev.search_term_by_term, [search_term]: search_term },
      }));
    },
  };

  const {
    handleFilterOptions,
    handleRemoveValue,
    handleToggleValue,
    handleToggleAll,
    handleAddOptions,
  } = {
    handleFilterOptions: () => {
      let all_selected = true;
      const new_options = options?.filter((o) => {
        if (o.label.toLowerCase().includes(search_term.toLowerCase())) {
          if (values_index_by_value[o.value] === undefined) {
            all_selected = false;
          }
          return true;
        }
        return false;
      });
      setOptionsState((prev) => ({
        ...prev,
        all_options_selected: new_options?.length > 0 ? all_selected : false,
      }));
      return new_options;
    },
    handleRemoveValue: (i: number) => {
      const new_value = [...value];
      new_value.splice(i, 1);
      setTouched(true);
      setValue(new_value);
    },
    handleToggleValue: (e: React.ChangeEvent<HTMLInputElement>, v: string) => {
      if (e.currentTarget.checked && values_index_by_value[v] === undefined) {
        setTouched(true);
        setValue(value ? [...value, v] : [v]);
        return;
      }
      if (!e.currentTarget.checked && values_index_by_value[v] !== undefined) {
        handleRemoveValue(values_index_by_value[v]);
      }
    },
    handleToggleAll: (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.checked && !all_options_selected) {
        const new_values: string[] = [];
        filtered_options.forEach((v: MultiSelectSelectOption) => {
          if (values_index_by_value !== undefined && values_index_by_value[v.value] !== undefined) {
            return;
          }

          new_values.push(v.value);
        });
        setTouched(true);
        setValue(value ? [...value, ...new_values] : [...new_values]);
        return;
      }
      if (!e.currentTarget.checked && all_options_selected) {
        let new_values = value;
        const filtered_options_by_value: { [key: string]: MultiSelectSelectOption } =
          filtered_options &&
          filtered_options.reduce(
            (object: { [key: string]: MultiSelectSelectOption }, o: MultiSelectSelectOption) => ({
              ...object,
              [o.value]: { ...o },
            }),
            {},
          );
        new_values = new_values.filter((v) => filtered_options_by_value[v] === undefined);
        setTouched(true);
        setValue(new_values);
      }
    },
    handleAddOptions: (new_options: MultiSelectSelectOption[]) => {
      setOptionsState((prev) => ({
        ...prev,
        options: [
          ...prev.options,
          ...new_options.filter((o) => options_by_value[o.value] === undefined),
        ],
      }));
    },
  };

  useEffect(() => {
    setOptionsState((prev) => ({
      ...prev,
      options: initial_options && initial_options?.length > 0 ? initial_options : [],
    }));
  }, []);

  useEffect(() => {
    if (value?.length > 0 || search_term !== '') {
      setOptionsState((prev) => ({ ...prev, filtered_options: handleFilterOptions() }));
      return;
    }
    setOptionsState((prev) => ({ ...prev, filtered_options: options }));
  }, [search_term, options, value?.length]);

  useEffect(() => {
    if (
      search_term === '' ||
      (search_term_by_term !== undefined && search_term_by_term[search_term] !== undefined)
    ) {
      return;
    }
    handleAsyncSearch();
  }, [debounced_search_term]);

  return (
    <Div m={{ b: 4 }} {...other_props}>
      <StyledField variant={variant} custom_toggle={!!renderToggle} disabled={disabled}>
        {label && (
          <label htmlFor={name}>
            <Text subtitle size={variant === 'card' ? 'xs' : 's'}>
              {label}
            </Text>
          </label>
        )}
        <Dropdown
          prevent_event_on_children
          parent_width={{ min: 320 }}
          p={0}
          disabled
          onToggle={(opened) => {
            if (!opened) {
              onClose && onClose();
            }
          }}
          placement={placement || 'bottom-start'}
          renderToggle={(opened, toggle) =>
            renderToggle ? (
              renderToggle(
                opened,
                (e) => {
                  toggle(!opened, e);
                  !opened && !should_fetch && setShouldFetch(true);
                },
                value,
              )
            ) : (
              <FormikField
                as={StyledInput}
                {...field}
                onClick={(e) => {
                  if (disabled) return;
                  toggle(!opened, e);
                  !opened && !should_fetch && setShouldFetch(true);
                }}>
                {value?.length > 0 && fetcher && Object.keys(options_by_value).length === 0 && (
                  <Div m={{ l: 2 }}>
                    <Loading />
                  </Div>
                )}
                <Div flex={{ wrap: true }} onClick={() => toggle(!opened)}>
                  {value &&
                    value.map(
                      (v, i) =>
                        options_by_value[v] !== undefined && (
                          <LabelButton
                            key={v}
                            label={options_by_value[v].label}
                            icon="close"
                            m={0.5}
                            neutral={neutral}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveValue(i);
                              onRemove && onRemove();
                            }}
                          />
                        ),
                    )}
                </Div>
                <Text user_select={false} muted p={{ x: 1, y: 0.5 }}>
                  Add
                </Text>
              </FormikField>
            )
          }>
          <StyledTitle p={{ y: 1, x: 3 }}>
            <Text size="xs" subtitle text_wrap={false}>
              {option_title}
            </Text>
          </StyledTitle>
          <Div p={{ x: 3, y: 2 }}>
            <Search small onChange={handleSearch} value={search_term} focus />
          </Div>
          {search_term !== '' && filtered_options?.length > 0 && (
            <>
              <Divider />
              <Div>
                <StyledOption
                  flex
                  p={{ x: 3, y: 2 }}
                  monospace={!!fetcher}
                  label={
                    <Text
                      muted
                      text_wrap={false}
                      style={{ display: 'flex', minWidth: 0, flexGrow: 1 }}>
                      Add all containing&nbsp;
                      {<Text as={'span'} ellipsis primary>{` "${search_term}"`}</Text>}
                    </Text>
                  }
                  name={''}
                  checked={all_options_selected}
                  onChange={(e) => handleToggleAll(e)}
                />
              </Div>
            </>
          )}
          <StyledOptions>
            {filtered_options?.length > 0 ? (
              filtered_options.map((o) => (
                <StyledOption
                  key={o.label}
                  p={{ x: 3, y: 2 }}
                  label={o.label}
                  monospace={!!fetcher}
                  name={o.label}
                  checked={values_index_by_value[o.value] !== undefined}
                  onChange={(e) => handleToggleValue(e, o.value)}
                />
              ))
            ) : (
              <Div padding={{ x: 3, y: 3 }}>No option</Div>
            )}
            {isValidating && (
              <StyledLoadingOption>
                <Divider />
                <Div p={{ x: 3, y: 2 }} flex={{ justify: 'center', align: 'center' }}>
                  <Icon right={1} muted small icon="loading" />
                </Div>
              </StyledLoadingOption>
            )}
          </StyledOptions>
          <Button
            m={{ x: 3, y: 2 }}
            outline
            onClick={() => {
              setTouched(true);
              setValue([]);
            }}>
            Clear All
          </Button>
        </Dropdown>
        {help && (
          <Text m={{ t: 1 }} as="span" muted>
            {help}
          </Text>
        )}
      </StyledField>
      {(touched || submitCount > 0) && error && (
        <Text m={{ t: 1, b: 0 }} as="p" danger>
          {error}
        </Text>
      )}
    </Div>
  );
};

const MultiSelectInputPermission: React.FC<
  React.ComponentPropsWithoutRef<typeof MultiSelectInput> & { role?: TeamRole }
> = ({ role, ...props }) => {
  const authorized = useAuthorized(role);
  return <MultiSelectInput {...props} disabled={props.disabled || !authorized} />;
};

export default withStaticProperties(MultiSelectInput, { Permission: MultiSelectInputPermission });
