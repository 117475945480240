import { Fragment, useState } from 'react';
import { Varitant as Variant } from '../../scenes/DashboardScene/Metrics/metric-categories';
import Button from '../base/Button';
import Text from '../base/Text';
import { Div, StyledUtilsProps } from '../helpers/StyledUtils';
import useLocalStorage from '../../hooks/useLocalStorage';
import TextSwitch from '../base/TextSwitch';
import { ChartTimeOptions } from '../Chart/Chart';

interface Props extends StyledUtilsProps {
  id?: string;
  title: string;
  description?: string;
  children?: React.ReactNode;

  variants?: Variant[];
  setVariant?: (variant: Variant) => void;
  variantMetric?(variant: Variant): React.ReactNode;
  time_options?: ChartTimeOptions;
  onRefresh?: () => void;
}

const MetricWrapper = ({
  id,
  title,
  description,
  children,
  variants,
  variantMetric,
  time_options,
  onRefresh,
  ...other_props
}: Props) => {
  const [refresh_key, setRefreshKey] = useState(new Date().getTime());
  const [variant, setVariant] = useLocalStorage(
    `metrics:${title}:variant`,
    variants ? variants[0] : undefined,
  );

  if (variants && (!variant || !variants.find((v) => v.key === variant.key))) {
    setVariant(variants[0]);
  }

  const { time_unit, time_units, onTimeUnitSelected } = time_options || {};

  return (
    <Div id={id} {...other_props}>
      <Div m={{ b: 4 }} flex={{ justify: 'space-between', align: 'center' }}>
        <Div>
          <Text heading size={'l'} as={'h3'} m={0}>
            {title}
          </Text>
          {description && <Text muted>{description}</Text>}
        </Div>
        <Div flex={{ align: 'center', gap: 2 }}>
          {variants && variants.length > 1 && variant && (
            <TextSwitch
              options={variants}
              active={variant.key}
              onSelect={(key) => setVariant(variants.find((v) => v.key === key))}
            />
          )}
          {onTimeUnitSelected && time_units && time_units.length > 1 && (
            <TextSwitch
              options={time_units}
              active={time_unit || time_units[0].key}
              onSelect={(key) => onTimeUnitSelected(key as string)}
            />
          )}
          <Button
            outline
            icon="reload"
            onClick={() => {
              onRefresh && onRefresh();
              setRefreshKey(new Date().getTime());
            }}
          />
        </Div>
      </Div>
      <Fragment key={refresh_key}>
        {variantMetric && variant && variantMetric(variant)}
        {children}
      </Fragment>
    </Div>
  );
};

export default MetricWrapper;
