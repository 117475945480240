import styled, { css } from 'styled-components';

import { StyledCard } from './base/Card';
import Text from './base/Text';
import { Div, StyledUtilsProps } from './helpers/StyledUtils';

const StyledInfoTable = styled(StyledCard)`
  display: flex;
  flex-wrap: wrap;
`;

const StyledInfoTableEntry = styled(Div)<{ even: boolean; last_row: boolean }>(
  ({ even, last_row, theme }) => css`
    width: 50%;
    position: relative;
    ${even &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border-right: ${theme.border};
      }
    `}
    ${!last_row &&
    css`
      border-bottom: ${theme.border};
    `};
  `,
);

const InfoTable: React.FC<
  { entries: { label: string; element: JSX.Element }[] } & StyledUtilsProps
> = ({ entries, ...props }) => {
  return (
    <StyledInfoTable {...props}>
      {entries.map((entry, i) => (
        <StyledInfoTableEntry
          even={i % 2 === 0}
          last_row={i > entries.length - (entries.length % 2 === 0 ? 3 : 2)}
          key={entry.label}>
          <Div p={{ x: 4, y: 3 }} flex={{ align: 'center', justify: 'space-between', gap: 4 }}>
            <Text muted text_wrap={false}>
              {entry.label}
            </Text>
            {entry.element}
          </Div>
        </StyledInfoTableEntry>
      ))}
    </StyledInfoTable>
  );
};

export default InfoTable;
