import { useContext } from 'react';
import Dropdown from '../../../common/Dropdown';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import { GlobalContext } from '../../../contexts/GlobalContext';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import useSWR from 'swr';
import team_integration_schemas from '../../../../configs/team-integration-schemas';
import { Div } from '../../../common/helpers/StyledUtils';
import Icon from '../../../common/base/Icon';
import Badge from '../../../common/base/Badge';
import Text from '../../../common/base/Text';
import Button, { PlaceholderButton } from '../../../common/base/Button';
import { showChat } from '../../../../utils/liveChat';

const ExportDropdown = () => {
  const { HookdeckAPI } = useContext(GlobalContext);

  const { data: integrations } = useSWR(APIMethodKeys.team_integrations.list(), () =>
    HookdeckAPI.team_integrations.list(),
  );

  return (
    <Dropdown outline label="Metrics Export" icon="export">
      <StyledCardSection p={4} w={{ px: 400 }}>
        <Text m={{ b: 4 }}>Export metrics in realtime to your observability tool of choice.</Text>
        <Div flex={{ direction: 'column', gap: 3 }}>
          {Object.entries(team_integration_schemas)
            .filter(([_, schema]) => schema.features.includes('metrics'))
            .map(([key, schema]) => {
              const existing_integration = integrations?.models.find((i) => i.provider === key);
              return (
                <StyledCard
                  key={key}
                  flex={{ justify: 'space-between' }}
                  p={{ l: 3, r: !existing_integration ? 2 : 3, y: 2 }}>
                  <Div flex={{ align: 'center' }}>
                    <Icon icon={schema.icon} small m={{ r: 2 }} />
                    {schema.label}
                  </Div>
                  {existing_integration ? (
                    <Badge subtle success m={{ l: 2, y: 1 }}>
                      Connected
                    </Badge>
                  ) : (
                    <Button
                      to={`/settings/project/integrations/${key}/connect`}
                      outline
                      small
                      icon="connect">
                      Connect
                    </Button>
                  )}
                </StyledCard>
              );
            })}
        </Div>
        <PlaceholderButton m={{ t: 4 }} p={{ y: 2.5 }} onClick={showChat}>
          Request a new integration
        </PlaceholderButton>
      </StyledCardSection>
    </Dropdown>
  );
};

export default ExportDropdown;
