import { useContext } from 'react';
import { organization_levels_by_role, team_levels_by_role } from '../../../../../domain/roles';
import { TeamRole } from '../../../../../typings/TeamMember.interface';
import { DashboardContext } from '../scenes/DashboardScene/DashboardContext';

const withOrganizationRole =
  (with_role: TeamRole) =>
  ({ children }: { children: React.ReactNode }) => {
    const { organization_role } = useContext(DashboardContext);

    if (
      typeof organization_levels_by_role[with_role] === 'undefined' ||
      typeof organization_levels_by_role[organization_role as TeamRole] === 'undefined'
    ) {
      return null;
    }

    if (organization_levels_by_role[organization_role!] < organization_levels_by_role[with_role]) {
      return null;
    }

    return <>{children}</>;
  };

const withTeamRole =
  (with_role: TeamRole) =>
  ({ children }: { children: React.ReactNode }) => {
    const { team_role } = useContext(DashboardContext);

    if (
      typeof team_levels_by_role[with_role] === 'undefined' ||
      typeof team_levels_by_role[team_role as TeamRole] === 'undefined'
    ) {
      return null;
    }

    if (team_levels_by_role[team_role!] < team_levels_by_role[with_role]) {
      return null;
    }

    return <>{children}</>;
  };

export const WithTeamAdminRole = withTeamRole('admin');

export const WithOrganizationAdminRole = withOrganizationRole('admin');
