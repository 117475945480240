import { addDays, differenceInDays } from 'date-fns';
import { Subscription } from '../../../../../typings/Subscription.interface';

export function useMemberTrial(subscription?: Subscription) {
  if (!subscription) return {};

  const member_trial_ends_at =
    subscription?.team_member_trial_start_at &&
    addDays(new Date(subscription.team_member_trial_start_at), 14);
  const is_member_trial_active =
    subscription?.plan === 'free' && member_trial_ends_at && member_trial_ends_at > new Date();
  const member_trial_left_days =
    is_member_trial_active && differenceInDays(member_trial_ends_at, new Date());
  const is_member_trial_expired =
    subscription?.plan === 'free' &&
    !!subscription?.team_member_trial_start_at &&
    !is_member_trial_active;

  return {
    is_member_trial_active,
    member_trial_left_days,
    is_member_trial_expired,
  };
}
