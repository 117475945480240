import { useContext } from 'react';

import { extractFromArray, parseQuery, stringifyQuery } from '../../../../utils';

import Button, { ButtonGroup } from '../../../common/base/Button';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import PostCreateModal from './Create/PostCreateModal';
import { ResourcesContextProvider, group_by_options, order_by_options } from './ResourcesContext';
import { DashboardContext } from '../DashboardContext';
import EmptyState from '../../../common/EmptyState';
import { useTheme } from 'styled-components';
import { withTeamPermission } from '../../../contexts/TeamPermissionContext';
import Search from '../../../common/Search';
import DropdownMenu from '../../../common/DropdownMenu';
import { Div } from '../../../common/helpers/StyledUtils';
import ConnectionsList from './List/ConnectionsList';
import Container from '../../../common/base/Container';
import LabelButton from '../../../common/base/LabelButton';
import Text from '../../../common/base/Text';
import useSWR from 'swr';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { GlobalContext } from '../../../contexts/GlobalContext';

const statuses = {
  active: 'Enabled',
  paused: 'Paused',
  disabled: 'Disabled',
};

interface WebhooksListQueryParams {
  status?: string | string[];
  source_id?: string | string[];
  destination_id?: string | string[];
}

interface WebhooksListFiltersProps {
  status?: keyof typeof statuses;
  source_id?: string;
  destination_id?: string;
}

const extractFiltersFromQuery = (
  parsed_query: WebhooksListQueryParams,
): WebhooksListFiltersProps => {
  return {
    status: extractFromArray(parsed_query.status),
    source_id: extractFromArray(parsed_query.source_id),
    destination_id: extractFromArray(parsed_query.destination_id),
  };
};

const ConnectionsView: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { has_connection } = useContext(DashboardContext);
  const theme = useTheme();
  const { query, updateSearchQuery } = useSearchQuery<
    WebhooksListQueryParams & { created: string }
  >();

  const filters = extractFiltersFromQuery(query);

  const { data } = useSWR(
    filters.source_id || filters.destination_id
      ? filters.source_id
        ? APIMethodKeys.sources.get(filters.source_id)
        : APIMethodKeys.destinations.get(filters.destination_id as string)
      : false,
    () =>
      (filters.source_id
        ? HookdeckAPI.sources.get(filters.source_id)
        : HookdeckAPI.destinations.get(filters.destination_id as string)) as any,
  );

  return (
    <>
      {query.created && <PostCreateModal />}
      <ResourcesContextProvider filters={filters}>
        {({ search_term, setSearchTerm, setGroupBy, setOrderBy, order_by, group_by }) => (
          <StyledViewWrapper>
            <StyledViewContent>
              <PageNav breadcrumb={[{ icon: 'connections', title: 'Connections' }]}>
                <Div w={{ px: 272 }}>
                  <Search
                    onChange={setSearchTerm!}
                    value={search_term || ''}
                    placeholder="Search by id or name..."
                  />
                </Div>
                <ButtonGroup>
                  <DropdownMenu
                    title="Group by"
                    icon={group_by_options[group_by!].icon}
                    placement="bottom-start"
                    outline
                    parent_width={{ min: 200 }}
                    label={`By ${group_by_options[group_by!].label}`}
                    options={Object.entries(group_by_options).map(([key, { label, icon }]) => ({
                      label: label,
                      icon: icon,
                      onClick: () => setGroupBy?.(key as keyof typeof group_by_options),
                    }))}
                  />
                  <DropdownMenu
                    title="Sort by"
                    icon="sort"
                    placement="bottom-end"
                    parent_width={{
                      min: 200,
                    }}
                    outline
                    label={order_by_options[order_by!].label}
                    options={Object.entries(order_by_options).map(([key, { label }]) => ({
                      label,
                      onClick: () => setOrderBy?.(key as keyof typeof order_by_options),
                    }))}
                  />
                  <DropdownMenu
                    title="Filter by status"
                    icon="filter"
                    placement="bottom-end"
                    outline
                    parent_width={{ min: 200 }}
                    label={statuses[filters.status as keyof typeof statuses] || 'All'}
                    options={[
                      { label: 'All', onClick: () => updateSearchQuery({ status: undefined }) },
                      ...Object.entries(statuses).map(([key, label]) => ({
                        label,
                        onClick: () => updateSearchQuery({ status: [key] }),
                      })),
                    ]}
                  />
                </ButtonGroup>
                <Button.Permission
                  to={(location) => ({
                    ...location,
                    pathname: `/connections/new`,
                    search: `?${stringifyQuery({
                      ...parseQuery(location.search),
                      default_source_id: undefined,
                    })}`,
                    state: { scroll: false },
                  })}
                  primary
                  icon="add_circle">
                  Connection
                </Button.Permission>
              </PageNav>
              {!has_connection ? (
                <EmptyState
                  title="Manage your connections"
                  description="Connections consist of a request source, an event destination, and customizable rules that determine the behavior of events traveling on your configured route. Create a connection to experience the full benefits of Hookdeck."
                  asset={`/images/empty/connections-${theme.mode}.svg`}
                  cta={{
                    label: 'Create connection',
                    icon: 'add_circle',
                    to: '/create-first-connection',
                  }}
                />
              ) : (
                <Container
                  xlarge
                  h={100}
                  p={{ x: 4, t: filters.source_id || filters.destination_id ? 4 : 9.5 }}>
                  {filters.source_id && (
                    <Div flex m={{ b: 4 }}>
                      <Text m={{ r: 2 }} subtitle>
                        Filtering on
                      </Text>
                      <LabelButton
                        label={'test'}
                        label_icon="source"
                        monospace
                        icon="close"
                        onClick={() => updateSearchQuery({ source_id: undefined })}
                      />
                    </Div>
                  )}
                  {filters.destination_id && (
                    <Div flex m={{ b: 4 }}>
                      <Text m={{ r: 2 }} subtitle>
                        Filtering on
                      </Text>
                      <LabelButton
                        label={data?.name || 'Loading...'}
                        monospace
                        label_icon="destination"
                        icon="close"
                        onClick={() => updateSearchQuery({ destination_id: undefined })}
                      />
                    </Div>
                  )}
                  <ConnectionsList filtering={!!filters.status} />
                </Container>
              )}
            </StyledViewContent>
          </StyledViewWrapper>
        )}
      </ResourcesContextProvider>
    </>
  );
};

export default withTeamPermission(ConnectionsView, 'member');
