import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
  const { pathname, state } = useLocation();

  useLayoutEffect(() => {
    if (!state || state.scroll !== false) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
};

export default useScrollToTop;
