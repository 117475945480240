import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import useSWR from 'swr';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { numberWithCommas } from '../../../../../utils';
import { useCopyToClipboard } from '../../../../../utils/copy';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import { StyledCard } from '../../../../common/base/Card';
import Container from '../../../../common/base/Container';
import Divider from '../../../../common/base/Divider';
import LabelButton from '../../../../common/base/LabelButton';
import Loading from '../../../../common/base/Loading';
import Skeleton from '../../../../common/base/Skeleton';
import Text from '../../../../common/base/Text';
import { useDialog } from '../../../../common/Dialog';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useMetric } from '../../../../common/metrics/useMetric';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { TeamPermission, withTeamPermission } from '../../../../contexts/TeamPermissionContext';
import { useDateRange } from '../../Metrics/MetricDatePicker';
import NotFound from '../../NotFound';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../../StyledView';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import resource_details_form_props from '../Forms/resource_details';
import rule_form_props from '../Forms/rules';
import ResourceMetricSection from '../ResourceMetricsSection';
import SaveButton from '../SaveButton';

const ConnectionView = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { addToast } = useToasts();
  const showDialog = useDialog();
  const history = useHistory();
  const {
    data: connection,
    mutate,
    error,
  } = useSWR(APIMethodKeys.webhooks.get(id), () => HookdeckAPI.webhooks.get(id));

  const copyToClipboard = useCopyToClipboard();

  const [date_range] = useDateRange();

  const { data: events_count } = useMetric<number>('card', 'events_count', 0, {
    options: {
      filters: { webhook_id: [id] },
    },
    date_range,
  });

  const onUpdate = async (values) =>
    HookdeckAPI.webhooks
      .update(id, values)
      .then((connection) => {
        mutate(connection);
        addToast('success', 'Connection saved');
        return connection;
      })
      .catch((e) => {
        addToast(
          'error',
          `An error occurred while saving the connection${
            e.response?.data[0] ? `: ${e.response.data[0]}` : ''
          }`,
        );
        throw e;
      });

  const onUnpause = async () => {
    HookdeckAPI.webhooks
      .unpause(id)
      .then((connection) => {
        mutate(connection);
        addToast('success', 'Connection unpaused');
      })
      .catch(() => {
        addToast('error', 'Failed to pause connection');
      });
  };

  const onPause = async () => {
    HookdeckAPI.webhooks
      .pause(id)
      .then((connection) => {
        mutate(connection);
        addToast('success', 'Connection paused');
      })
      .catch(() => {
        addToast('error', 'Failed to pause connection');
      });
  };

  const onEnable = async () => {
    HookdeckAPI.webhooks
      .enable(id)
      .then((connection) => {
        mutate(connection);
        addToast('success', 'Connection enabled');
      })
      .catch(() => {
        addToast('error', 'Failed to enable connection');
      });
  };

  const onDisable = async () => {
    showDialog(
      () => {
        HookdeckAPI.webhooks
          .disable(id)
          .then((connection) => {
            mutate(connection);
            addToast('success', 'Connection disabled');
          })
          .catch(() => {
            addToast('error', 'Failed to enable connection');
          });
      },
      () => null,
      {
        title: 'Disable Connection',
        submit_label: 'Disable',
        cancel_label: 'Cancel',
        message:
          "By disabling this connection, all existing events will stop delivery and future events won't be processed. Are you sure?",
      },
    );
  };

  const onDelete = async () => {
    showDialog(
      () => {
        HookdeckAPI.webhooks
          .delete(id)
          .then(() => {
            addToast('success', 'Connection deleted');
            history.push('/connections');
          })
          .catch(() => {
            addToast('error', 'Failed to delete connection');
          });
      },
      () => null,
      {
        danger: true,
        title: 'Delete Connection',
        submit_label: 'Delete',
        cancel_label: 'Keep Connection',
        message: ConfirmDeleteDialog({
          text: 'By deleting this connection, no current and future events will be delivered anymore. Are you sure?',
        }),
      },
    );
  };

  if (error && error.response?.status === 404) {
    return (
      <NotFound
        title="Connection not found"
        description="This connection does not exist or has been deleted."
        id={id}
        link={{ to: '/connections', text: 'All Connections' }}
      />
    );
  }

  if (error && error.response?.status === 410) {
    return (
      <StyledViewWrapper>
        <StyledViewContent>
          <Div flex={{ justify: 'center' }} p={8}>
            <Container small center>
              <Text center size="l">
                Connection deleted
              </Text>
              <Text as="p" m={{ t: 4, b: 2 }}>
                This connection was permanently deleted.
              </Text>
              <Button primary minimal m={{ t: 4 }} to={`/connections`}>
                Connections
              </Button>
            </Container>
          </Div>
        </StyledViewContent>
      </StyledViewWrapper>
    );
  }

  return (
    <StyledViewWrapper>
      <StyledViewContent light>
        <PageNav
          breadcrumb={[
            { icon: 'connections', title: 'Connections', path: '/connections' },
            { icon: 'connections', title: connection?.full_name || null, monospace: true },
          ]}
        />
        <Container large>
          {!connection ? (
            <Div m={{ t: 8 }}>
              <Loading />
            </Div>
          ) : (
            <Div m={{ b: 14 }}>
              <Text as="h2" heading m={{ t: 14, b: 2 }} flex={{ align: 'center' }}>
                Connection Overview
                {connection.disabled_at && (
                  <Badge muted icon="disable" small m={{ l: 2 }}>
                    Disabled
                  </Badge>
                )}
                {connection.paused_at && (
                  <Badge warning subtle icon="pause_circle" small m={{ l: 2 }}>
                    Paused
                  </Badge>
                )}
              </Text>
              <Divider m={{ b: 4 }} />
              <Div flex={{ gap: 4 }}>
                <StyledCard flex={{ grow: true, justify: 'space-between', align: 'center' }} p={3}>
                  <Div>
                    <Text subtitle muted size="s">
                      Connection ID
                    </Text>
                    <Text monospace ellipsis>
                      {connection.id}
                    </Text>
                  </Div>
                  <Button outline onClick={() => copyToClipboard(connection.id)} icon="copy" />
                </StyledCard>
                <StyledCard flex={{ justify: 'space-between', align: 'center' }} p={3}>
                  <Div>
                    <Text subtitle muted size="s">
                      Events · Last 24h
                    </Text>
                    {events_count !== undefined ? (
                      <Text monospace ellipsis>
                        {numberWithCommas(events_count)}
                      </Text>
                    ) : (
                      <Skeleton w={{ px: 100 }} h={{ px: 20 }} loading />
                    )}
                  </Div>
                  <Button outline to={`/events?webhook_id[0]=${id}`} icon="link" />
                </StyledCard>
              </Div>
              <Div flex={{ gap: 4 }} m={{ t: 4 }}>
                <StyledCard
                  overflow_hidden
                  flex={{ justify: 'space-between', align: 'center' }}
                  p={3}>
                  <Div w={100}>
                    <Text subtitle muted size="s">
                      Source
                    </Text>
                    <LabelButton
                      neutral
                      monospace
                      label={connection.source.name}
                      to={`/sources/${connection.source.id}`}
                    />
                  </Div>
                </StyledCard>
                <StyledCard
                  overflow_hidden
                  flex={{ justify: 'space-between', align: 'center' }}
                  p={3}>
                  <Div w={100}>
                    <Text subtitle muted size="s">
                      Destination
                    </Text>
                    <LabelButton
                      neutral
                      monospace
                      label={connection.destination.name}
                      to={`/destinations/${connection.destination.id}`}
                    />
                  </Div>
                </StyledCard>
              </Div>
              <ResourceMetricSection id={id} resource="webhook" />
              <TeamPermission role="member">
                <Formik
                  initialValues={resource_details_form_props.getInitialValues(connection)}
                  validate={(values) =>
                    resource_details_form_props.validate(
                      values,
                      (name) =>
                        !name || name === connection.name
                          ? Promise.resolve(false)
                          : HookdeckAPI.webhooks.nameIsUsed(connection.source.id, name),
                      false,
                    )
                  }
                  onSubmit={(values, { resetForm }) =>
                    onUpdate(resource_details_form_props.postprocessValues(values)).then(
                      (connection) => {
                        resetForm({
                          values: resource_details_form_props.getInitialValues(connection),
                        });
                      },
                    )
                  }>
                  <Form>
                    <Text as="h2" heading m={{ t: 14, b: 2 }}>
                      Connection Details
                    </Text>
                    <Divider m={{ b: 4 }} />
                    <resource_details_form_props.Fields
                      prefix=""
                      placeholder=""
                      show_description
                      name_required={false}
                    />
                    <SaveButton m={{ t: 4 }} />
                  </Form>
                </Formik>
                <Formik
                  initialValues={{
                    rules: rule_form_props.getInitialValues(connection.rules),
                  }}
                  validate={async (values) =>
                    cleanseFormErrorObject({
                      rules: await rule_form_props.validate(values.rules, HookdeckAPI),
                    })
                  }
                  onSubmit={async (values, { resetForm }) => {
                    return onUpdate({
                      rules: rule_form_props.postprocessValues(values.rules),
                    }).then((connection) =>
                      resetForm({
                        values: {
                          rules: rule_form_props.getInitialValues(connection.rules),
                        },
                      }),
                    );
                  }}>
                  <Form>
                    <Text as="h2" heading m={{ t: 14, b: 2 }}>
                      Rules
                    </Text>
                    <Divider m={{ b: 4 }} />
                    <rule_form_props.Fields
                      prefix="rules"
                      webhook_id={connection.id}
                      source_id={connection.source.id}
                      display="full"
                    />
                    <SaveButton m={{ t: 4 }} />
                  </Form>
                </Formik>
                <Text as="h2" heading m={{ t: 14, b: 0 }}>
                  {connection.paused_at ? 'Unpause' : 'Pause'} Connection
                </Text>
                <Text as="p" muted m={{ b: 2 }}>
                  {connection.paused_at
                    ? 'Unpausing your connection will resume delivery all paused events on this connection'
                    : 'Pausing your connection will pause all event delivery until the connection is unpaused and accumulate new events on this connection'}
                </Text>
                <Divider m={{ b: 4 }} />
                <Button.Permission
                  outline
                  onClick={connection.paused_at ? onUnpause : onPause}
                  icon={connection.paused_at ? 'play_circle' : 'pause_circle'}>
                  {connection.paused_at ? 'Unpause' : 'Pause'}
                </Button.Permission>
                <Text as="h2" heading m={{ t: 14, b: 0 }}>
                  {connection.disabled_at ? 'Enable' : 'Disable'} Connection
                </Text>
                <Text as="p" muted m={{ b: 2 }}>
                  {connection.disabled_at
                    ? 'Enabling your connection will resume delivery of new events on this connection'
                    : 'Disabling your connection will stop all event delivery and ignore future events on this connection'}
                </Text>
                <Divider m={{ b: 4 }} />
                <Button.Permission
                  outline
                  onClick={connection.disabled_at ? onEnable : onDisable}
                  icon={connection.disabled_at ? 'add_circle' : 'inventory'}>
                  {connection.disabled_at ? 'Enable' : 'Disable'}
                </Button.Permission>
                <Text as="h2" heading m={{ t: 14, b: 0 }}>
                  Delete Connection
                </Text>
                <Text as="p" muted m={{ b: 2 }}>
                  Deleting this connection will permanently delete it.
                </Text>
                <Divider m={{ b: 4 }} />
                <Button.Permission danger onClick={onDelete} icon="delete">
                  Delete
                </Button.Permission>
              </TeamPermission>
            </Div>
          )}
        </Container>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default withTeamPermission(ConnectionView, 'member');
