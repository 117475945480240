import { StyledUtilsProps } from '../../helpers/StyledUtils';
import { StyledSwitch } from './SwitchInput';

interface Props extends StyledUtilsProps {
  checked: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  show_label?: boolean;
  disabled?: boolean;
}

const Switch: React.FC<Props> = ({ onChange, checked, show_label, ...props }) => {
  return (
    <StyledSwitch as="label" has_label={!!show_label} {...props}>
      {show_label && <span>{checked ? 'On' : 'Off'}</span>}
      <input type="checkbox" checked={checked} disabled={props.disabled} onChange={onChange} />
    </StyledSwitch>
  );
};

export default Switch;
