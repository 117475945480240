export const PLAN_FEATURES_DISPLAY_NAMES = {
  max_users: 'Users',
  retention_days: 'Retention Days',
  max_events_per_second: 'Events per Second',
  all_integrations: 'Integrations',
  metrics: 'Metrics',
  metrics_export: 'Metrics Export',
  viewer_role: 'View-Only Role',
  unlimited_seats: 'Unlimited Seats',
};
