import { useCallback, useContext, useRef, useState } from 'react';
import useSWR from 'swr';
import { SeekPagination } from '../../../../../../../typings/API.interface';
import { Event } from '../../../../../../../typings/Event.interface';
import { IgnoredEvent } from '../../../../../../../typings/IgnoredEvent.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { DashboardContext } from '../DashboardContext';
import { Request } from '../../../../../../../typings/Request.interface';

export interface UseRequestListResult {
  entries: (Event | IgnoredEvent)[];
  fetched: boolean;
  validating: boolean;
  revalidate: (events?: Event[]) => void;
  count: number;
  has_next: boolean;
  has_prev: boolean;
  next: () => void;
  prev: () => void;
}

const useRequestEntriesList = (request?: Request, paging_limit = 10): UseRequestListResult => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { view } = useContext(DashboardContext);
  const [events_paging_params, setEventsPagingParams] = useState<SeekPagination>({
    limit: paging_limit,
  });
  const cached_cursors = useRef<SeekPagination>();
  const [ignored_events_paging_params, setIgnoredEventsPagingParams] = useState<SeekPagination>({
    limit: paging_limit,
  });

  const request_id = request?.id;

  const fetch_events = !ignored_events_paging_params.next && events_paging_params.next !== '$END$';

  const {
    data: events,
    isValidating: events_validating,
    mutate: mutateEvents,
  } = useSWR(
    request_id &&
      fetch_events &&
      APIMethodKeys.requests.listEvents(request_id, {
        ...events_paging_params,
        cli_id: view === 'cli' ? { any: true } : null,
      }),
    () =>
      HookdeckAPI.requests.listEvents(request_id!, {
        ...events_paging_params,
        cli_id: view === 'cli' ? { any: true } : null,
      }),
  );

  const fetch_ignored_events = (events && events?.count < paging_limit) || !fetch_events;
  const ignored_events_limit = fetch_ignored_events && paging_limit - (events?.count || 0);

  const {
    data: ignored_events,
    isValidating: ignored_events_validating,
    mutate: mutateIgnoredEvents,
  } = useSWR(
    request_id &&
      fetch_ignored_events &&
      APIMethodKeys.requests.listIgnoredEvents(request_id, {
        ...ignored_events_paging_params,
        limit: ignored_events_limit,
      }),
    () =>
      HookdeckAPI.requests.listIgnoredEvents(request_id!, {
        ...ignored_events_paging_params,
        limit: ignored_events_limit,
      }),
  );

  const revalidate = useCallback((events?: Event[]) => {
    if (events) {
      mutateEvents(
        (data) => (data && events ? { ...data, models: [...data.models, ...events] } : undefined),
        false,
      );
    } else {
      mutateEvents();
    }
    mutateIgnoredEvents();
  }, []);

  const fetched =
    (!fetch_events || events !== undefined) &&
    (!fetch_ignored_events || ignored_events !== undefined);
  const entries = [...(events?.models || []), ...(ignored_events?.models || [])];

  const curriedChangeCursor = useCallback(
    (dir: 'next' | 'prev') => () => {
      const events_cursor = {
        next: undefined as string | undefined,
        prev: undefined as string | undefined,
        limit: paging_limit,
      };
      const ignored_events_cursor = {
        next: undefined as string | undefined,
        prev: undefined as string | undefined,
        limit: paging_limit,
      };

      if (dir === 'next') {
        if (!events?.pagination.next && !ignored_events) {
          events_cursor.next = '$END$';
          cached_cursors.current = events_paging_params;
        } else if (events?.pagination.next) {
          events_cursor.next = events.pagination.next;
        } else if (ignored_events?.pagination.next) {
          ignored_events_cursor.next = ignored_events.pagination.next;
        }
      } else if (dir === 'prev') {
        if (!ignored_events?.pagination.prev && !events) {
          events_cursor.next = cached_cursors.current?.next;
        } else if (ignored_events?.pagination.prev) {
          ignored_events_cursor.prev = ignored_events.pagination.prev;
        } else if (events?.pagination.prev) {
          events_cursor.prev = events.pagination.prev;
        }
      }

      setEventsPagingParams(events_cursor);
      setIgnoredEventsPagingParams(ignored_events_cursor);
    },
    [events, ignored_events, events_paging_params, paging_limit],
  );

  return {
    entries,
    fetched,
    validating: events_validating || ignored_events_validating,
    revalidate,
    count: entries.length,
    has_next: !!(events?.pagination?.next || ignored_events?.pagination?.next || !ignored_events),
    has_prev: !!(events?.pagination?.prev || ignored_events?.pagination?.prev || !events),
    next: curriedChangeCursor('next'),
    prev: curriedChangeCursor('prev'),
  };
};

export default useRequestEntriesList;
