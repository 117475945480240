import { useContext, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import { extractFromArray } from '../../../../utils';
import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Container from '../../../common/base/Container';
import Loading from '../../../common/base/Loading';
import Text from '../../../common/base/Text';
import Editor from '../../../common/Editor';
import TransformRuleFormWrapper from '../Connections/Forms/TransformRuleForm';
import { Div } from '../../../common/helpers/StyledUtils';
import KeyValueTable from '../../../common/KeyValueTable';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import TransformationExecutionDetails from './TransformationExecutionDetails';
import TransformationExecutionsList from './TransformationExecutionsList';
import TransformationExecutionsView from './TransformationExecutionsView';
import InfoTable from '../../../common/InfoTable';
import { format } from 'date-fns';
import { getCurrentTimezoneAbreviation } from '../../../../utils/date';
import { useCopyToClipboard } from '../../../../utils/copy';
import NotFound from '../NotFound';
import Divider from '../../../common/base/Divider';
import ConfirmDeleteDialog from '../Connections/ConfirmDeleteDialog';
import { useToasts } from '../../../common/Toast';
import { useDialog } from '../../../common/Dialog';
import Tooltip from '../../../common/base/Tooltip';

interface Query {
  selected_execution_id?: string | string[];
}

const TransformationView: React.FC = () => {
  const { id: transformation_id } = useParams<{ id: string }>();
  const { query, updateSearchQuery } = useSearchQuery<Query>();
  const selected_execution_id = extractFromArray(query.selected_execution_id) as string;
  const [show_value, setShowValues] = useState(false);
  const history = useHistory();
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const { HookdeckAPI } = useContext(GlobalContext);
  const copyToClipboard = useCopyToClipboard();
  const { data: transformation, error } = useSWR(
    APIMethodKeys.transformations.get(transformation_id),
    () => HookdeckAPI.transformations.get(transformation_id),
  );
  const { data: transformation_connections } = useSWR(
    APIMethodKeys.transformations.hasConnections(transformation_id),
    () => HookdeckAPI.transformations.hasConnections(transformation_id),
  );
  const disable_delete =
    transformation_connections?.has_connections === undefined ||
    !!transformation_connections?.has_connections;

  if (error && error.response?.status === 404) {
    return (
      <NotFound
        title="Transformation not found"
        description="This transformation does not exist or has been deleted."
        id={transformation_id}
      />
    );
  }

  if (!transformation) {
    return (
      <Div flex={{ justify: 'center' }} p={8}>
        <Loading />
      </Div>
    );
  }

  const onDelete = async () => {
    showDialog(
      () => {
        return HookdeckAPI.transformations
          .delete(transformation_id)
          .then(() => {
            addToast('success', 'Transformation deleted');
            history.push('/connections');
          })
          .catch((error) => {
            addToast('error', error?.response?.data?.message || 'Failed to delete transformation');
          });
      },
      () => null,
      {
        danger: true,
        title: 'Delete Transformation',
        submit_label: 'Delete',
        cancel_label: 'Keep Transformation',
        message: ConfirmDeleteDialog({
          text: "Deleted transformations cannot be recovered. Are you sure you'd like to proceed?",
        }),
      },
    );
  };

  return (
    <StyledViewWrapper>
      <StyledViewContent light>
        <PageNav
          breadcrumb={[
            {
              icon: 'connections',
              title: 'Connections',
              path: `/connections`,
            },
            {
              icon: 'transformation',
              title: transformation.name,
              monospace: true,
            },
          ]}>
          <Button to={(location) => location.pathname + '/edit'} outline icon="edit">
            Open Editor
          </Button>
        </PageNav>
        <Container large>
          <Text heading size="l" m={{ b: 4, t: 16 }}>
            Transformation Details
          </Text>
          <InfoTable
            entries={[
              {
                label: 'Name',
                element: (
                  <Text monospace ellipsis>
                    {transformation.name}
                  </Text>
                ),
              },
              {
                label: 'Created At',
                element: (
                  <Text monospace>
                    {format(new Date(transformation.created_at), `yyyy-MM-dd HH:mm:ss`)}{' '}
                    {getCurrentTimezoneAbreviation()}
                  </Text>
                ),
              },
              {
                label: 'ID',
                element: <Text monospace>{transformation.id}</Text>,
              },
              {
                label: 'Last Updated At',
                element: (
                  <Text monospace>
                    {format(new Date(transformation.updated_at), `yyyy-MM-dd HH:mm:ss`)}{' '}
                    {getCurrentTimezoneAbreviation()}
                  </Text>
                ),
              },
            ]}
          />
          <StyledCard overflow_hidden m={{ y: 8 }}>
            <StyledCardSection
              flex={{ justify: 'space-between', align: 'center' }}
              p={{ x: 4, y: 2 }}>
              <Text subtitle>Transformation Code</Text>
              <Button
                minimal
                small
                icon="copy"
                onClick={() => copyToClipboard(transformation.code)}
              />
            </StyledCardSection>
            <StyledCardSection>
              <Editor
                value={transformation.code}
                language="javascript"
                jsdocs="transformation"
                height="200px"
              />
            </StyledCardSection>
          </StyledCard>
          <KeyValueTable
            label="Environment Variables"
            object={transformation.env || {}}
            preference_key="transformations:vars"
            hide_value={!show_value}
          />
          <StyledCard overflow_hidden m={{ y: 8 }}>
            <StyledCardSection
              p={{ x: 4, y: 2 }}
              flex={{ justify: 'space-between', align: 'center' }}>
              <Text subtitle m={0}>
                Executions
              </Text>
              <Button
                to={(location) => location.pathname + '/executions'}
                minimal
                small
                onClick={() => setShowValues(!show_value)}
                icon="link">
                All Executions
              </Button>
            </StyledCardSection>
            <TransformationExecutionsList
              transformation_id={transformation_id}
              selected_execution_id={selected_execution_id}
              filters={{}}
              onExecutionSelected={(selected_execution_id) =>
                updateSearchQuery({ selected_execution_id })
              }
              onPaginationChanged={(pagination) =>
                updateSearchQuery({ ...pagination, selected_execution_id: undefined })
              }
              onEdit={(execution) =>
                history.push({
                  ...location,
                  search: execution && `?input_execution_id=${execution.id}`,
                  pathname: location.pathname + '/edit',
                })
              }
            />
          </StyledCard>
          <Div m={{ t: 14, b: 16 }}>
            <Text as="h2" heading m={{ b: 0 }}>
              Delete Transformation
            </Text>
            <Text as="p" muted m={{ b: 2 }}>
              Permanently deletes this transformation. Transformations can only be deleted if they
              are not currently in use on a connection.
            </Text>
            <Divider m={{ b: 4 }} />
            <Tooltip
              placement="right"
              disabled={!disable_delete}
              tooltip={
                disable_delete
                  ? 'This transformation is currently in use on a connection.'
                  : undefined
              }>
              <Button.Permission disabled={disable_delete} danger onClick={onDelete} icon="delete">
                Delete
              </Button.Permission>
            </Tooltip>
          </Div>
        </Container>
      </StyledViewContent>
      {selected_execution_id && (
        <TransformationExecutionDetails
          transformation_id={transformation_id}
          execution_id={selected_execution_id}
          onClose={() => updateSearchQuery({ selected_execution_id: undefined })}
        />
      )}
    </StyledViewWrapper>
  );
};

const TransformationEditWrapper: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { transformation_id } = useParams<{ transformation_id: string }>();
  const history = useHistory();
  const location = useLocation();

  const { data: transformation, mutate } = useSWR(
    APIMethodKeys.transformations.get(transformation_id),
    () => HookdeckAPI.transformations.get(transformation_id),
  );
  const onSave = (v) =>
    HookdeckAPI.transformations
      .update(transformation_id, {
        ...v.transformation,
        env: v.transformation.env.reduce(
          (object, [key, value]) => ({ ...object, [key]: value }),
          {},
        ),
      })
      .then((transformation) => mutate(transformation));

  if (!transformation) {
    return null;
  }

  return (
    <TransformRuleFormWrapper
      onSave={onSave}
      onClose={() => {
        if (location.state?.from) {
          history.push(location.state?.from);
        } else {
          history.push(location.pathname.split('/edit')[0]);
        }
      }}
      transformation_id={transformation_id}
      transformation={{
        name: transformation.name,
        code: transformation.code,
        env: Object.entries(transformation.env || {}) as any,
      }}
    />
  );
};

const TransformationViewRouter: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/transformations/:transformation_id/edit*"
        component={TransformationEditWrapper}
      />
      <Route
        path="/transformations/:transformation_id/executions"
        render={({ match, history, location }) => (
          <TransformationExecutionsView
            transformation_id={match.params.transformation_id}
            onEdit={(execution) =>
              history.push({
                ...location,
                search: execution ? `?input_execution_id=${execution.id}` : '',
                pathname: location.pathname.split('/executions')[0] + '/edit',
                state: {
                  from: location.state?.from,
                },
              })
            }
            onClose={() => {
              if (location.state?.from) {
                history.push(location.state?.from);
              } else {
                history.push(location.pathname.split('/executions')[0]);
              }
            }}
          />
        )}
      />
      <Route component={TransformationView} />
    </Switch>
  );
};

export default TransformationViewRouter;
