import { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { StyledCard } from '../../common/base/Card';
import Container from '../../common/base/Container';
import Icon from '../../common/base/Icon';
import Loading from '../../common/base/Loading';
import { Div } from '../../common/helpers/StyledUtils';
import Text from '../../common/base/Text';
import { GlobalContext } from '../../contexts/GlobalContext';
import FullscreenLayout from '../../layouts/FullscreenLayout';
import OnboardingNav from './Onboarding/OnboardingNav';

const CliAuthView: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const [key_claimed, setKeyClaimed] = useState(false);
  const match = useRouteMatch();
  useEffect(() => {
    HookdeckAPI.session.claimCliAuthToken(match.params.key).then(() => {
      setKeyClaimed(true);
    });
  }, []);

  return (
    <FullscreenLayout center>
      <OnboardingNav />
      <Container flex={{ justify: 'center', align: 'center', direction: 'column' }}>
        <Div m={{ bottom: 7 }}>
          <Text heading size="l">
            Connecting your CLI
          </Text>
        </Div>
        <StyledCard raised overflow_hidden p={6} style={{ width: '320px' }}>
          {key_claimed ? (
            <Div
              m={{ bottom: 2 }}
              flex={{ justify: 'center', align: 'center', direction: 'column' }}>
              <Icon icon="success" large success />
              <Text as="p" center m={{ b: 1, t: 4 }}>
                You are now authenticated with the CLI go back to your terminal!
              </Text>
            </Div>
          ) : (
            <Loading />
          )}
        </StyledCard>
      </Container>
    </FullscreenLayout>
  );
};

export default CliAuthView;
