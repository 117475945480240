import React, { forwardRef } from 'react';
import Text from '../../../base/Text';

export interface InputHelpProps extends React.ComponentPropsWithoutRef<'div'> {
  children?: React.ReactNode;
}

export const InputHelp = forwardRef<HTMLDivElement, InputHelpProps>(
  ({ children, ...others }, ref) => {
    return (
      <Text as="div" muted ref={ref} {...others}>
        {children}
      </Text>
    );
  },
);

export default InputHelp;
