import Text from '../../base/Text';
import { StyledUtilsProps } from '../../helpers/StyledUtils';
import { StyledCheckbox } from './CheckboxInput';

interface Props extends StyledUtilsProps {
  label: string | JSX.Element;
  name: string;
  checked: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  disabled?: boolean;
}

const Checkbox: React.FC<Props> = ({ label, checked, name, onChange, ...props }) => {
  return (
    <StyledCheckbox as="label" has_label={!!label} {...props}>
      <input
        name={name}
        type="checkbox"
        checked={checked}
        disabled={props.disabled}
        onChange={onChange}
      />
      {typeof label === 'string' ? (
        <Text as="span" body>
          {label}
        </Text>
      ) : (
        label
      )}
    </StyledCheckbox>
  );
};

export default Checkbox;
