import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import useComponentDefaultProps from '../../../../hooks/useComponentDefaultProps';
import { Div, StyledUtilsProps } from '../../../helpers/StyledUtils';
import InputError from './InputError';
import InputHelp from './InputHelp';
import Text from '../../../base/Text';

export type Variant = 'card';

export interface InputBaseProps {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  help?: React.ReactNode;
  error?: React.ReactNode;
  with_asterisk?: boolean;
  minimal?: boolean;
  inputContainer?(children: React.ReactNode): React.ReactNode;
}

export interface InputProps
  extends StyledUtilsProps,
    Omit<InputBaseProps, 'name'>,
    React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode;
  id?: string;
}

const defaultProps: Partial<InputProps> = {
  inputContainer: (children) => children,
  with_asterisk: false,
  minimal: false,
};

const StyledInput = styled(Div)``;
const StyledFieldWrapper = styled(Div)<{ error: boolean }>(
  ({ theme, error }) => css`
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    ${StyledInput} {
      flex-grow: 1;

      input,
      select {
        box-sizing: border-box;
        width: 100%;
        font-size: ${theme.pxToRem(14)};
        line-height: ${theme.pxToRem(20)};
        padding: ${theme.spacing(2.5)};
        outline: none;
        border: ${theme.border};
        border-radius: ${theme.radius.normal};

        &:focus {
          outline: 1px solid ${theme.colors.outline.focus.primary};
          border-color: ${theme.colors.outline.focus.primary};
        }

        ${error &&
        css`
          border-color: ${theme.colors.outline.danger};
        `}
      }
    }
  `,
);

export const Input = forwardRef<HTMLDivElement, InputProps>((props, ref) => {
  const { label, children, required, id, error, help, inputContainer, ...others } =
    useComponentDefaultProps(defaultProps, props);

  return (
    <Div flex={{ direction: 'column', gap: 1 }} ref={ref} {...others}>
      <StyledFieldWrapper error={!!error}>
        {label && (
          <Text
            subtitle
            size="s"
            as="label"
            key="label"
            id={id ? `${id}-label` : undefined}
            htmlFor={id}>
            {label}
          </Text>
        )}
        <StyledInput>{inputContainer!(children)}</StyledInput>
      </StyledFieldWrapper>
      {help && <InputHelp key="help">{help}</InputHelp>}
      {error && <InputError key="error">{error}</InputError>}
    </Div>
  );
});

export default Input;
