export enum TrackingType {
  promotion = 'promotion',
  referrer = 'referrer',
}

export interface Promotion {
  slug: string;
}

export interface Referrer {
  slug: string;
}
