import { useContext, useMemo } from 'react';
import useSWR from 'swr';

import { APIList } from '../../../../../../../typings/API.interface';
import { Bookmark } from '../../../../../../../typings/Bookmark.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import Button from '../../../common/base/Button';
import LabelButton from '../../../common/base/LabelButton';
import Loading from '../../../common/base/Loading';
import Text from '../../../common/base/Text';
import DisplayDate from '../../../common/DisplayDate';
import { Div } from '../../../common/helpers/StyledUtils';
import { Status } from '../../../common/Status';
import Table from '../../../common/Table';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { DashboardContext } from '../DashboardContext';
import BookmarksListEmptyView from './BookmarksListEmptyView';
import { Event } from '../../../../../../../typings/Event.interface';

type BookmarksListProps = {
  bookmarks?: APIList<Bookmark>;
  events?: APIList<Event>;
  active_filters?: object;
  selected_event_id?: string;
  selected_id: string;
  onEventSelected(id: string): void;
  onBookmarkSelected(id: string | null): void;
  onFilterChanged(filters: object): void;
  onReplay: (bookmark: Bookmark) => void;
};

const BookmarksList: React.FC<BookmarksListProps> = ({
  bookmarks,
  events,
  selected_event_id,
  onEventSelected,
  selected_id,
  onBookmarkSelected,
  onFilterChanged,
  onReplay,
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { view } = useContext(DashboardContext);
  const entries = bookmarks?.models;
  const count = bookmarks?.count;

  const unique_webhook_ids = useMemo(
    () =>
      events ? Array.from(new Set(events.models.map((event) => event.webhook_id))).sort() : [],
    [entries],
  );

  const { data: webhooks } = useSWR(
    unique_webhook_ids && APIMethodKeys.webhooks.list({ id: unique_webhook_ids }),
    () => HookdeckAPI.webhooks.list({ id: unique_webhook_ids }),
  );

  const webhooks_by_id =
    webhooks &&
    webhooks.models.reduce((object, webhook) => ({ ...object, [webhook.id]: webhook }), {});

  if (entries === undefined || (unique_webhook_ids.length > 0 && webhooks === undefined)) {
    return (
      <Div w={100} p={{ y: 12 }} flex={{ justify: 'center ' }}>
        <Loading />
      </Div>
    );
  }

  if (entries && count === 0) {
    return <BookmarksListEmptyView />;
  }

  const onRowSelected = (id) => {
    return id
      ? id.indexOf('evt_') === 0
        ? onEventSelected(id)
        : onBookmarkSelected(id)
      : onBookmarkSelected(null);
  };

  const headers = ['Bookmark Label', 'Connection', 'Last used'];
  const widths = [{ min: 280, max: 280 }, { min: 120 }, { min: 288, max: 288 }];

  const rows = entries?.reduce((rows, entry) => {
    const webhook = webhooks_by_id ? webhooks_by_id[entry?.webhook_id] : undefined;
    return [
      ...rows,
      {
        id: entry.id,
        selected: selected_id === entry.id && !selected_event_id,
        highlighted: selected_id === entry.id,
        outdated: false,
        expendable: true,
        fields: [
          entry.label,
          webhook ? (
            <LabelButton
              to={`/connections/${webhook.id}`}
              monospace
              neutral
              label={webhook.full_name}
              small
            />
          ) : (
            <Text monospace size="s" muted>
              Connections deleted
            </Text>
          ),
          <Div
            key={`${entry.id}-next-attempt`}
            flex={{ gap: 2, justify: 'space-between', align: 'center' }}>
            {!entry.last_used_at ? (
              <Text size="s">–</Text>
            ) : (
              <DisplayDate date={entry.last_used_at} />
            )}
            <Button.Permission
              role="member"
              small={{ text: 's' }}
              minimal
              primary
              icon="replay"
              m={{ y: -1.5 }}
              onClick={(e) => {
                e.stopPropagation();
                onReplay(entry);
              }}>
              Replay on {view.toUpperCase()}
            </Button.Permission>
          </Div>,
        ],
      },
      ...(selected_id !== entry.id || !events
        ? []
        : [
            {
              headers: ['Status', 'Connection', 'Timestamp'],
              rows:
                events.models.length === 0
                  ? [
                      {
                        id: 'none',
                        highlighted: true,
                        selectable: false,
                        element: (
                          <Div p={{ x: 5, y: 1.5 }}>
                            <Text muted>
                              No events associated with this bookmark in your retention window.
                            </Text>
                          </Div>
                        ),
                      },
                    ]
                  : events.models.map((event) => ({
                      id: event.id,
                      selected: selected_event_id === event.id,
                      highlighted: selected_id === entry.id,
                      fields: [
                        <Status small key={event.id} {...event} />,
                        webhooks_by_id![event.webhook_id] ? (
                          <LabelButton
                            to={`/connections/${event.webhook_id}`}
                            monospace
                            neutral
                            small
                            label={webhooks_by_id![event.webhook_id].full_name}
                          />
                        ) : (
                          <Text monospace size="s" muted>
                            Connections deleted
                          </Text>
                        ),
                        <DisplayDate key={event.id} date={event.created_at} />,
                      ],
                    })),
            },
          ]),
    ];
  }, []);

  return (
    <Table
      fill
      headers={headers}
      widths={widths}
      rows={rows}
      onRowSelected={onRowSelected}
      onNextPage={
        bookmarks?.pagination.next
          ? () => onFilterChanged({ next: bookmarks.pagination.next, prev: undefined })
          : undefined
      }
      onPreviousPage={
        bookmarks?.pagination.prev
          ? () => onFilterChanged({ prev: bookmarks.pagination.prev, next: undefined })
          : undefined
      }
      current_count={count}
    />
  );
};

export default BookmarksList;
