export const cleanseFormErrorObject = (obj: object | object[]): object | object[] => {
  const cleaned_obj: object | object[] = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    // If the value is an object or array, recursively cleanse it.
    if (typeof obj[key] === 'object') {
      const result = cleanseFormErrorObject(obj[key]);

      // If the result is an array, only keep it if there's at least one object with a key that contains a string
      if (Array.isArray(result)) {
        cleaned_obj[key] = result;
      }
      // If the result is an object with at least one key, add it to the cleaned_obj.
      else if (!Array.isArray(result) && Object.keys(result).length > 0) {
        cleaned_obj[key] = result;
      }
    }
    // If the value is a string, add it to the cleaned_obj.
    else if (typeof obj[key] === 'string') {
      cleaned_obj[key] = obj[key];
    }
  }

  return cleaned_obj;
};
