import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from '../components/common/helpers/ErrorBoundary';
import DashboardScene from '../components/scenes/DashboardScene';

const Dashboard = () => {
  const [is_client, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  return (
    is_client && (
      <BrowserRouter basename="/">
        <ErrorBoundary>
          <DashboardScene />
        </ErrorBoundary>
      </BrowserRouter>
    )
  );
};

Dashboard.configs = {
  title: 'Dashboard',
  desc: 'Manage, monitor and test your events',
  disable_tracking: true,
};

export default Dashboard;
