import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import useSWR from 'swr';

import { organization_levels_by_role } from '../../../../../../../../domain/roles';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { showChat } from '../../../../../utils/liveChat';
import Badge from '../../../../common/base/Badge';
import Button, { PlaceholderButton } from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Skeleton from '../../../../common/base/Skeleton';
import Text from '../../../../common/base/Text';
import CopyableField from '../../../../common/CopyableField';
import { useDialog } from '../../../../common/Dialog';
import Checkbox from '../../../../common/Form/Fields/Checkbox';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UserContext } from '../../../../contexts/UserContext';
import { DashboardContext } from '../../DashboardContext';
import TeamNotifications from './TeamNotifications';
import LINKS from '../../../../../configs/links';

const TeamSettings: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const { organization, team, mutateTeam, resetContext } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const [deleting, setDeleting] = useState(false);

  const {
    data: custom_domains,
    mutate,
    isValidating,
  } = useSWR(
    team && APIMethodKeys.teams.listCustomDomains(team.id),
    () => HookdeckAPI.teams.listCustomDomains(),
    {
      refreshInterval(latest) {
        if (latest?.some((domain) => domain?.ssl.status === 'initializing')) {
          return 5 * 1000;
        }
        return 0;
      },
    },
  );

  const handleSubmit = (values) => {
    return HookdeckAPI.teams
      .update(values)
      .then((team) => {
        addToast('success', 'Project updated');
        mutateTeam(team);
        return true;
      })
      .catch(() => {
        addToast('error', 'Failed to update project');
      });
  };

  const showDomainInput = () =>
    showDialog(
      (values: { hostname: string }) => {
        return HookdeckAPI.teams
          .createCustomDomain(values.hostname)
          .then(() => {
            addToast('success', 'The domain added, complete the DNS configurations');
            mutate();
          })
          .catch((e) => {
            // TODO: Show those errors inside the form
            if (e.response?.code === 'CUSTOM_DOMAIN_INVALID') {
              addToast('error', 'The domain entered is not a valid hostname.');
            }
            if (e.response?.code === 'CUSTOM_DOMAIN_ALREADY_EXISTS') {
              addToast('error', 'The domain entered is already in use.');
            }
            throw e;
          });
      },
      undefined,
      {
        title: 'Set a custom domain',
        submit_label: 'Configure',
        cancel_label: 'Cancel',
        form_props: {
          initial_values: {
            hostname: '',
          },
          validate: ({ hostname }: { hostname: string }) => {
            if (!hostname || hostname.length < 0) return { hostname: 'Required' };
            if (
              !/^([a-z0-9]([a-z0-9\-]{0,61}[a-z0-9])?\.)+[a-z0-9\-]{2,61}\.[a-z]{2,}$/.test(
                hostname,
              )
            ) {
              return { hostname: 'Invalid hostname. Hostname must contain a subdomain.' };
            }
            return {};
          },
          Fields: () => (
            <TextInput
              label="Domain"
              name="hostname"
              help="Valid domain where a TXT & CNAME DNS record will need to be configured."
              required
            />
          ),
        },
      },
    );

  const onDomainRefresh = () => {
    mutate();
  };

  const onDomainDeleted = (domain_id: string) => {
    showDialog(
      () => {
        setDeleting(true);
        return HookdeckAPI.teams
          .deleteCustomDomain(domain_id)
          .then(() => {
            addToast('success', 'Domain removed');
            mutate((domains = []) => domains.filter((d) => d.id !== domain_id));
          })
          .catch(() => {
            addToast('error', 'The domain could not be removed, try again later or contact us.');
          })
          .finally(() => {
            setDeleting(false);
          });
      },
      undefined,
      {
        danger: true,
        message: `Are you sure you want to remove the domain? All URLs using this domain will stop working.`,
      },
    );
  };

  const onTransfer = () =>
    showDialog(
      (values: { organization_id: string }) => {
        return HookdeckAPI.teams
          .transfer(values.organization_id)
          .then(() => {
            resetContext();
          })
          .catch(() => {
            addToast('error', 'The project could not be tranfered, please contact us.');
          });
      },
      undefined,
      {
        title: 'Transfer project',
        submit_label: 'Transfer project',
        cancel_label: 'Cancel',
        form_props: {
          initial_values: {
            organization_id: '',
          },
          validate: ({ organization_id }: { organization_id: string }) => {
            if (!organization_id) return { organization_id: 'Required' };
            return {};
          },
          Fields: () => {
            const { HookdeckAPI } = useContext(GlobalContext);
            const { data: organizations } = useSWR(APIMethodKeys.organizations.listAll(), () =>
              HookdeckAPI.organizations.listAll(),
            );
            return (
              <>
                <Text m={{ b: 4 }}>
                  Admins and project admins are able to transfer projects to organizations where
                  they are an admin. Projects maintain their access status (Open or Private) in the
                  organization they are transferred to.
                </Text>
                <Text heading m={{ b: 4 }}>
                  Transfer considerations:
                </Text>
                <Div flex={{ align: 'flex-start' }} m={{ b: 4 }}>
                  <Icon icon="lock" left m={{ t: 0.5 }} />
                  <Div>
                    <Text subtitle>Project access</Text>
                    <Text muted>
                      Project access is not maintained when transferring a project to an
                      organization. If the project is private all members will lose access and will
                      need to be re-added
                    </Text>
                  </Div>
                </Div>
                <Div flex={{ align: 'flex-start' }} m={{ b: 4 }}>
                  <Icon icon="construction" left m={{ t: 0.5 }} />
                  <Div>
                    <Text subtitle>Features</Text>
                    <Text muted>
                      Projects inherit features from their parent organizations. If you move this
                      project to an organization with a lesser plan, you may lose feature access.
                    </Text>
                  </Div>
                </Div>
                <Div flex={{ align: 'flex-start' }} m={{ b: 4 }}>
                  <Icon icon="progress_activity" left m={{ t: 0.5 }} />
                  <Div>
                    <Text subtitle>No downtime</Text>
                    <Text muted>
                      You won’t experience any downtime when transferring a project.
                    </Text>
                  </Div>
                </Div>
                <Div flex={{ align: 'flex-start' }} m={{ b: 4 }}>
                  <Icon icon="rate_limit" left m={{ t: 0.5 }} />
                  <Div>
                    <Text subtitle>Throughput transfer</Text>
                    <Text muted>
                      If the target organization is on a paid plan, the project’s throughput and fee
                      will be transfered to the new organization. If the organization is on a free
                      plan, the project throughput will be reset to the free plan limits.
                    </Text>
                  </Div>
                </Div>
                <Divider m={{ y: 6 }} />
                <SelectInput
                  block
                  label="Target organization"
                  help="The organization you’d like your project transferred to."
                  placeholder="Select target organization"
                  name="organization_id"
                  flex={{ grow: true, direction: 'column' }}
                  search
                  dropdown={{
                    title: 'Organizations',
                    parent_width: true,
                    placement: 'bottom-end',
                  }}
                  options={
                    organizations
                      ?.filter(
                        (organization) =>
                          organization_levels_by_role[organization.role] >=
                            organization_levels_by_role.admin &&
                          organization.id !== team!.organization_id,
                      )
                      .map((organization) => ({
                        label: organization.name,
                        value: organization.id,
                      })) || []
                  }
                />
              </>
            );
          },
        },
      },
    );

  const onDelete = () =>
    showDialog(
      () => {
        return HookdeckAPI.teams
          .delete()
          .then(() => {
            resetContext();
          })
          .catch(() => {
            addToast('error', 'The project could not be deleted, please contact us.');
          });
      },
      undefined,
      {
        title: 'Delete project',
        submit_label: 'Delete project',
        cancel_label: 'Cancel',
        submit_icon: 'delete',
        danger: true,
        form_props: {
          initial_values: {
            name: '',
          },
          validate: ({ name }: { name: string }) => {
            if (!name || name.length < 0) return { name: 'Required' };
            if (name !== team!.name) {
              return { name: 'Project name does not match.' };
            }
            return {};
          },
          Fields: () => (
            <>
              <Text m={{ b: 4 }}>
                This action can’t be undone. You’ll lose all data associated with this project,
                including connection configuration, routing logic, and event history. Are you sure?{' '}
                <br />
                <br />
                If you’d like to delete this project, type <strong>{team!.name}</strong> below to
                confirm.
              </Text>
              <TextInput m={0} name="name" placeholder={team!.name} required />
            </>
          ),
        },
      },
    );

  return (
    <>
      <Div m={{ b: 14 }}>
        <Formik
          initialValues={{
            name: team!.name,
          }}
          validate={(values) => {
            const errors: Partial<typeof values> = {};
            if (!values.name || values.name.length === 0) {
              errors.name = 'Required';
            }
            if (values.name && values.name.length > 40) {
              errors.name = 'Name is longer then 40 characters';
            }
            return errors;
          }}
          onSubmit={handleSubmit}>
          {(props) => (
            <Form>
              <Text heading as="h2" m={{ b: 2 }}>
                Project Name
              </Text>
              <Divider m={{ b: 4 }} />
              <TextInput name="name" required m={{ b: 4 }} />
              <Button.Permission
                outline
                disabled={!props.isValid || !props.touched || props.isSubmitting}
                primary
                submit
                icon={props.isSubmitting ? 'loading' : 'save'}>
                Save
              </Button.Permission>
            </Form>
          )}
        </Formik>
      </Div>
      <Div m={{ b: 14 }}>
        <Text heading as="h2" m={{ b: 0 }}>
          Custom Domain
        </Text>
        <Text muted m={{ b: 2 }}>
          Use your own domain as a URL for receiving requests.
        </Text>
        <Divider m={{ b: 4 }} />
        {!custom_domains && <Skeleton w={100} h={{ px: 38 }} />}
        {custom_domains && custom_domains.length === 0 && (
          <Div flex={{ align: 'center' }}>
            <PlaceholderButton.Permission onClick={showDomainInput}>
              Configure Domain
            </PlaceholderButton.Permission>
          </Div>
        )}
        {custom_domains &&
          custom_domains.length > 0 &&
          custom_domains.map((domain) => (
            <StyledCard key={domain.id}>
              <StyledCardSection
                p={{ y: 1, l: 4, r: 1 }}
                flex={{ justify: 'space-between', align: 'center' }}>
                <Text subtitle flex={{ align: 'center' }}>
                  {domain.hostname}
                  {domain.status === 'active' ? (
                    <Badge m={{ l: 2 }} success icon="success_circle" subtle>
                      Domain
                    </Badge>
                  ) : (
                    <Badge m={{ l: 2 }} primary icon="info" subtle>
                      Domain – Pending
                    </Badge>
                  )}
                  {domain.ssl.status === 'active' ? (
                    <Badge m={{ l: 2 }} success icon="success_circle" subtle>
                      SSL Certificate
                    </Badge>
                  ) : (
                    <Badge m={{ l: 2 }} primary icon="info" subtle>
                      SSL Certificate – Pending
                    </Badge>
                  )}
                </Text>
                <Div flex={{ align: 'center' }}>
                  <Button
                    minimal
                    icon={isValidating ? 'loading' : 'retry'}
                    disabled={isValidating}
                    onClick={onDomainRefresh}
                  />
                  <Button.Permission
                    minimal
                    icon={deleting ? 'loading' : 'delete'}
                    disabled={deleting}
                    onClick={() => onDomainDeleted(domain.id)}
                  />
                </Div>
              </StyledCardSection>
              {domain.status === 'pending' && domain.ssl.status === 'initializing' && (
                <StyledCardSection p={{ y: 3, x: 4 }}>
                  <Text muted>
                    Domain and SSL certificate initializing, this may take a few minutes...
                  </Text>
                </StyledCardSection>
              )}
              {domain.status !== 'active' && (
                <StyledCardSection p={4}>
                  <Text as={'p'}>
                    Copy and paste the below TXT record in your DNS settings
                    <Text danger as="span">
                      *
                    </Text>
                  </Text>
                  <Text subtitle size="s" m={{ b: 1, t: 3 }}>
                    TXT Name
                  </Text>
                  <CopyableField monospace value={domain.ssl.txt_name} />
                  <Text subtitle size="s" m={{ b: 1, t: 3 }}>
                    TXT Value
                  </Text>
                  <CopyableField monospace value={domain.ssl.txt_value} />
                </StyledCardSection>
              )}
              {domain.ssl.status !== 'active' && (
                <StyledCardSection p={4}>
                  <Text as={'p'}>
                    Copy and paste the below CNAME record in your DNS settings
                    <Text danger as="span">
                      *
                    </Text>
                  </Text>
                  <Text subtitle size="s" m={{ b: 1, t: 3 }}>
                    CNAME key
                  </Text>
                  <CopyableField monospace value={domain.hostname} />
                  <Text subtitle size="s" m={{ b: 1, t: 3 }}>
                    CNAME value
                  </Text>
                  <CopyableField monospace value={'hkdk.events'} />
                </StyledCardSection>
              )}
            </StyledCard>
          ))}
      </Div>
      <Div m={{ b: 14 }}>
        <Text heading as="h2" m={{ b: 2 }}>
          Delivery
        </Text>
        <Divider m={{ b: 4 }} />
        <Div flex={{ justify: 'space-between' }} m={{ b: 4 }}>
          <Checkbox
            disabled
            name="static_ip_proxy"
            checked={team!.static_ip_proxy}
            onChange={() => null}
            label="Enable delivery from Static IPs"
          />
          <Link as="button" onClick={showChat}>
            Contact us to {team!.static_ip_proxy ? 'disable' : 'enable'} {'->'}
          </Link>
        </Div>
        <Formik
          initialValues={{
            headers_prefix: team!.headers_prefix || '',
            enable_headers_prefix: !!team!.headers_prefix,
          }}
          validate={(values) => {
            const errors: Partial<typeof values> = {};
            if (values.enable_headers_prefix && !values.headers_prefix) {
              errors.headers_prefix = 'Required';
            }
            return errors;
          }}
          onSubmit={(values) =>
            handleSubmit({
              headers_prefix: values.enable_headers_prefix ? values.headers_prefix || null : null,
            })
          }>
          {(props) => (
            <Form>
              <StyledCard m={{ b: 4 }}>
                <StyledCardSection flex={{ justify: 'space-between' }} p={4}>
                  <Div>
                    <Text>Use custom HTTP headers prefix</Text>
                    <Text muted>White-label the default "X-Hookdeck" headers prefix.</Text>
                  </Div>
                  <SwitchInput show_label name="enable_headers_prefix" />
                </StyledCardSection>
                {props.values.enable_headers_prefix && (
                  <>
                    <StyledCardSection p={4}>
                      <TextInput
                        m={0}
                        label="Headers Prefix"
                        name="headers_prefix"
                        help={`Headers will start with ${
                          props.values.headers_prefix.toLowerCase() || 'x-hookdeck'
                        }. ie: ${props.values.headers_prefix.toLowerCase() || 'x-hookdeck'}-eventid`}
                      />
                    </StyledCardSection>
                    <StyledCardSection p={4}>
                      <Text flex={{ justify: 'space-between' }}>
                        White-labeling disables some headers that shouldn't be public-facing.{' '}
                        <Link href={LINKS.product_docs.projects}>Learn&nbsp;more {'->'}</Link>
                      </Text>
                    </StyledCardSection>
                  </>
                )}
              </StyledCard>
              <Button.Permission
                outline
                disabled={!props.isValid || !props.touched || props.isSubmitting}
                primary
                submit
                icon={props.isSubmitting ? 'loading' : 'save'}>
                Save
              </Button.Permission>
            </Form>
          )}
        </Formik>
      </Div>
      <Div m={{ b: 14 }}>
        <TeamNotifications />
      </Div>
      {!organization!.workos_connection_id &&
        !organization!.workos_directory_id &&
        !user?.sso_idp_id && (
          <Div m={{ b: 14 }}>
            <Text heading as="h2" m={{ b: 0 }}>
              Transfer Project
            </Text>
            <Text m={{ b: 2 }} muted>
              Transfer your project and all associated data to another organization.
            </Text>
            <Divider m={{ b: 4 }} />
            <Div flex={{ justify: 'space-between' }} m={{ b: 14 }}>
              <Button.Permission role="admin" icon="transfer" outline onClick={onTransfer}>
                Transfer project
              </Button.Permission>
            </Div>
          </Div>
        )}
      {!team!.directory_idp_id && (
        <StyledCard m={{ b: 14 }}>
          <StyledCardSection p={{ y: 3, x: 4 }}>
            <Text heading danger>
              Danger Zone
            </Text>
          </StyledCardSection>
          <StyledCardSection p={4} flex={{ justify: 'space-between', align: 'center' }}>
            <Div>
              <Text heading as="h2" m={0}>
                Delete Project
              </Text>
              <Text muted>Irreversibly deletes this project and all associated data.</Text>
            </Div>
            <Button.Permission role="admin" m={{ l: 4 }} danger icon="delete" onClick={onDelete}>
              Delete
            </Button.Permission>
          </StyledCardSection>
        </StyledCard>
      )}
    </>
  );
};

export default TeamSettings;
