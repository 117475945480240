import { useField } from 'formik';

import { GridUnit } from '@hookdeck/theme';

import { Source } from '../../../../../../../../typings/Source.interface';
import integration_schemas from '../../../../../configs/integration-schemas';
import { fieldName } from '../../../../../utils';
import { ClickableArea } from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import CheckboxInput from '../../../../common/Form/Fields/CheckboxInput';
import EditorInput from '../../../../common/Form/Fields/EditorInput';
import ItemListPickerInput from '../../../../common/Form/Fields/ItemListPicker';
import SelectInput from '../../../../common/Form/Fields/SelectInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import Alert from '../../../../common/base/Alert';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import TextAreaInput from '../../../../common/Form/Fields/TextAreaInput';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import DropdownMenu from '../../../../common/DropdownMenu';
import LINKS from '../../../../../configs/links';

type ErrorOfFormValue<T> = {
  [P in keyof T]?: T[P] extends object ? ErrorOfFormValue<T[P]> : string;
};

type CustomResponseValue = {
  content_type: 'json' | 'text' | 'xml';
  body: string;
};

export interface SourceConfigurationFormValues {
  show_advanced: boolean;
  enable_verification: boolean;
  enable_custom_response: boolean;
  allowed_http_methods: {
    GET: boolean;
    POST: boolean;
    PUT: boolean;
    PATCH: boolean;
    DELETE: boolean;
  };
  verification:
    | {
        type: keyof typeof integration_schemas;
        configs: { [key: string]: string };
      }
    | {};
  custom_response: CustomResponseValue;
}

const default_allowed_http_methods = {
  GET: false,
  POST: true,
  PUT: true,
  PATCH: true,
  DELETE: true,
};

const source_configuration_form_props = {
  postprocessValues: (values: SourceConfigurationFormValues) => {
    if (
      values.enable_verification &&
      'type' in values.verification &&
      values.verification.configs === undefined
    ) {
      values.verification.configs = {};
    }
    return {
      allowed_http_methods: Object.entries(values.allowed_http_methods)
        .filter(([, value]) => !!value)
        .map(([key]) => key),
      custom_response: values.enable_custom_response ? values.custom_response : null,
      verification:
        values.enable_verification && 'type' in values.verification ? values.verification : null,
    };
  },
  getInitialValues: (source?: Source): SourceConfigurationFormValues =>
    source
      ? {
          show_advanced: source.custom_response ? true : false,
          enable_verification: !!source.verification,
          verification: source.verification
            ? {
                type: source.verification.type.toUpperCase(),
                configs: source.verification.configs,
              }
            : {},
          allowed_http_methods: {
            GET: source.allowed_http_methods.includes('GET') ?? false,
            POST: source.allowed_http_methods.includes('POST') ?? false,
            PUT: source.allowed_http_methods.includes('PUT') ?? false,
            PATCH: source.allowed_http_methods.includes('PATCH') ?? false,
            DELETE: source.allowed_http_methods.includes('DELETE') ?? false,
          },
          enable_custom_response: !!source.custom_response,
          custom_response: source.custom_response || {
            content_type: 'json',
            body: '',
          },
        }
      : {
          show_advanced: false,
          enable_verification: false,
          verification: {},
          allowed_http_methods: default_allowed_http_methods,
          enable_custom_response: false,
          custom_response: {
            content_type: 'json',
            body: '',
          },
        },
  validate: async (values: SourceConfigurationFormValues) => {
    const errors: ErrorOfFormValue<SourceConfigurationFormValues> = {};
    if (values.enable_verification) {
      if ('type' in values.verification) {
        errors.verification = {};
        if (!values.verification.type) {
          (errors.verification as any).type = 'Required';
        } else if ('configs' in values.verification) {
          integration_schemas[values.verification.type.toUpperCase()]?.fields
            .filter((field) => field.features?.includes('VERIFICATION'))
            .forEach((field) => {
              if (field.required && !(values.verification as any).configs[field.name]) {
                (errors.verification as any).configs[field.name] = 'Required';
              }
            });
        }
      }
    }
    if (values.enable_custom_response) {
      if (values.custom_response.content_type === 'json') {
        try {
          JSON.parse(values.custom_response.body);
        } catch (e) {
          errors.custom_response = {
            body: 'Invalid JSON',
          };
        }
      }
    }
    return cleanseFormErrorObject(errors);
  },
  Fields: ({ prefix, show_all }: { prefix: string; show_all?: boolean }) => {
    const [{ value: show_advanced }, , { setValue: setShowAdvanced }] = useField<boolean>(
      fieldName('show_advanced', prefix),
    );
    const [{ value: enable_custom_response }] = useField<boolean>(
      fieldName('enable_custom_response', prefix),
    );
    const [{ value: custom_response }] = useField<CustomResponseValue>(
      fieldName('custom_response', prefix),
    );
    const [{ value: custom_response_content_type }, , { setValue: setCustomResponseContentType }] =
      useField<'text' | 'json' | 'xml'>(fieldName('custom_response.content_type', prefix));

    const [{ value: enable_verification }] = useField<boolean>(
      fieldName('enable_verification', prefix),
    );
    const [{ value: verification_type }] = useField<keyof typeof integration_schemas>(
      fieldName('verification.type', prefix),
    );

    const verification_features =
      verification_type &&
      integration_schemas[verification_type]?.fields.filter(
        (field) =>
          !field.features ||
          field.features?.includes('VERIFICATION') ||
          field.features?.includes('HANDSHAKE'),
      );

    const advanced_content = (
      <>
        <Div p={{ x: show_all ? 0 : 4, y: 2 }}>
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <Text subtitle size="s" flex={{ align: 'center' }}>
              Source Authentication
              <Tooltip
                tooltip="Enable authentication to verify the authenticity of the request made to Hookdeck. A request that fails verification will be logged, but not delivered."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.sources_add_auth,
                }}>
                <Icon muted right pointer icon="info" />
              </Tooltip>
            </Text>
            <SwitchInput name={fieldName('enable_verification', prefix)} />
          </Div>
          {enable_verification && (
            <StyledCard m={{ t: 2 }}>
              <StyledCardSection>
                <ItemListPickerInput
                  search_placeholder="Search for an authentication method..."
                  name={fieldName('verification.type', prefix)}
                  categories={[
                    { key: 'generic', label: 'Generic' },
                    { key: 'platform', label: 'Platform' },
                  ]}
                  getItemsKey={(search_team, category) =>
                    `verification-methods-${search_team}-${category}`
                  }
                  getItems={(search_term, category) =>
                    Promise.resolve(
                      Object.entries(integration_schemas)
                        .filter(([, config]) => {
                          if (
                            search_term &&
                            !config.label.toLowerCase().includes(search_term.toLowerCase())
                          ) {
                            return false;
                          }
                          if (
                            (category && category === 'generic' && !config.generic) ||
                            (category === 'platform' && config.generic)
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map(([key, config]) => ({
                          id: key,
                          icon: 'verification' as const,
                          label: config.label,
                          category: config.generic ? 'Generic' : 'Platform',
                        }))
                        .sort((a, b) => (a.label > b.label ? 1 : -1)),
                    )
                  }
                />
              </StyledCardSection>
              {verification_features && (
                <StyledCardSection p={{ x: 3, b: 3, t: 1 }}>
                  {verification_features.length === 0 && (
                    <Alert inline bare m={{ t: 2 }}>
                      You're all set! No additional input is required for this verification method.
                    </Alert>
                  )}
                  {verification_features.length > 0 && (
                    <Div flex={{ wrap: true }}>
                      {verification_features.map((field, i) => {
                        switch (field.type) {
                          case 'select':
                            return (
                              <GridUnit
                                size={verification_features.length === 1 ? 1 : 1 / 2}
                                key={field.name}>
                                <SelectInput
                                  m={
                                    verification_features.length === 1
                                      ? { t: 2 }
                                      : {
                                          r: i % 2 === 0 ? 2 : 0,
                                          l: i % 2 !== 0 ? 2 : 0,
                                          t: 2,
                                        }
                                  }
                                  block
                                  label={field.label}
                                  name={fieldName(`verification.configs.${field.name}`, prefix)}
                                  options={field.options ?? []}
                                  required={field.required === false ? false : true}
                                />
                              </GridUnit>
                            );
                          case 'textarea':
                            return (
                              <GridUnit size={1} key={field.name}>
                                <TextAreaInput
                                  m={
                                    verification_features.length === 1
                                      ? { t: 2 }
                                      : {
                                          r: i % 2 === 0 ? 2 : 0,
                                          l: i % 2 !== 0 ? 2 : 0,
                                          t: 2,
                                        }
                                  }
                                  monospace={true}
                                  label={field.label}
                                  placeholder={field.placeholder}
                                  default_value={''}
                                  name={fieldName(`verification.configs.${field.name}`, prefix)}
                                  required={field.required === false ? false : true}
                                />
                              </GridUnit>
                            );
                          default:
                            return (
                              <GridUnit
                                size={verification_features.length === 1 ? 1 : 1 / 2}
                                key={field.name}>
                                <TextInput
                                  m={
                                    verification_features.length === 1
                                      ? { t: 2 }
                                      : {
                                          r: i % 2 === 0 ? 2 : 0,
                                          l: i % 2 !== 0 ? 2 : 0,
                                          t: 2,
                                        }
                                  }
                                  label={field.label}
                                  type={field.type}
                                  placeholder={field.placeholder}
                                  default_value={''}
                                  name={fieldName(`verification.configs.${field.name}`, prefix)}
                                  required={field.required === false ? false : true}
                                />
                              </GridUnit>
                            );
                        }
                      })}
                    </Div>
                  )}
                </StyledCardSection>
              )}
            </StyledCard>
          )}
        </Div>
        <Div p={{ x: show_all ? 0 : 4, b: 2, t: 1 }}>
          <Div
            flex={{ justify: 'space-between', align: 'center' }}
            p={{ t: enable_custom_response ? 0 : 1 }}>
            <Text size="s" subtitle flex={{ align: 'center' }}>
              Customize Response
              <Tooltip
                tooltip="Override the default Hookdeck HTTP response with a custom static response in JSON, XML or TXT"
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.sources_customize_response,
                }}>
                <Icon muted right small pointer icon="info" />
              </Tooltip>
            </Text>
            <Div flex={{ gap: 2, align: 'center' }}>
              {enable_custom_response && (
                <DropdownMenu
                  minimal
                  small
                  label={custom_response_content_type.toUpperCase()}
                  options={[
                    { label: 'JSON', onClick: () => setCustomResponseContentType('json') },
                    {
                      label: 'TEXT',
                      onClick: () => setCustomResponseContentType('text'),
                    },
                    {
                      label: 'XML',
                      onClick: () => setCustomResponseContentType('xml'),
                    },
                  ]}
                />
              )}
              <SwitchInput name={fieldName('enable_custom_response', prefix)} />
            </Div>
          </Div>
          {enable_custom_response && (
            <StyledCard overflow_hidden m={{ t: 1 }}>
              <EditorInput
                name={fieldName('custom_response.body', prefix)}
                height="164px"
                language={custom_response.content_type === 'json' ? 'json' : 'text'}
              />
            </StyledCard>
          )}
        </Div>
        <Div p={{ x: show_all ? 0 : 4, y: 2 }}>
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <Text size="s" subtitle flex={{ align: 'center' }} m={{ b: 2 }}>
              HTTP Methods
              <Tooltip
                tooltip="Allow only specific HTTP methods to be accepted by Hookdeck. Requests that don't match the allowed HTTP will be logged."
                cta={{
                  label: 'Learn more',
                  to: LINKS.product_docs.sources_custom_methods,
                }}>
                <Icon muted right pointer icon="info" />
              </Tooltip>
            </Text>
            <Div flex={{ gap: 2 }}>
              {['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].map((method) => (
                <StyledCard p={{ x: 2, y: 1 }} key={method}>
                  <CheckboxInput
                    m={0}
                    label={method}
                    name={fieldName(`allowed_http_methods.${method}`, prefix)}
                  />
                </StyledCard>
              ))}
            </Div>
          </Div>
        </Div>
      </>
    );

    if (show_all) {
      return advanced_content;
    }
    return (
      <>
        <StyledCard overflow_hidden raised>
          <StyledCardSection muted={show_advanced}>
            <ClickableArea
              p={{ y: 2, x: 3 }}
              flex={{ justify: 'space-between', align: 'center' }}
              onClick={() => setShowAdvanced(!show_advanced)}>
              <Text size="s" subtitle muted={!show_advanced}>
                Advanced Configuration
              </Text>
              <Icon muted icon={show_advanced ? 'expand_less' : 'expand_more'} />
            </ClickableArea>
          </StyledCardSection>
          {show_advanced && <StyledCardSection p={{ y: 2 }}>{advanced_content}</StyledCardSection>}
        </StyledCard>
      </>
    );
  },
};

export default source_configuration_form_props;
