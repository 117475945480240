import Text from '../base/Text';

const MetricNoData: React.FC = () => {
  return (
    <Text muted center>
      There is no data at this time for this metric.
    </Text>
  );
};

export default MetricNoData;
