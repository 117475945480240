import { useFormikContext } from 'formik';
import { Prompt } from 'react-router';

import Button from '../../../common/base/Button';
import Icon from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import { Div, StyledUtilsProps } from '../../../common/helpers/StyledUtils';

const SaveButton: React.FC<Pick<StyledUtilsProps, 'm'>> = ({ m }) => {
  const { isSubmitting, dirty, touched, initialValues, values } =
    useFormikContext<Record<string, any>>();
  const has_changes = dirty && Object.keys(touched).some((k) => initialValues[k] != values[k]);
  return (
    <Div m={m} flex={{ gap: 4, align: 'center' }}>
      <Prompt
        when={has_changes && !isSubmitting}
        message={(location) =>
          location.pathname != window.location.pathname
            ? 'Are you sure you want to quit without saving your work?'
            : true
        }
      />
      <Button.Permission
        primary
        outline
        disabled={isSubmitting}
        submit
        icon={isSubmitting ? 'loading' : 'save'}>
        Save
      </Button.Permission>
      {has_changes && (
        <Text muted size="m">
          <Icon icon="info" left />
          You have unsaved changes
        </Text>
      )}
    </Div>
  );
};

export default SaveButton;
