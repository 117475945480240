import { Source } from '../../../../../../../typings/Source.interface';
import { StyledCard } from '../../../common/base/Card';
import Icon from '../../../common/base/Icon';
import { Div } from '../../../common/helpers/StyledUtils';
import Tooltip from '../../../common/base/Tooltip';
import Text from '../../../common/base/Text';
import { ClickableArea, ClickableAreaProps } from '../../../common/base/Button';
import integration_schemas from '../../../../configs/integration-schemas';
import { Truncatable } from '../../../common/Truncatable';

interface Props extends Pick<ClickableAreaProps, 'to' | 'onClick'> {
  source: Source;
  action_button?: JSX.Element;
}

const SourceCard: React.FC<Props> = ({ source, onClick, to }) => {
  return (
    <StyledCard overflow_hidden>
      <ClickableArea
        p={{ x: 2, y: 1.5 }}
        flex={{ justify: 'space-between', align: 'center' }}
        onClick={onClick}
        to={to}>
        <Div flex={{ align: 'center', justify: 'space-between' }} w={100}>
          <Div flex={{ align: 'center' }} w={100}>
            <Icon icon="source" muted left />
            <Text w={100} ellipsis monospace muted={!!source.disabled_at}>
              <Truncatable text={source.name} />
            </Text>
          </Div>
          {source.disabled_at && (
            <Tooltip tooltip={`Disabled`} placement="bottom-end">
              <Icon muted icon="disable" right />
            </Tooltip>
          )}
          {source.verification && !source.disabled_at && (
            <Tooltip
              placement="bottom-end"
              tooltip={`${
                integration_schemas[source.verification.type.toUpperCase()].label
              } verification enabled`}>
              <Icon muted icon="verification" right />
            </Tooltip>
          )}
        </Div>
      </ClickableArea>
    </StyledCard>
  );
};

export default SourceCard;
