import { add, format } from 'date-fns';
import { useField } from 'formik';
import { useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';

import { EventListFiltersProps } from '../../../../../typings/EventList.interface';
import { RequestListFiltersProps } from '../../../../../typings/RequestList.interface';
import { CubeModel } from '../../../../../utils/formatCubeQuery';
import Button from '../../../../common/base/Button';
import { StyledCard } from '../../../../common/base/Card';
import { Div } from '../../../../common/helpers/StyledUtils';
import HistogramChart from './Chart';
import HistogramTimebar from './Timebar';
import { Resolution } from '../../../../../utils/chart';

const StyledSlider = styled.div`
  user-select: none;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  width: 100%;
`;

const StyledSliderStep = styled.div<{ active?: boolean }>(
  ({ theme, active }) => css`
    user-select: none;
    border-bottom: 1px solid
      ${active ? theme.colors.on.neutral.primary : theme.colors.outline.neutral};
    position: relative;
    height: 16px;
  `,
);

const StyledSliderHandleTime = styled.div<{
  active?: boolean;
  $end: boolean;
  direction: 'left' | 'right';
}>(
  ({ theme, active, $end, direction }) => css`
    cursor: grab;
    user-select: none;
    border: 1px solid ${active ? theme.colors.on.neutral.primary : theme.colors.outline.neutral};
    color: ${active ? theme.colors.on.neutral.primary : theme.colors.on.neutral.secondary_neutral};
    font-size: ${theme.pxToRem(12)};
    line-height: ${theme.pxToRem(18)};
    font-weight: 500;
    border-radius: ${theme.radius.small};
    background-color: ${theme.colors.surface.base.surface};
    padding: ${theme.spacing(0)} ${theme.spacing(1)};
    z-index: 1;
    width: fit-content;
    position: absolute;
    top: 30px;
    white-space: nowrap;
    ${direction === 'left'
      ? $end
        ? css`
            transform: translate(100%);
          `
        : css`
            transform: translate(-100%);
          `
      : $end
        ? css`
            right: 0;
          `
        : css`
            left: 0;
          `}
  `,
);

const StyledSliderHandle = styled.div<{ $end?: boolean; active?: boolean }>(
  ({ theme, $end, active }) => css`
    user-select: none;
    position: absolute;
    cursor: grab;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    z-index: 1;
    background-color: ${theme.colors.surface.base.surface};
    border: 1px solid ${active ? theme.colors.on.neutral.primary : theme.colors.outline.neutral};

    ${$end
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
    bottom: -8px;
  `,
);

const StyledSaveCard = styled(StyledCard)(
  ({ theme }) => css`
    position: absolute;
    left: 0;
    top: -60px;
    width: fit-content;
    z-index: 1;
    box-shadow: ${theme.elevation[3]};
  `,
);

const granularity_formating = {
  second: 'MMM d, h:mm:ss a',
  minute: 'MMM d, h:mm a',
  hour: 'MMM d, h:mm a',
  day: 'MMM d, h:mm a',
};

const HistogramRangePickerWrapper: React.FC<{
  model: CubeModel;
  dimention: string;
  filters: EventListFiltersProps | RequestListFiltersProps;
  start_date: Date;
  end_date: Date;
  start_name: string;
  end_name: string;
  chart_resolution: Resolution;
  axis_resolution: Resolution;
  refresh_key?: string;
  onApply?: () => void;
}> = ({
  model,
  dimention,
  filters,
  start_date,
  end_date,
  start_name,
  end_name,
  chart_resolution,
  axis_resolution,
  refresh_key,
  onApply,
}) => {
  const [{ value: timebar_start }, , { setValue: setTimebarStart }] = useField(start_name);
  const [{ value: timebar_end }, , { setValue: setTimebarEnd }] = useField(end_name);

  const ref = useRef<HTMLDivElement>(null);
  const drag_event = useRef<{
    from: 'start' | 'end' | null;
    rect_left: number;
    start_step: number;
    step_width: number;
  } | null>(null);
  const [dragging, setDragging] = useState<'start' | 'end' | null>(null);
  const apply_button_ref = useRef<HTMLDivElement>(null);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const element = document.querySelector('#main');
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: element as any,
          mainAxis: true,
          altAxis: false,
          padding: 8,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, -112],
        },
      },
    ],
  });

  const onMove = useCallback(
    (event) => {
      if (!drag_event.current) {
        return;
      }
      const { rect_left, from, step_width, start_step } = drag_event.current;
      const round = from === 'end' ? Math.ceil : Math.floor;
      const current_step = Math.min(
        Math.max(round((event.clientX - rect_left) / step_width), 0),
        chart_resolution.steps - 1,
      );

      if (from) {
        if (from === 'end') {
          if (current_step >= timebar_start) {
            setTimebarEnd(current_step);
          }
        } else {
          if (current_step <= timebar_end) {
            setTimebarStart(current_step);
          }
        }
      } else {
        const direction = current_step > start_step ? 'right' : 'left';

        if (current_step !== start_step) {
          if (direction === 'right') {
            if (timebar_start !== start_step) {
              setTimebarStart(start_step);
            }
            if (timebar_end !== current_step) {
              setTimebarEnd(current_step);
            }
          } else {
            if (timebar_start !== current_step) {
              setTimebarStart(current_step);
            }
            if (timebar_end !== start_step) {
              setTimebarEnd(start_step);
            }
          }
        }
      }
    },
    [chart_resolution.steps, timebar_start, timebar_end],
  );

  const onStart = useCallback(
    (event, from?: 'start' | 'end') => {
      const rect = ref.current!.getBoundingClientRect();
      const step_width = ref.current!.offsetWidth / chart_resolution.steps;
      const start_step = from
        ? from === 'start'
          ? timebar_start
          : timebar_end
        : Math.min(
            Math.max(Math.floor((event.clientX - rect.left) / step_width), 0),
            chart_resolution.steps,
          );

      drag_event.current = {
        from: from ? from : null,
        rect_left: rect.left,
        start_step: start_step,
        step_width: step_width,
      };
      setDragging(from ?? null);
      window.addEventListener('mousemove', onMove);
      window.addEventListener('mouseup', onEnd);
    },
    [chart_resolution.steps, timebar_end, timebar_start],
  );

  const onEnd = (event?: MouseEvent) => {
    if (!drag_event.current) {
      return null;
    }
    /* Event is null when clicking the cancel button */
    if (event) {
      const round = drag_event.current.from === 'end' ? Math.ceil : Math.floor;
      const current_step = Math.min(
        Math.max(
          round((event.clientX - drag_event.current.rect_left) / drag_event.current.step_width),
          0,
        ),
        chart_resolution.steps - 1,
      );
      if (current_step === drag_event.current.start_step) {
        setTimebarStart(drag_event.current.start_step);
        setTimebarEnd(drag_event.current.start_step);
      }
    }
    if (apply_button_ref.current) {
      apply_button_ref.current.focus();
    }
    setDragging(null);
    drag_event.current = null;
    document.body.style.cursor = 'unset';
    window.removeEventListener('mousemove', onMove);
    window.removeEventListener('mouseup', onMove);
  };

  const curriedOnStart = useCallback(
    (from: 'start' | 'end') => (event) => onStart(event, from),
    [onStart],
  );

  const range_selected = timebar_start !== 0 || timebar_end !== chart_resolution.steps - 1;
  const start_label_direction = timebar_start > 10 ? 'left' : 'right';
  const end_label_direction = timebar_end > chart_resolution.steps - 10 ? 'right' : 'left';

  const midpoint_step = Math.floor((timebar_start + timebar_end) / 2);

  return (
    <>
      {dragging && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999999,
            cursor: dragging ? 'grabbing' : 'ew-resize',
          }}
        />
      )}
      <div ref={ref} onMouseDown={onStart}>
        <HistogramChart
          model={model}
          dimention={dimention}
          filters={filters}
          start_date={start_date}
          end_date={end_date}
          resolution={chart_resolution}
          refresh_key={refresh_key}
        />
      </div>
      {range_selected && (
        <StyledSaveCard
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          p={1}
          raised>
          <Div flex={{ gap: 1 }}>
            <Button
              minimal
              onClick={() => {
                onEnd();
                setTimebarStart(0);
                setTimebarEnd(chart_resolution.steps - 1);
              }}>
              Cancel
            </Button>
            <Button
              minimal
              ref={apply_button_ref}
              primary
              submit={onApply === undefined}
              onClick={onApply}>
              Apply
            </Button>
          </Div>
        </StyledSaveCard>
      )}
      <StyledSlider>
        {Array.from(Array(chart_resolution.steps).keys()).map((i) => {
          return (
            <StyledSliderStep
              active={i >= timebar_start && i <= timebar_end && range_selected}
              key={i}>
              {(timebar_start === i || timebar_end === i) && (
                <>
                  {timebar_start === i && (
                    <div onMouseDown={curriedOnStart('start')}>
                      <StyledSliderHandleTime
                        direction={start_label_direction}
                        $end={false}
                        active={range_selected}>
                        {format(
                          add(start_date, {
                            seconds: i * chart_resolution.unit * chart_resolution.factor_to_seconds,
                          }),
                          granularity_formating[chart_resolution.granularity],
                        )}
                      </StyledSliderHandleTime>
                      <StyledSliderHandle
                        onMouseDown={curriedOnStart('start')}
                        $end={false}
                        active={range_selected}
                      />
                    </div>
                  )}
                  {timebar_end === i && (
                    <div onMouseDown={curriedOnStart('end')}>
                      <StyledSliderHandleTime
                        direction={end_label_direction}
                        $end={true}
                        active={range_selected}>
                        {format(
                          add(start_date, {
                            seconds:
                              (i + 1) * chart_resolution.unit * chart_resolution.factor_to_seconds,
                          }),
                          granularity_formating[chart_resolution.granularity],
                        )}
                      </StyledSliderHandleTime>
                      <StyledSliderHandle
                        onMouseDown={curriedOnStart('end')}
                        $end={true}
                        active={range_selected}
                      />
                    </div>
                  )}
                </>
              )}
              {range_selected && midpoint_step === i && <div ref={setReferenceElement} />}
            </StyledSliderStep>
          );
        })}
      </StyledSlider>
      <Div>
        <HistogramTimebar start_date={start_date} resolution={axis_resolution} />
      </Div>
    </>
  );
};

export default HistogramRangePickerWrapper;
