import { useContext, useMemo, useState } from 'react';

import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Divider from '../../../common/base/Divider';
import Icon from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import TextSwitch from '../../../common/base/TextSwitch';
import DropdownMenu from '../../../common/DropdownMenu';
import { Div } from '../../../common/helpers/StyledUtils';
import {
  metric_definitions,
  metric_time_units,
  MetricName,
} from '../../../common/metrics/metric-definitions';
import MetricChart from '../../../common/metrics/MetricChart';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';

const resource_map = {
  destination: {
    id_mappping: 'destination_id',
    label: 'Destination',
    metrics_path: '/metrics/attempts',
  },
  source: { id_mappping: 'source_id', label: 'Source', metrics_path: '/metrics/requests' },
  webhook: { id_mappping: 'webhook_id', label: 'Connection', metrics_path: '/metrics/events' },
};

const ResourceMetricSection: React.FC<{
  id: string;
  resource: keyof typeof resource_map;
}> = ({ id, resource }) => {
  const { subscription } = useContext(DashboardContext);
  const metric_options = useMemo(
    () =>
      Object.entries(metric_definitions)
        .filter(([, value]) => value.resource === resource && ['rate', 'count'])
        .map(([key, value]) => ({
          label: value.title,
          value: key as MetricName,
        })),
    [resource],
  );
  const { query, updateSearchQuery } = useSearchQuery<{
    metric: MetricName;
    time_unit: keyof typeof metric_time_units;
  }>({
    metric: metric_options[0].value,
    time_unit: '24h',
  });
  const [refresh_key, setRefreshKey] = useState(new Date().getTime());

  const { id_mappping, label, metrics_path } = resource_map[resource];
  return (
    <>
      <Text as="h2" heading m={{ t: 14, b: 2 }} flex={{ align: 'center' }}>
        {label} Metrics
      </Text>
      <Divider m={{ b: 4 }} />
      <StyledCard>
        <StyledCardSection p={{ x: 4, y: 3 }} flex={{ justify: 'space-between' }}>
          <DropdownMenu
            outline
            label={metric_definitions[query.metric].title}
            options={metric_options.map((option, i) => ({
              ...option,
              onClick: () => {
                updateSearchQuery({ metric: option.value });
              },
              ...(!subscription?.features?.includes('metrics') && i > 0
                ? {
                    badge: metric_definitions[option.value].type,
                    disabled: true,
                    tooltip: 'Upgrade to enable this metric',
                    icon: 'upgrade',
                  }
                : {}),
            }))}
          />
          <Div flex={{ gap: 2 }}>
            <TextSwitch
              options={Object.keys(metric_time_units).map((key) => ({
                label: key,
                key: key,
              }))}
              active={query.time_unit}
              onSelect={(key) => updateSearchQuery({ time_unit: key })}
            />
            <Button icon={'reload'} outline onClick={() => setRefreshKey(new Date().getTime())} />
          </Div>
        </StyledCardSection>
        <StyledCardSection>
          <MetricChart
            height={300}
            key={refresh_key}
            type={metric_definitions[query.metric].type === 'count' ? 'chart:bar' : 'chart:line'}
            metric={query.metric}
            configs={{
              time_unit: query.time_unit,
              options: { filters: { [id_mappping]: [id] } },
            }}
          />
        </StyledCardSection>
        <StyledCardSection flex={{ justify: 'space-between', align: 'center' }} p={{ x: 4, y: 3 }}>
          <Text muted>
            <Icon icon="info" left />
            {subscription?.features?.includes('metrics')
              ? 'Explore more data in metrics.'
              : 'Upgrade to view all metrics.'}
          </Text>
          {subscription?.features?.includes('metrics') ? (
            <Button outline to={`${metrics_path}?${id_mappping}[0]=${id}`} icon="link">
              View all metrics
            </Button>
          ) : (
            <Button outline to="/settings/organization/plans?highlight=metrics" icon="upgrade">
              View Plans
            </Button>
          )}
        </StyledCardSection>
      </StyledCard>
    </>
  );
};

export default ResourceMetricSection;
