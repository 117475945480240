import React, { forwardRef } from 'react';
import Text from '../../../base/Text';

export interface InputErrorProps extends React.ComponentPropsWithoutRef<'div'> {
  children?: React.ReactNode;
}

const InputError = forwardRef<HTMLDivElement, InputErrorProps>(({ children, ...others }, ref) => {
  return (
    <Text as="div" danger ref={ref} {...others}>
      {children}
    </Text>
  );
});

export default InputError;
