import { useContext } from 'react';
import { ResourcesContext } from '../../ResourcesContext';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { useToasts } from '../../../../../common/Toast';
import { useDialog } from '../../../../../common/Dialog';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import { Webhook } from '../../../../../../../../../typings/Webhook.interface';
import { Destination } from '../../../../../../../../../typings/Destination.interface';
import { Source } from '../../../../../../../../../typings/Source.interface';

const configs = {
  webhooks: 'connection',
  destinations: 'destination',
  sources: 'source',
} as const;

const useOnDisableToggle = (type: keyof typeof configs) => {
  const { mutateResourceType } = useContext(ResourcesContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();

  const label = configs[type];
  const { HookdeckAPI } = useContext(GlobalContext);
  const [confirm_disable, setConfirmDisable] = useLocalStorage(`pref:prompt_archive_${type}`, true);
  const onToggleDisable = (resource: Webhook | Source | Destination, disable: boolean) => {
    const apply = () => {
      const promise = disable
        ? HookdeckAPI[type].disable(resource.id)
        : HookdeckAPI[type].enable(resource.id);
      return promise
        .then((resource) => {
          addToast(
            'success',
            `${resource.disabled_at ? 'Disabled' : 'Enabled'} ${label} ${
              'full_name' in resource ? resource.full_name : resource.name
            }`,
          );
          mutateResourceType!(type, resource);
        })
        .catch(() => {
          addToast(
            'error',
            `Failed to ${disable ? 'disable' : 'enable'} ${label} ${
              'full_name' in resource ? resource.full_name : resource.name
            }`,
          );
        });
    };
    if (disable && confirm_disable) {
      showDialog(
        () => {
          apply();
          setConfirmDisable(false);
        },
        () => null,
        {
          message: `By disabling the ${label} it will no longer accept and deliver events, are you sure?`,
        },
      );
    } else {
      apply();
    }
  };

  return onToggleDisable;
};

export default useOnDisableToggle;
