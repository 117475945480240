import { addHours, startOfHour, sub, subDays } from 'date-fns';
import { Form, Formik } from 'formik';
import { useContext, useMemo } from 'react';

import { Source } from '../../../../../../../../typings/Source.interface';
import integration_schemas from '../../../../../configs/integration-schemas';
import field_formats from '../../../../../utils/field-formatters';
import formatCubeQuery from '../../../../../utils/formatCubeQuery';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import CopyableField from '../../../../common/CopyableField';
import DropdownMenu from '../../../../common/DropdownMenu';
import TextAreaInput from '../../../../common/Form/Fields/TextAreaInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import PreviewHistogram from '../../../../common/Histogram/PreviewHistogram';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { useCubeQueryLocalRawData } from '../../../../hooks/useCubeQueryLocalRawData';
import resource_details_form_props from '../Forms/resource_details';
import { ResourcesContext } from '../ResourcesContext';
import useOnDisableToggle from './hooks/useOnDisableToggle';
import useOnDelete from './hooks/useOnDeleteToggle';

interface Props {
  source: Source;
}

const SourcePreview = ({ source }: Props) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { mutateResourceType } = useContext(ResourcesContext);
  const { addToast } = useToasts();
  const onDisable = useOnDisableToggle('sources');
  const onDelete = useOnDelete('sources');

  const now = useMemo(() => addHours(startOfHour(new Date()), 1), []);

  const stats_chart_query = useMemo(() => {
    return formatCubeQuery('Requests', {
      filters: {
        date: {
          min: subDays(now, 1).toISOString(),
          max: now.toISOString(),
        },
        source_id: [source.id],
      },
      granularity: 'hour',
      dimentions: ['status'],
    });
  }, [now]);

  const { raw_data } = useCubeQueryLocalRawData(stats_chart_query, {
    resetResultSetOnChange: false,
  });

  const timeseries_data = useMemo(() => {
    const data = [
      ...(raw_data
        ? raw_data.map((entry: any) => ({
            created_at: entry['Requests.createdAt'],
            count: entry['Requests.count'],
            status: entry['Requests.status'],
          }))
        : []),
    ];
    return Array.from(Array(12).keys())
      .reduce((arr: any, i) => {
        const step_start = sub(now, {
          hours: i * 2,
        });
        const step_end = sub(step_start, {
          hours: 2,
        });

        const entries = data.filter(
          (entry) =>
            step_end.getTime() < new Date(entry.created_at).getTime() &&
            step_start.getTime() >= new Date(entry.created_at).getTime(),
        );

        const entry = entries.reduce(
          (rollup, data) => {
            if (!rollup.values[data.status]) {
              rollup.values[data.status] = 0;
            }
            rollup.values[data.status] += parseInt(data.count);
            return rollup;
          },
          {
            values: {},
          },
        );

        arr.push([[step_start, step_end], entry.values]);

        return arr;
      }, [])
      .reverse();
  }, [raw_data]);

  return (
    <Formik
      onSubmit={(values, { resetForm }) => {
        HookdeckAPI.sources.update(source.id, { name: values.name }).then((updated_source) => {
          mutateResourceType!('sources', updated_source);
          resetForm({ values: { name: updated_source.name }, touched: {} });
          addToast('success', 'Source updated');
        });
      }}
      validate={(values) =>
        resource_details_form_props.validate(values, HookdeckAPI.sources.nameIsUsed)
      }
      initialValues={{ name: source.name }}>
      {({ dirty, resetForm, isSubmitting, isValid }) => (
        <Form>
          <StyledCardSection muted p={{ x: 4, y: 1 }}>
            <Text subtitle size="xs">
              {source.disabled_at ? 'Disabled' : 'Enabled'} Source
            </Text>
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }}>
            <TextAreaInput
              monospace
              name="name"
              label="Source Name"
              required
              auto_resize={true}
              format={field_formats.slugify}
              maxlength={155}
            />
            <Text subtitle size="s" m={{ b: 1, t: 4 }}>
              Source URL
            </Text>
            <CopyableField monospace value={source.url} />
            {source.verification && (
              <>
                <Text subtitle size="s" m={{ b: 1, t: 4 }}>
                  Verification
                </Text>
                <StyledCard muted p={{ x: 3, y: 1.25 }}>
                  <Div flex={{ align: 'center' }}>
                    <Icon icon="verification" left success small />
                    <Text subtitle>
                      {integration_schemas[source.verification.type.toUpperCase()].label}
                    </Text>
                  </Div>
                </StyledCard>
              </>
            )}
            <Div flex m={{ t: 4, b: 2 }}>
              <Text subtitle size="s">
                Requests
              </Text>
              <Text subtitle size="s" muted m={{ x: 1 }}>
                {' · '}
              </Text>
              <Text subtitle size="s" muted>
                Last 24h
              </Text>
            </Div>
            <PreviewHistogram
              values={timeseries_data}
              resolution="hour"
              dimentions_config={{
                accepted: {
                  label: 'Accepted',
                  color: 'success',
                },
                rejected: {
                  label: 'Rejected',
                  color: 'danger',
                },
              }}
              show_tooltip
              size="medium"
            />
          </StyledCardSection>
          <StyledCardSection p={{ x: 4, y: 3 }} flex={{ justify: 'space-between' }}>
            {dirty ? (
              <>
                <Button onClick={() => resetForm()} outline>
                  Cancel
                </Button>
                <Button submit primary icon="save" disabled={isSubmitting || !isValid}>
                  {isSubmitting ? <Icon icon="loading" /> : 'Save'}
                </Button>
              </>
            ) : (
              <>
                <Button to={`/sources/${source.id}`} outline icon="link">
                  Open Source
                </Button>

                <DropdownMenu.Permission
                  outline
                  icon="horizontal_more"
                  placement="bottom-end"
                  options={[
                    source.disabled_at
                      ? {
                          label: 'Enable Source',
                          icon: 'enable',
                          onClick: () => onDisable(source, false),
                        }
                      : {
                          label: 'Disable Source',
                          icon: 'disable',
                          onClick: () => onDisable(source, true),
                        },
                    {
                      label: 'Delete Source',
                      icon: 'delete',
                      danger: true,
                      onClick: () => onDelete(source),
                    },
                  ]}
                />
              </>
            )}
          </StyledCardSection>
        </Form>
      )}
    </Formik>
  );
};

export default SourcePreview;
