import { useMemo, useRef, useState } from 'react';
import useSWR from 'swr';

import { APIList, OrderByDirection } from '../../../../../typings/API.interface';
import { Event } from '../../../../../typings/Event.interface';

export interface RefreshingListResult<T> {
  models: T[];
  fetched: boolean;
  count: number;
  outdated_ids: string[];
  has_next_results: boolean;
  refresh: (entry?: { id: string; [key: string]: any }) => void;
  next: string | null;
  prev: string | null;
}

export type RefreshListOptions = {
  revalidation_interval?: number;
  limit?: number;
  include_data?: boolean;
  fetch?: boolean;
};

const useRefreshingList = <T,>(
  getCacheKey: (query: object) => string,
  list: (query: object, signal?: AbortSignal) => Promise<APIList<T>>,
  api_filters: object,
  order_by: string,
  dir: OrderByDirection,
  {
    revalidation_interval = 5000,
    limit = 100,
    include_data = false,
    fetch = true,
  } = {} as RefreshListOptions,
): RefreshingListResult<T> => {
  const [start_revalidation, setStartRevalidation] = useState(false);
  const [automatically_load_new_models, setLoadNewEvents] = useState(false);
  const [refresh_new_models, setRefreshNewEvents] = useState(true);
  const query_filters = {
    ...api_filters,
    order_by,
    dir,
    limit,
    ...(include_data ? { include: 'data' } : {}),
  };
  const [abort_controller, setAbortController] = useState<AbortController | null>(null);
  const update_cache = useRef({});
  const [last_outdated_response, setLastOutadedResponse] = useState<APIList<Event>>();

  const revalidation_option = {
    refreshInterval: revalidation_interval,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  };

  const { data: current_models, mutate } = useSWR(
    fetch && getCacheKey(query_filters),
    () => {
      if (abort_controller) {
        abort_controller.abort();
      }
      const controller = new AbortController();
      setAbortController(controller);
      return list(query_filters, controller.signal) as any;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (models) => {
        if (models.count < limit) {
          setTimeout(() => setLoadNewEvents(true), revalidation_interval);
        } else if (automatically_load_new_models) {
          setLoadNewEvents(false);
        }
        if (models.count > 0) {
          setTimeout(() => setStartRevalidation(true), revalidation_interval);
        }
      },
    },
  );

  // Only check for new models if on first page.
  const should_check_new_models = current_models && !current_models.pagination.prev;

  const makePrevExpression = (order_by: string, current_models: any) => {
    if (!current_models.models[0]) {
      // Keep old behavior
      return undefined;
    }

    const order_by_field = order_by === 'last_attempt_at' ? 'last_attempt_at' : 'created_at';

    const date = current_models.models[0]
      ? new Date(current_models.models[0][order_by_field]).getTime().toString()
      : null;
    const id = current_models.models[0]?.id;

    return `${date}${id}`;
  };

  const { data: new_models } = useSWR(
    fetch &&
      should_check_new_models &&
      getCacheKey({
        ...query_filters,
        limit: 1,
        prev: makePrevExpression(order_by, current_models),
      }),
    () =>
      list({
        ...query_filters,
        limit: current_models.count === limit ? 1 : limit - current_models.count,
        prev: makePrevExpression(order_by, current_models),
      }) as any,
    {
      ...(refresh_new_models
        ? revalidation_option
        : {
            ...revalidation_option,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
          }),
      onSuccess: (models) => {
        if (current_models.count !== limit && models.count > 0) {
          mutate(
            {
              models: [...models.models, ...current_models.models],
              count: current_models.count + models.count,
              pagination: {
                ...current_models.pagination,
                prev:
                  new_models && new_models.count > 0 && current_models.count !== limit
                    ? new_models.pagination.prev
                    : current_models.pagination.prev,
              },
            },
            false,
          );
        }
        if (models.count + current_models.count === limit && models.count > 0) {
          setRefreshNewEvents(false);
        }

        if (!start_revalidation && models.count + current_models.count > 0) {
          setTimeout(() => setStartRevalidation(true), revalidation_interval);
        }
      },
    },
  );

  const model_ids = useMemo(
    () => (current_models ? current_models.models.map((model) => model.id) : []),
    [current_models],
  );

  const { data: revalidated_models } = useSWR(
    fetch &&
      current_models &&
      start_revalidation &&
      model_ids.length > 0 &&
      getCacheKey({ ...query_filters, id: model_ids }),
    () => list({ ...query_filters, id: model_ids }) as any,
    revalidation_option,
  );

  const outdated_ids = useMemo(
    () =>
      current_models && revalidated_models
        ? current_models.models.reduce((list, model) => {
            const revalidated_event = revalidated_models?.models.find(({ id }) => id === model.id);
            if (!revalidated_event) {
              list.push(model.id);
            }
            return list;
          }, [])
        : [],
    [current_models, revalidated_models],
  );

  // Don't use the { data } returned by SWR and instead set the state with onSuccess.
  // This is because everytime there is a new ID, the data cache key gets invalidated and the data has to be refresh causing a flashing.
  // Instead just keep the latest successful updated view
  useSWR(
    current_models &&
      start_revalidation &&
      outdated_ids.length > 0 &&
      getCacheKey({
        id: outdated_ids,
        limit: outdated_ids.length,
      }),
    () =>
      list({
        id: outdated_ids,
        limit: outdated_ids.length,
      }) as any,
    { ...revalidation_option, onSuccess: (r) => setLastOutadedResponse(r) },
  );

  const consolidated_models = useMemo(() => {
    return current_models
      ? current_models.models
          .reduce((list, model) => {
            const revalidated_event = revalidated_models?.models.find(({ id }) => id === model.id);
            if (revalidated_event) {
              list.push(revalidated_event);
              return list;
            }
            const oudated_event = last_outdated_response?.models.find(({ id }) => id === model.id);
            if (oudated_event) {
              list.push(oudated_event);
              return list;
            }
            list.push(model);
            return list;
          }, [])
          .map((model) => {
            if (update_cache.current[model.id]) {
              if (update_cache.current[model.id].updated_at >= model.updated_at) {
                return update_cache.current[model.id];
              }
              delete update_cache.current[model.id];
            }
            return model;
          })
      : [];
  }, [current_models, revalidated_models?.models, last_outdated_response]);

  return {
    models: consolidated_models,
    fetched: !!current_models,
    count: consolidated_models.length,
    outdated_ids,
    has_next_results:
      current_models && current_models.count === limit && new_models && new_models.count > 0,
    refresh: (entry) => {
      if (entry) {
        update_cache.current[entry.id] = entry;
        mutate(
          (data) =>
            data && {
              ...data,
              models: data.models.map((model) => (model.id === entry.id ? entry : model)),
            },
          false,
        );
      } else {
        mutate();
      }
      setRefreshNewEvents(true);
    },
    next: current_models?.pagination.next,
    prev: current_models?.pagination.prev,
  };
};

export default useRefreshingList;
