import { useField } from 'formik';
import { useContext, useState } from 'react';
import useSWR from 'swr';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { Div } from '../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import resource_details_form_props from '../Forms/resource_details';
import source_configuration_form_props from '../Forms/source_configuration';
import RadioCardsInput from '../../../../common/Form/Fields/RadioCardsInput';
import SelectInput from '../../../../common/Form/Fields/SelectInput';

const SourceSection = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const [{ value: use }] = useField('source_use');
  const [search_term, setSearchTerm] = useState<string>('');

  const filters: Record<string, any> = {
    limit: 100,
    name: search_term ? { contains: search_term } : undefined,
  };
  const { data: sources, isValidating } = useSWR(
    use === 'select' && APIMethodKeys.sources.list(filters),
    () => HookdeckAPI.sources.list(filters),
  );

  return (
    <>
      <RadioCardsInput
        m={{ b: 6 }}
        name={'source_use'}
        options={[
          {
            value: 'new',
            label: 'Create new source',
            description: 'Configure a new source in Hookdeck.',
          },
          {
            value: 'select',
            label: 'Use existing source',
            description: 'Re-use a source you’ve already created.',
          },
        ]}
      />
      {use === 'select' && (
        <SelectInput
          name="source_id"
          label="Source"
          required
          search
          placeholder="Select..."
          search_placeholder="Find a source..."
          separator={false}
          borderless
          sublte_error
          options={
            sources?.models.map((source) => ({
              value: source.id,
              label: source.name,
              icon: 'source',
            })) || []
          }
          width={100}
          block
          search_term={search_term}
          setSearchTerm={setSearchTerm}
          search_is_loading={isValidating}
        />
      )}
      {use === 'new' && (
        <>
          <resource_details_form_props.Fields
            prefix="source"
            placeholder="shopify-prod"
            name_prefix="Source"
            name_required
          />
          <Div m={{ t: 6 }}>
            <source_configuration_form_props.Fields prefix="source" />
          </Div>
        </>
      )}
    </>
  );
};

export default SourceSection;
