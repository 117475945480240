import { Fragment } from 'react';
import styled, { css } from 'styled-components';

const StyledKeybindWrapper = styled.div`
  display: flex;
  align-items: center;

  kbd {
    --colors-gray2: hsl(60 7.7% 97.5%);
    --colors-gray5: hsl(57 6% 90.7%);
    --colors-gray8: hsl(51 6% 77.1%);
    --colors-gray11: hsl(50 2% 43.1%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    height: 16px;
    background: var(--colors-gray5);
    flex-shrink: 0;
    color: var(--colors-gray11);
    font-size: 12px;
    user-select: none;
    border-radius: 2px;
    cursor: default;
    box-shadow:
      inset 0 0.5px rgba(255, 255, 255, 0.1),
      inset 0 1px 5px var(--colors-gray2),
      0px 0px 0px 0.5px var(--colors-gray8),
      0px 2px 1px -1px var(--colors-gray8),
      0 1px var(--colors-gray8);
    text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 1px;
    font-family: inherit;
    font-weight: 400;
    margin-left: 4px;
    margin-right: 4px;
    filter: ${({ theme }) => (theme.mode === 'dark' ? 'invert(1)' : 'none')};
  }
`;

const StyledKbd = styled.kbd<{ length: number }>(
  ({ length }) => `
  ${
    length > 1 &&
    css`
      padding: 0 4px;
    `
  }
`,
);

const Keybinds: React.FC<{ keys: string[] }> = ({ keys }) => {
  return (
    <StyledKeybindWrapper>
      {keys.map((key) => {
        const value = ['⌘', 'CMD', 'CTRL'].includes(key.toUpperCase())
          ? ((window.navigator as any)?.userAgentData?.platform || window.navigator.platform)
              .toLowerCase()
              .indexOf('mac') > -1
            ? '⌘'
            : 'CTRL'
          : key.toUpperCase();
        return (
          <Fragment key={key}>
            <StyledKbd length={value.length}>{value}</StyledKbd>
          </Fragment>
        );
      })}
    </StyledKeybindWrapper>
  );
};

export default Keybinds;
