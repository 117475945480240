import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { capitalizeFirstLetter } from '../../utils/index';

const TITLE_SUFFIX = '- Hookdeck';
const CLI_PREFIX = 'CLI';
const DEFAULT_TITLE = `Dashboard ${TITLE_SUFFIX}`;

interface UseTitleProps {
  title?: string;
  subtitle?: string;
  usePath?: boolean;
  capitalizeSubtitle?: boolean;
}

const setDocumentTitle = (title?: string) => {
  if (!title || typeof document === 'undefined') {
    document.title = DEFAULT_TITLE;
    return;
  }

  document.title = `${title} ${TITLE_SUFFIX}`;
};

const getTitle = (
  usePath: boolean,
  capitalizeSubtitle: boolean,
  pathname: string,
  title?: string,
  subtitle?: string,
) => {
  let newTitle: string;
  let prefix: string | undefined;

  const [, subpath1, subpath2, subpath3] = pathname.split('/');

  if (subpath1 === 'cli') {
    prefix = CLI_PREFIX;
  }

  if (usePath) {
    title = subpath1;
    subtitle = subpath2;

    if (title === 'cli') {
      title = subpath2;
      subtitle = subpath3;
    }

    if (title) {
      title = title
        .replace(/-/g, ' ')
        .split(' ')
        .map((word) => capitalizeFirstLetter(word))
        .join(' ');

      if (subtitle) {
        subtitle = subtitle.replace(/-/g, ' ');

        if (capitalizeSubtitle) {
          subtitle = capitalizeFirstLetter(subtitle);
        }
      }
    }
  }

  newTitle = prefix ? `${prefix} ${title || ''}` : title || '';
  if (title && subtitle) {
    newTitle += ` - ${subtitle}`;
  }

  return newTitle;
};

const useTitle = ({
  title,
  subtitle,
  usePath = false,
  capitalizeSubtitle = false,
}: UseTitleProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const newTitle = getTitle(usePath, capitalizeSubtitle, pathname, title, subtitle);
    setDocumentTitle(newTitle);

    return () => {
      document.title = DEFAULT_TITLE;
    };
  }, [title, subtitle, usePath, pathname, capitalizeSubtitle]);
};

export default useTitle;
