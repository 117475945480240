import { useField } from 'formik';
import { FormEvent, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { StyledCard } from '../../base/Card';
import Icon from '../../base/Icon';
import Text from '../../base/Text';
import EditorInput from '../../Form/Fields/EditorInput';
import TextInput from '../../Form/Fields/TextInput';
import { Div } from '../../helpers/StyledUtils';

const StyledInput = styled(TextInput)``;
const StyledContainer = styled(Div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  column-gap: 12px;
  max-width: 100%;
  min-width: 100%;

  ${StyledInput} {
    width: 100%;
  }
`;

const FocusableEditorInput: React.FC<{ label: string; name: string; onBlur?: () => void }> = ({
  label,
  name,
  onBlur,
}) => {
  const theme = useTheme();
  const [focused, setFocused] = useState<boolean>(false);
  const focused_styles = {
    position: 'absolute',
    width: '500px',
    height: '500px',
    zIndex: theme.zindex.fixed,
    boxShadow: theme.elevation[3],
    border: theme.border,
  };

  return (
    <StyledCard overflow_hidden style={focused ? (focused_styles as object) : {}}>
      <EditorInput
        label={label}
        variant={'card'}
        onBlur={() => {
          setFocused(false);
          onBlur && onBlur();
        }}
        display="minimal"
        onFocus={() => setFocused(true)}
        height={focused ? '500px' : '100px'}
        name={name}
        format_on_blur
      />
    </StyledCard>
  );
};

const JSONFilter: React.FC<{
  delete_button?: JSX.Element;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}> = ({ delete_button, onSubmit }) => {
  const [, { touched }] = useField('request.body');

  return (
    <StyledContainer>
      <Div>
        <FocusableEditorInput label={'Headers'} name="request.headers" onBlur={onSubmit} />
      </Div>
      <Div>
        <FocusableEditorInput label={'Body'} name="request.body" onBlur={onSubmit} />
        {touched && (
          <Text size="s" muted flex={{ align: 'center' }} m={{ t: 2 }}>
            <Icon icon="info" left={1} />
            Payloads over 2.5 MB are not searchable
          </Text>
        )}
      </Div>
      <Div>
        <FocusableEditorInput label={'Query'} name="request.parsed_query" onBlur={onSubmit} />
      </Div>
      <Div flex>
        <StyledInput m={0} name="request.path" onBlur={onSubmit} label={'Path'} variant={'card'} />
        <Div>{delete_button && delete_button}</Div>
      </Div>
    </StyledContainer>
  );
};

export default JSONFilter;
