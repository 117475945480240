import { Issue, IssueStatus } from '../../../../../../../../typings/Issue.interface';
import { issue_actions, ISSUE_STATUS_CONFIGS } from '../../../../../utils/issues';
import Button, { ButtonGroup } from '../../../../common/base/Button';
import { Div } from '../../../../common/helpers/StyledUtils';
import Text from '../../../../common/base/Text';
import IssueSection from './IssueSection';
import { IssuesRenderData } from '.';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';

const IssueGroup: React.FC<{
  group_key: IssueStatus;
  issues: Issue[];
  render_data: IssuesRenderData;
  applyAction: (
    current_status: IssueStatus,
    ids: string[],
    action: keyof typeof issue_actions,
  ) => void;
}> = ({ group_key, issues, render_data, applyAction }) => {
  const { label, description, default_action, current_action } = ISSUE_STATUS_CONFIGS[group_key];
  const action = issue_actions[default_action];

  return (
    <Div m={{ t: 14 }} w={100}>
      <Div m={{ b: 4 }}>
        <Div flex={{ justify: 'space-between' }}>
          <Div>
            <Text heading>{label} Issues</Text>
            <Text muted>{description}</Text>
          </Div>
          <Div>
            <ButtonGroup
              more_options={Object.keys(issue_actions)
                .filter((key) => {
                  if (key === current_action || key === default_action) {
                    return false;
                  }
                  return group_key === 'OPENED' ? key !== 'reopen' : true;
                })
                .map((key: keyof typeof issue_actions) => ({
                  label: `${issue_actions[key].label} All`,
                  danger: issue_actions[key].danger,
                  role: issue_actions[key].role,
                  icon: issue_actions[key].icon,
                  onClick: () =>
                    applyAction(
                      group_key,
                      issues.map((issue) => issue.id),
                      key,
                    ),
                }))}>
              <Button.Permission
                role={action.role}
                outline
                onClick={() =>
                  applyAction(
                    group_key,
                    issues.map((issue) => issue.id),
                    default_action,
                  )
                }>
                <Icon icon={action.icon} muted left />
                {action.label} All
              </Button.Permission>
            </ButtonGroup>
          </Div>
        </Div>
        <Divider m={{ t: 2 }} />
      </Div>
      {issues.map((issue) => (
        <IssueSection
          key={issue.id}
          issue={issue}
          render_data={render_data}
          applyAction={applyAction}
        />
      ))}
    </Div>
  );
};

export default IssueGroup;
