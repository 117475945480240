import { useContext } from 'react';
import { Bookmark } from '../../../../../typings/Bookmark.interface';
import { Event } from '../../../../../typings/Event.interface';
import { fieldName } from '../../utils';
import { useDialog } from '../common/Dialog';
import TextInput from '../common/Form/Fields/TextInput';
import { useToasts } from '../common/Toast';
import { GlobalContext } from '../contexts/GlobalContext';

const useEventAction = (
  callback: (
    action: 'retry' | 'mute' | 'bookmark',
    event: Event,
    meta?: { bookmark?: Bookmark },
  ) => void,
): { [k in 'retry' | 'mute' | 'bookmark']: (event: Event) => Promise<void> } => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();
  return {
    retry: async (event: Event) => {
      await HookdeckAPI.events
        .retry(event.id)
        .then((result) => {
          addToast('success', 'Event queued for retry');
          if (callback) callback('retry', result);
        })
        .catch((e) => {
          if (e.response?.code === 'WEBHOOK_DISABLED' || e.response?.code === 'WEBHOOK_DELETED') {
            addToast('error', 'Failed to retry, connection disabled or deleted');
          } else {
            addToast('error', 'Failed to retry');
          }
        });
    },
    mute: async (event: Event) => {
      await HookdeckAPI.events
        .mute(event.id)
        .then((updated_event) => {
          addToast('success', 'Event retry cancelled');
          if (callback) callback('mute', updated_event);
        })
        .catch(() => addToast('error', 'Failed to cancel retries'));
    },
    bookmark: async (event: Event) => {
      await new Promise((resolve) => {
        showDialog(
          async (values: { label: string }) => {
            await HookdeckAPI.bookmarks
              .create({
                event_data_id: event.event_data_id,
                label: values.label,
                webhook_id: event.webhook_id,
              })
              .then((bookmark) => {
                if (callback) callback('bookmark', event, { bookmark });
                addToast('success', 'Event bookmarked');
              })
              .catch((error) =>
                addToast(
                  'error',
                  error.response?.code == 'DATA_ARCHIVED'
                    ? 'Failed to create bookmark because associated data is archived'
                    : 'Failed to bookmark event',
                ),
              );
            resolve(null);
          },
          () => null,
          {
            title: 'Add Bookmark',
            submit_label: 'Add',
            cancel_label: 'Cancel',
            form_props: {
              initial_values: {},
              validate: ({ label }: { label: string }) => {
                if (!label || label.length < 0) return { label: 'Required' };
                return {};
              },
              Fields: () => (
                <TextInput
                  name={fieldName('label')}
                  label="Bookmark Label"
                  placeholder="Shopify Order with Gift Card"
                  m={{ b: 2 }}
                  required
                  focus
                />
              ),
            },
          },
        );
      });
    },
  };
};

export default useEventAction;
