import LogRocket from 'logrocket';
import { Component, PropsWithChildren } from 'react';
import { withRouter } from 'react-router-dom';
import { User } from '../../../../../../typings/User.interface';
import Sentry from '../../../client/Sentry';
import { showChat } from '../../../utils/liveChat';
import { withUserContext } from '../../contexts/UserContext';
import Alert from '../base/Alert';
import Button from '../base/Button';
import Container from '../base/Container';
import Text from '../base/Text';

class ErrorBoundary extends Component<
  PropsWithChildren<{ user?: User; location: any }>,
  { error?: Error }
> {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname && this.state.error) {
      this.setState({ error: undefined });
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtra('sessionURL', LogRocket.sessionURL);
      if (this.props.user) {
        scope.setUser(this.props.user);
      }
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
    LogRocket.captureException(error);
    this.setState({ error });
  }

  render() {
    return this.state.error ? (
      <Container medium p={8}>
        <Text heading size="l" m={{ b: 2 }}>
          Something went wrong...
        </Text>
        <Text as="p" muted m={{ b: 4 }}>
          The technical team was notified and will look into it!
        </Text>
        {this.state.error.message && (
          <Alert danger inline>
            {this.state.error.message}
          </Alert>
        )}
        <Button m={{ y: 4 }} primary onClick={() => window.location.reload()}>
          Reload
        </Button>
        <Button m={4} outline onClick={showChat}>
          Get Help
        </Button>
      </Container>
    ) : (
      this.props.children
    );
  }
}

export default withRouter(withUserContext(['user'])(ErrorBoundary));
