import { Form, Formik } from 'formik';
import { useContext } from 'react';
import useSWR from 'swr';

import { Source } from '../../../../../../../../typings/Source.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Loading from '../../../../common/base/Loading';
import MultiSelectInput from '../../../../common/Form/Fields/MultiSelectInput';
import SelectResourceInput from '../../../../common/Form/Fields/SelectResourceInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { DashboardContext } from '../../DashboardContext';
import SourceCard from '../../Connections/SourceCard';
import Text from '../../../../common/base/Text';
import Divider from '../../../../common/base/Divider';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import Skeleton from '../../../../common/base/Skeleton';

const TeamNotifications: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { team, mutateTeam } = useContext(DashboardContext);
  const { addToast } = useToasts();

  const { data: source } = useSWR(
    team?.webhook_source_id && APIMethodKeys.sources.get(team.webhook_source_id),
    () => HookdeckAPI.sources.get(team!.webhook_source_id!),
  );

  const handleSubmit = (values) => {
    return HookdeckAPI.teams
      .update({
        notification_methods: Object.entries(values.notification_methods).reduce(
          (arr: any[], [method, checked]) => {
            if (checked) {
              arr.push(method);
            }
            return arr;
          },
          [],
        ),
        webhook_topics: (values.notification_methods.webhook && values.webhook_topics) || null,
        webhook_source_id:
          (values.notification_methods.webhook && values.webhook_source?.id) || null,
      })
      .then((team) => {
        addToast('success', 'Project updated');
        mutateTeam(team);
        return true;
      })
      .catch(() => {
        addToast('error', 'Failed to update project');
        return false;
      });
  };

  return (
    <>
      <Text heading as="h2" m={{ b: 2 }}>
        Notification Settings
      </Text>
      <Divider m={{ b: 4 }} />
      <Loading
        require={team?.webhook_source_id ? [source] : []}
        wrapper={() => (
          <>
            <Skeleton w={100} h={{ px: 74 }} loading m={{ b: 4 }} />
            <Skeleton w={100} h={{ px: 74 }} loading />
          </>
        )}>
        {() => (
          <Formik
            initialValues={{
              notification_methods: team!.notification_methods.reduce(
                (obj, method) => ({ ...obj, [method]: true }),
                {},
              ),
              webhook_topics: team!.webhook_topics || [],
              webhook_source: source || null,
            }}
            validate={(values) => {
              const errors: { webhook_source?: string } = {};
              if (values.notification_methods.webhook && !values.webhook_source) {
                errors.webhook_source = 'Required';
              }
              return errors;
            }}
            onSubmit={handleSubmit}>
            {(props) => (
              <Form>
                <StyledCard flex={{ justify: 'space-between' }} m={{ b: 4 }} p={4}>
                  <Div>
                    <Text>Email Notifications</Text>
                    <Text muted>Each project member receives email notifications.</Text>
                  </Div>
                  <SwitchInput show_label name="notification_methods.email" />
                </StyledCard>
                <StyledCard m={{ b: 4 }}>
                  <StyledCardSection p={4} flex={{ justify: 'space-between' }}>
                    <Div>
                      <Text>Webhook Notifications</Text>
                      <Text muted>A Hookdeck source URL receives webhook notifications.</Text>
                    </Div>
                    <SwitchInput show_label name="notification_methods.webhook" />
                  </StyledCardSection>

                  {props.values.notification_methods.webhook && (
                    <StyledCardSection p={4}>
                      <MultiSelectInput
                        label={'Webhook topics'}
                        option_title={'Topics'}
                        name={'webhook_topics'}
                        required
                        initial_options={[
                          'issue.opened',
                          'issue.updated',
                          'deprecated.attempt-failed',
                          'event.successful',
                        ].map((v) => ({ value: v, label: v }))}
                      />
                      <SelectResourceInput<Source>
                        label={'Webhook source'}
                        name={'webhook_source'}
                        help={`Select the source receiving webhooks. We recommend naming the source 'Hookdeck'.`}
                        listResources={HookdeckAPI.sources.list}
                        listResourcesKey={APIMethodKeys.sources.list}
                        ResourcePreview={({ resource }) => (
                          <SourceCard source={resource} to={`/sources/${resource.id}`} />
                        )}
                      />
                    </StyledCardSection>
                  )}
                </StyledCard>
                <Button.Permission
                  outline
                  disabled={!props.isValid || !props.touched || props.isSubmitting}
                  primary
                  submit
                  icon={props.isSubmitting ? 'loading' : 'save'}>
                  Save
                </Button.Permission>
              </Form>
            )}
          </Formik>
        )}
      </Loading>
    </>
  );
};

export default TeamNotifications;
