import { useField, useFormikContext } from 'formik';
import { FormEvent } from 'react';

import { getErrorCodeLabels } from '../../../../utils/error-codes';
import Text from '../../base/Text';
import MultiSelectInput from '../../Form/Fields/MultiSelectInput';
import TextInput from '../../Form/Fields/TextInput';
import { Div } from '../../helpers/StyledUtils';

const initial_options = Object.keys(getErrorCodeLabels()).map((k) => ({ label: k, value: k }));

const ResponseCodeFilter: React.FC<{
  delete_button?: JSX.Element;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}> = ({ delete_button, onSubmit }) => {
  const { submitCount } = useFormikContext();
  const [, { error: status_min_error, touched: status_min_touched }] = useField(
    'response.response_status.min',
  );
  const [, { error: status_max_error, touched: status_max_touched }] = useField(
    'response.response_status.max',
  );

  return (
    <Div>
      <Div flex={{ direction: 'row' }}>
        <Div flex={{ align: 'center' }} m={{ r: 5 }}>
          <TextInput
            m={0}
            label={'Min'}
            variant={'card'}
            name={'response.response_status.min'}
            type={'number'}
            placeholder={'200'}
            max={599}
            min={200}
            show_error={false}
            onBlur={onSubmit}
          />
          <Div m={{ x: 4 }}>–</Div>
          <TextInput
            m={0}
            label={'Max'}
            variant={'card'}
            name={'response.response_status.max'}
            type={'number'}
            placeholder={'500'}
            max={599}
            min={200}
            show_error={false}
            onBlur={onSubmit}
          />
        </Div>
        <MultiSelectInput
          label={'Error Codes'}
          variant={'card'}
          initial_options={initial_options}
          option_title={'Error codes'}
          name={'response.error_code'}
          m={0}
          onClose={onSubmit}
          onRemove={onSubmit}
        />
        <Div>{delete_button && delete_button}</Div>
      </Div>
      {(status_min_touched || submitCount > 0) && status_min_error && (
        <Text m={{ top: 1, bottom: 0 }} as="p" danger>
          {status_min_error}
        </Text>
      )}
      {(status_max_touched || submitCount > 0) && status_max_error && (
        <Text m={{ top: 1, bottom: 0 }} as="p" danger>
          {status_max_error}
        </Text>
      )}
    </Div>
  );
};

export default ResponseCodeFilter;
