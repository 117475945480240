import { Form, Formik } from 'formik';
import { useContext } from 'react';

import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Divider from '../../../../common/base/Divider';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import { useDialog } from '../../../../common/Dialog';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { DashboardContext } from '../../DashboardContext';
import { isFreePlan } from '../../../../../utils/subscription';

const OrganizationSettings: React.FC = () => {
  const { HookdeckAPI, setAPITeamId } = useContext(GlobalContext);
  const {
    organization,
    organization_role,
    mutateOrganization,
    mutateTeam,
    subscription,
    resetContext,
  } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const showDialog = useDialog();

  const handleSubmit = (values) => {
    return HookdeckAPI.organizations
      .update(values)
      .then((organization) => {
        addToast('success', 'Organization updated');
        mutateOrganization(organization);
        return true;
      })
      .catch(() => {
        addToast('error', 'Failed to update organization');
      });
  };

  const onDelete = () =>
    showDialog(
      () => {
        return HookdeckAPI.organizations
          .delete()
          .catch(() => {
            addToast('error', 'The organization could not be deleted, please contact us.');
          })
          .then(() => {
            resetContext();
          });
      },
      undefined,
      {
        title: 'Delete organization',
        submit_label: 'Delete organization',
        cancel_label: 'Cancel',
        submit_icon: 'delete',
        danger: true,
        form_props: {
          initial_values: {
            name: '',
          },
          validate: ({ name }: { name: string }) => {
            if (!name || name.length < 0) return { name: 'Required' };
            if (name !== organization!.name) {
              return { name: 'Organization name does not match.' };
            }
            return {};
          },
          Fields: () => (
            <>
              <Text m={{ b: 4 }}>
                This action can’t be undone. You’ll lose all of the billing history, the projects
                within this organization and their associated data. Are you sure? <br />
                <br />
                If you’d like to delete this organization, type{' '}
                <strong>{organization!.name}</strong> below to confirm.
              </Text>
              <TextInput m={0} name="name" placeholder={organization!.name} required />
            </>
          ),
        },
      },
    );

  return (
    <>
      <Div m={{ b: 14 }}>
        <Formik
          initialValues={{
            name: organization!.name,
          }}
          validate={(values: { name?: string }) => {
            const errors: { name?: string } = {};
            if (!values.name || values.name.length === 0) {
              errors.name = 'Required';
            }
            if (values.name && values.name.length > 40) {
              errors.name = 'Name is longer then 40 characters';
            }
            return errors;
          }}
          onSubmit={handleSubmit}>
          {(props) => (
            <Form>
              <Text heading as="h2" m={{ b: 2 }}>
                Organization Name
              </Text>
              <Divider m={{ b: 4 }} />
              <TextInput name="name" required m={{ b: 4 }} />
              <Button.Permission
                outline
                disabled={!props.isValid || !props.touched || props.isSubmitting}
                primary
                submit
                icon={props.isSubmitting ? 'loading' : 'save'}>
                Save
              </Button.Permission>
            </Form>
          )}
        </Formik>
      </Div>
      <StyledCard m={{ b: 14 }}>
        <StyledCardSection p={{ y: 3, x: 4 }}>
          <Text heading danger>
            Danger Zone
          </Text>
        </StyledCardSection>
        <StyledCardSection p={4} flex={{ justify: 'space-between', align: 'center' }}>
          <Div>
            <Text heading as="h2" m={0}>
              Delete Organization
            </Text>
            <Text muted>
              Irreversibly deletes this organization and all projects within it. The organization
              must be on the developer plan with no unpaid invoices to be deleted.
            </Text>
          </Div>
          <Tooltip
            disabled={organization_role === 'owner' && isFreePlan(subscription!.plan)}
            tooltip={
              organization_role !== 'owner'
                ? 'Restricted to the organization owner'
                : !isFreePlan(subscription!.plan)
                  ? 'Organization must be on the developer (free) plan'
                  : ''
            }>
            <Button
              m={{ l: 4 }}
              disabled={organization_role !== 'owner' || !isFreePlan(subscription!.plan)}
              danger
              icon="delete"
              onClick={onDelete}>
              Delete
            </Button>
          </Tooltip>
        </StyledCardSection>
      </StyledCard>
    </>
  );
};

export default OrganizationSettings;
