import { useEffect, useState } from 'react';
import { StyledCard } from '../../base/Card';
import Skeleton from '../../base/Skeleton';
import Text from '../../base/Text';
import { StyledUtilsProps } from '../../helpers/StyledUtils';
import { MetricName, default_data_formatter, metric_definitions } from '../metric-definitions';
import { MetricConfigs, useMetric } from '../useMetric';

interface Props extends StyledUtilsProps {
  metric: MetricName;
  refresh_key?: number;
  configs: MetricConfigs;

  // props used by MetricCardGroup
  loading?: boolean;
  onLoad?: () => void;
}

const MetricCard = ({
  metric,
  refresh_key,
  configs,
  loading = false,
  onLoad,
  ...other_props
}: Props) => {
  const [_refresh, setRefresh] = useState(new Date().getTime());

  refresh_key = refresh_key || _refresh;

  const { data, error, rate_as } = useMetric<number>('card', metric, refresh_key, configs);

  useEffect(() => {
    if (data !== undefined) {
      onLoad && onLoad();
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      setRefresh(new Date().getTime());
    }
  }, [loading]);

  const { title, formatDataForDisplay, type } = metric_definitions[metric];

  const formated_data =
    data !== undefined
      ? formatDataForDisplay
        ? formatDataForDisplay(data)
        : default_data_formatter[type](data, rate_as || undefined)
      : null;

  loading = (data === undefined || loading) && !error;

  return (
    <StyledCard p={3} {...other_props}>
      <Text subtitle size="s" muted>
        {title}
      </Text>
      {loading ? (
        <Skeleton variant={'rectangle'} h={{ px: 24 }} w={50} loading />
      ) : (
        <Text subtitle size="l">
          {
            data !== undefined
              ? formated_data
              : '⃠' /* ENCLOSING CIRCLE BACKSLASH unicode character */
          }
        </Text>
      )}
    </StyledCard>
  );
};

export default MetricCard;
