import { Formik } from 'formik';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import TextInput from '../../../../../common/Form/Fields/TextInput';
import Loading from '../../../../../common/base/Loading';
import Modal from '../../../../../common/Modal';
import { useToasts } from '../../../../../common/Toast';
import { GlobalContext } from '../../../../../contexts/GlobalContext';

const EmailModal: React.FC<{ email: string; mutate: (email: string) => void }> = ({
  email,
  mutate,
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const history = useHistory();
  const { addToast } = useToasts();

  const onClose = () =>
    history.push({
      pathname: '/settings/organization/billing',
      state: { scroll: false },
    });

  const onSubmit = (v) => {
    HookdeckAPI.billing
      .updateBillingEmail(v.email)
      .then(({ email }) => {
        addToast('success', `Billing email mail updated`);
        mutate(email);
        onClose();
      })
      .catch(() => {
        addToast('error', `Failed to update billing email, please contact us.`);
      });
  };

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Modal
            title="Update billing email"
            cancel_label="Cancel"
            submit_label="Confirm"
            onSubmit={props.handleSubmit}
            onCancel={onClose}
            onClose={onClose}>
            {!email ? (
              <Loading />
            ) : (
              <TextInput
                name="email"
                type="email"
                label="Billing Email"
                required
                placeholder={email}
              />
            )}
          </Modal>
        </form>
      )}
    </Formik>
  );
};

export default EmailModal;
